import { useState } from 'react'
import { useDepartmentList } from '../../common/filter/useDepartmentList'
import _ from 'lodash'
import { useRecordFilterSearchParams } from '../callRecord/useRecordFilterSearchParams'
import { useUserList } from '../../../../common/apollo/useUserList'
// 계약 기록 목록 필터
export const useContractFilter = () => {
  const [openStatusByKey, setOpenStatusByKey] = useState({}) // 필터가 열려 렌더링 발생할때만 쿼리 호출할 수 있도록 스테이트 관리
  const [userSearchQuery, setUserSearchQuery] = useState('')
  const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])
  const {
    meta,
    searchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup
  } = useRecordFilterSearchParams()
  const onDepartmentListComplete = (departments) => {
    setSelectedDepartmentIds(_.map(departments, 'id'))
  }
  const {
    departments
  } = useDepartmentList(null, onDepartmentListComplete)
  const convertToOptions = ({ id, name, group, ...v }) => {
    return {
      label: name,
      value: id,
      key: id,
      group
    }
  }
  const {
    loading,
    users
  } = useUserList(
    userSearchQuery
  )
  const filters = [
    {
      key: 'delegates',
      value: '담당변호사',
      options: users.map(v => ({ label: v.name, value: v.name }))
    }
  ].map(v => ({
    ...v,
    selectedItems: _.get(meta, ['filter', v.key], ''),
    selectedOptions: _.isEmpty(v.selectedOptions) ? v.options : v.selectedOptions,
    onChangeFilter: (value = []) => {
      onChangeMeta('filter', v.key, value)
    },
    onChangeDepartmentFilter: (value) => { // 작성자 > 부서 목록 변경시 업데이트
      setSelectedDepartmentIds(value)
    },
    onOpenChange: open => setOpenStatusByKey(openStatusByKey => ({ ...openStatusByKey, [v.key]: open }))
  }))

  return {
    loading,
    meta,
    filters,
    // caseNumbers,
    // users,
    // closedTag,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    openStatusByKey,
    departments
  }
}
