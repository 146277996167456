import { tableActionItems } from './TableActionItems'
import styled from 'styled-components'
import { Button } from 'antd'
import _ from 'lodash'

const Container = styled.div`
  display: flex;
  gap: 0px;
  `
// 엑셀 다운로드: 관리자, 복제: 관리자+직원, 삭제: 관리자+직원
export const TableActions = ({
  disabledTableActionByKey = {},
  hiddenTableActionByKey = {},
  handleStatViewer = () => {},
  handleCopyRecord = () => {},
  handleRemoveCallRecord = () => {}
}) => {
  return (
    <Container>
      {tableActionItems.map(({ label, key, icon }) => {
        return (
          <Button
            icon={icon}
            disabled={_.get(disabledTableActionByKey, [key], false)}
            key={key}
            type='text'
            style={{ padding: '10px', display: _.get(hiddenTableActionByKey, [key]) && 'none', opacity: _.get(disabledTableActionByKey, [key], false) && 'none' ? 0.5 : 1 }}
            onClick={() => {
              if (key === 'stat') {
                handleStatViewer()
              } else if (key === 'excelDownload') {
                console.log('excelDownload')
              } else if (key === 'copyRecord') {
                handleCopyRecord()
              } else { // deleteCallRecord
                handleRemoveCallRecord()
              }
            }}
          >
            {label}
          </Button>
        )
      })}
    </Container>
  )
}
