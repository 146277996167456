import { useContractRecordList } from '../apollo/useContractRecordList'

export const useProjectTypeDueAmount = (viewMode, groupModeContract, projectTypeId, outstanding = false, outstandingFrom = null, outstandingTo = null) => {

  /*
    계약 기록 데이터
  */
  const {
    // loading: filterLoading,
    // variables,
    // contractRecordsData: filterContractRecordsData,
    // totalCount: filterTotalCount,
    totalDueAmount: filterTotalDueAmount,
    // totalReceived: filterTotalReceived,
    // refetch
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    null, // to,
    null, // departmentId,
    'OVERDUE', // contractState,
    null, // deleted,
    null, // caseNumber,
    projectTypeId, // projectTypeId,
    null, // assignedLawyerId,
    viewMode === 'list' ? null : groupModeContract,
    outstanding, // outstanding
    outstandingFrom, // outstandingFrom
    outstandingTo // outstandingTo
  )
  return {
    // filterLoading,
    // filterContractRecordsData,
    // filterTotalCount,
    filterTotalDueAmount,
    // filterTotalReceived
  }
}
