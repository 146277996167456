import { useQuery } from '@apollo/client'
import { getCallRecordList } from '../../apollo/callRecordQueries'
import _ from 'lodash'

export const useCallRecordList = (
  ascending = false,
  key = null,
  searchQuery,
  recordType,
  from,
  to,
  counselLawyerId = null,
  departmentId = null,
  contractState,
  deleted = false,
  groupMode
) => {
  counselLawyerId = counselLawyerId[0]
  departmentId = departmentId[0]
  contractState = contractState[0]
  const variables = {
    sortBy: {
      ascending,
      key: 'inputDatetime'
    },
    filter: {
      searchQuery,
      recordType,
      ...from && to
        ? {
            dateRange: {
              from,
              to
            }
          }
        : {},
      ..._.isEmpty(counselLawyerId) ? { } : { counselLawyerId },
      ..._.isEmpty(departmentId) ? { } : { departmentId },
      ..._.isEmpty(contractState) ? { } : { contractState }
    },
    groupBy: groupMode
  }

  const { data, loading, refetch } = useQuery(getCallRecordList, {
    variables,
    fetchPolicy: 'no-cache'
  })

  const format = (groupMode === 'NAME' || groupMode === 'TYPE') ? 'groups' : 'records'
  const callRecordsData = _.get(data, ['GetCallRecordList', format], [])
  const parseCallRecords = (callRecords) => {
    return _.map(callRecords, callRecord => {
      const counselLawyerIds = _.get(callRecord, 'counselLawyers', [])?.map(lawyer => lawyer.id) || []
      const inputDatetime = _.get(callRecord, 'inputDatetime', null)
      const visitReserved = _.get(callRecord, 'visitReserved', null)
      const visited = _.get(callRecord, 'visited', null)
      const [inputDate, inputTime] = inputDatetime ? inputDatetime.split(' ') : [null, null]
      const [visitReservedDate, visitReservedTime] = visitReserved ? visitReserved.split(' ') : [null, null]
      const [visitedDate, visitedTime] = visited ? visited.split(' ') : [null, null]
      return {
        ...callRecord,
        key: _.get(callRecord, ['id'], {}),
        inputDate,
        inputTime,
        visitReservedDate,
        visitReservedTime,
        visitedDate,
        visitedTime,
        counselLawyerIds
      }
    })
  }
  return {
    loading,
    variables,
    callRecordsData: parseCallRecords(callRecordsData),
    totalCount: _.get(data, ['GetCallRecordList', 'totalCount'], 0),
    refetch
  }
}
