import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContractFilter } from '../../filter/contractRecordDetail/useContractFilter'
import { navigateToContractRecordDetail } from '../../../cacheAndNavigation/contractRecordDetail/navigation'
import { useBlockModal } from '../../../../timeCharge/hooks/common/useBlockModal'
import _ from 'lodash'
import { useContractPaymentsAddEditRemove } from '../useContractPaymentsAddEditRemove'
import { useCommentAddRemove } from '../useCommentAddRemove'
import { useDepositAddEditRemove } from '../useDepositAddEditRemove'

export const useSavingContractRecordDetailForm = (payment, formStatus, newContractIds = [], deletedContractIds = [], refetch = () => {}, setNewContractIds, setDeletedContractIds, newReasonIds, setNewReasonIds, removedReasonIds, setRemovedReasonIds, newDepositIds, setNewDepositIds, removedDepositIds, setRemovedDepositIds, pageDelete) => {
  const [readOnly, setReadOnly] = useState(true)
  const navigate = useNavigate()
  const { searchParams } = useContractFilter()
  const searchString = searchParams.toString()
  const onEdit = () => {
    setReadOnly(!readOnly)
    navigateToContractRecordDetail(navigate, payment, searchString, 'editing')
  }
  const onCancel = () => {
    setReadOnly(!readOnly)
    navigateToContractRecordDetail(navigate, payment, searchString, 'view')
  }
  const {
    onCreateContractPayments,
    onRemoveContractPayment,
    onEditContractPayment
  } = useContractPaymentsAddEditRemove(refetch)

  const {
    onAddComment,
    onEditComment,
    onRemoveComment
  } = useCommentAddRemove(refetch)

  const {
    onAddDepositRecord,
    onEditDepositRecord,
    onRemoveDepositRecord
  } = useDepositAddEditRemove(refetch)

  const onSave = async (form, id) => {
    const values = form.getFieldValue()
    const combinedContracts = _.concat(values.contract, values.extraContract)
    const reasonList = values.reasonList
    const depositList = values.depositRecordList
    // added 에 추가 되면 추가
    // removed에 추가 되면 삭제
    const formattedDataArray = _.chain(combinedContracts)
      .filter((item) => newContractIds.includes(item.periodicId))
      .map((item) => {
        if (item.periodic !== null) { // 주기 입금
          return {
            contractId: id,
            periodic: item.periodic,
            periodicId: item.periodicId,
            payments: _.map(item.payments, (payment) => ({
              category: item.category,
              dueAmount: payment.dueAmount,
              dueDate: payment.dueDate,
              expenseProofMethod: null,
              type: null
            }))
          }
        } else { // 단일 입금
          return {
            contractId: id,
            periodic: item.periodic,
            periodicId: item.periodicId,
            payments: [
              {
                category: item.category,
                dueAmount: item.totalDueAmount,
                dueDate: item.dueDate,
                expenseProofMethod: null,
                type: null
              }
            ]
          }
        }
      })
      .value()

    // const matchedIds = _.map(find, (item) => {
    //   const matchedContract = _.find(contract, { category: item.category });
    //   return matchedContract ? matchedContract.id : null;
    // }).filter(Boolean); // Filter out nulls for items without a match
    
    const editedFormatDataArray = _.chain(combinedContracts)
      .filter((item) => !newContractIds.includes(item.periodicId))
      .map((item) => {
        return {
          periodicId: item.periodicId,
          input: {
            category: item.category,
            dueAmount: item.dueAmount,
            dueDate: item.dueDate,
            expenseProofMethod: null,
            type: null
          }
        }
      })
      .value()

    const addCommentFormatDataArray = _.chain(reasonList)
      .filter((item) => newReasonIds.includes(item.id))
      .map((item) => {
        return {
          contractId: id,
          content: item.content
        }
      })
      .value()

    const editCommentFormatDataArray = _.chain(reasonList)
      .filter((item) => !newReasonIds.includes(item.id))
      .map((item) => {
        return {
          editCommentId: item.id,
          content: item.content
        }
      })
      .value()

    const addDepositFormatDataArray = _.chain(depositList)
      .filter((item) => newDepositIds.includes(item.key))
      .map((item) => {
        const matchedContract = _.find(combinedContracts, { category: item.category })
        return {
          id: matchedContract.id,
          input: {
            received: item.received,
            receivedAmount: item.receivedAmount,
            type: item.type,
            expenseProofMethod: item.expenseProofMethod
          }
        }
      })
      .value()

    const editedDepositFormatDataArray = _.chain(depositList)
      .filter((item) => !newDepositIds.includes(item.id))
      .map((item) => {
        console.log('🚀 ~ .map ~ item:', item)
        return {
          id: item.id,
          input: {
            // category: item.category,
            expenseProofMethod: item.expenseProofMethod,
            received: item.received,
            receivedAmount: item.receivedAmount,
            type: item.type
          }
        }
      })
      .value()
    try {
      setReadOnly(!readOnly)
      if (deletedContractIds.length > 0) {
        _.forEach(deletedContractIds, onRemoveContractPayment)
      }
      if (newReasonIds.length > 0) {
        _.forEach(addCommentFormatDataArray, onAddComment)
      }
      if (removedReasonIds.length > 0) {
        _.forEach(removedReasonIds, onRemoveComment)
      }
      if (newDepositIds.length > 0) {
        _.forEach(addDepositFormatDataArray, onAddDepositRecord)
      }
      if (removedDepositIds.length > 0) {
        _.forEach(removedDepositIds, onRemoveDepositRecord)
      }
      if (newContractIds.length > 0) {
        _.forEach(formattedDataArray, onCreateContractPayments)
      }
      _.forEach(editCommentFormatDataArray, onEditComment)
      _.forEach(editedFormatDataArray, onEditContractPayment)
      _.forEach(editedDepositFormatDataArray, onEditDepositRecord)
      setNewContractIds([])
      setDeletedContractIds([])
      setNewReasonIds([])
      setRemovedReasonIds([])
      setNewDepositIds([])
      setRemovedDepositIds([])
    } catch (error) {
    }
  }
  const onDelete = () => {
    console.log('delete')
  }

  const {
    fetching,
    onFetchAndNav,
    blockModalOpen,
    onOkCancelModal,
    onCloseCancelModal
  } = useBlockModal(null, formStatus === 'editing' && !pageDelete)
  return {
    cancelModalOpen: blockModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onEdit,
    onCancel,
    onSave,
    onDelete,
    readOnly
  }
}
