import { useOutletContext } from 'react-router-dom'
import { convertToDayjs, parseDateObj } from '../../../common/util'
import { MESSAGES } from '../../../common/config'
import _ from 'lodash'
import { useMutation } from '@apollo/client'
import { addCallRecord, editCallRecord } from '../../apollo/mutations'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { checkDateTime } from '../../components/common/CommonFunction'

dayjs.locale('ko')

export const useCallRecordSaveEdit = (form, setScratchPadList, refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addCallRecordMutFn] = useMutation(addCallRecord)
  const [editCallRecordMutFn] = useMutation(editCallRecord)
  const handleError = () => {
    messageApi.open(MESSAGES.recordAddError)
  }
  const reformatTime = (value, type) => {
    if (type === 'minutes') {
      if (value !== null) {
        value = parseDateObj(value, 'HH:mm:ss')
        const [hours, minutes] = _.split(value, ':').map(_.parseInt)
        return hours * 60 + minutes
      } else {
        return null
      }
    }
  }
  const returnIdOrNull = (value) => {
    if (value === null) {
      return null
    }
    if (_.isString(value)) { // 부서 입력시 key만 넘겨줘서 string
      return value
    } else if (value.id !== null) { // 입력되어있는 부서는 객체라서 id만 가져온다
      return value.id
    } else {
      return null
    }
  }

  const reformatToIdArray = (value) => {
    const isArrayOfObjects = _.every(value, item => _.isObject(item) && 'id' in item && 'name' in item && '__typename' in item)
    if (isArrayOfObjects) {
      return _.map(value, 'id')
    } else {
      return value
    }
  }

  const parseCallRecords = (name) => {
    return {
      type: name.type || null,
      callCount: name.callCount || null,
      name: name.name || null,
      gender: name.gender || null,
      age: name.age || null,
      phone: name.phone || null,
      email: name.email || null,
      address: name.address || null,
      primaryAuthorId: returnIdOrNull(name.primaryAuthor),
      secondaryAuthorId: returnIdOrNull(name.secondaryAuthor),
      inputDatetime: checkDateTime(name.inputDate, name.inputTime) || null,
      projectTypeId: returnIdOrNull(name.projectType),
      projectSubTypeId: returnIdOrNull(name.projectSubType),
      departmentId: returnIdOrNull(name.department) || null,
      via: name.via || null,
      expectedCaseName: name.expectedCaseName || null,
      managerId: returnIdOrNull(name.manager) || null,
      amount: name.amount || null,
      jurisdiction: name.jurisdiction || null,
      caseNumber: name.caseNumber || null,
      content: name.content || null,
      callType: name.callType || null,
      visitReserved: checkDateTime(name.visitReservedDate, name.visitReservedTime) || null,
      reservationStatus: name.reservationStatus || null,
      reservationCancelReason: name.reservationCancelReason || null,
      visited: checkDateTime(name.visitedDate, name.visitedTime) || null,
      visitor: name.visitor || null,
      visitDepartmentId: returnIdOrNull(name.visitDepartment) || null,
      counselLawyers: reformatToIdArray(name.counselLawyers) || null,
      counselEmployees: reformatToIdArray(name.counselEmployees) || null,
      nVisit: name.nVisit || null,
      nRevisit: name.nRevisit || null,
      contractor: name.contractor || null,
      assigned: name.assigned || null,
      nAssign: name.nAssign || null,
      cancelled: name.cancelled || null,
      cancelReason: name.cancelReason || null
    }
  }
  const onSaveCallRecord = (name, order) => {
    name = _.head(name)
    if (name !== undefined) { // 추후에 scratchpad에서 값이 하나라도 변경되야 저장
      const transformedData = parseCallRecords(name)
      addCallRecordMutFn({
        variables: {
          input: transformedData
        }
      }).then(v => {
        if (_.get(v, ['data', 'AddCallRecord', 'ok'])) {
          refetch()
        }
      }).catch(e => {
        handleError()
      })
      let removedItems = []
      const data = JSON.parse(localStorage.getItem('scratchPad'))
      const removedData = _.filter(data, function (item) {
        if (_.includes(name.key, item.key)) {
          removedItems.push(item)
          return false
        }
        return true
      })
      form.setFieldsValue({ scratchPad: removedData })
      localStorage.setItem('scratchPad', JSON.stringify(removedData))
      setScratchPadList(form.getFieldValue('scratchPad'))
    }
  }
  const onEditCallRecord = (id, input) => {
    const transformedData = parseCallRecords(input)
    editCallRecordMutFn({
      variables: {
        editCallRecordId: id,
        input: transformedData
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditCallRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      console.log(e)
      handleError()
    })
  }

  return {
    onSaveCallRecord,
    onEditCallRecord
  }
}
