import { useEffect, useRef, useState } from 'react'
import { uuidv4 } from '../../../common/util'
import { TableActions } from './TableActions'
import { ViewMode } from '../recordCustomTable/ViewMode'
import { RowGapWrapper } from '../../../common/Theme/style'
import { getCurrentUser } from '../../../timeCharge/apollo/queries'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { TotalIndicator } from '../../../timeCharge/components/common/CustomTable/TotalIndicator'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Input, InputNumber, Radio, Table, Form, Switch } from 'antd'

import { useQuery } from '@apollo/client'
import _ from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { GroupMode } from '../recordCustomTable/GroupMode'

const Container = styled.div` // 테이블 파란색 줄
  display: flex;
  flex-direction: column;
  width: 100%;
  /* .ant-table-thead .ant-table-cell {
    background: white;
    border-bottom: 0.5px solid ${COLORS.primaryColor50};
  } */
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`

export const CallCustomTable = ({
  data,
  defaultColumns = [],
  TableComponent = Table,
  total,
  unit,
  loading,
  pageType,
  onClickRow = () => {},
  disabledTableActionByKey = {
    stat: false,
    excelDownload: true,
    copyRecord: false,
    delete: false
  },
  hiddenTableActionByKey,
  selectedRowKeys,
  setSelectedRowKeys,
  onChangeSelectedRowKeys = () => {},
  editingKeys = [],
  viewMode,
  groupMode,
  hiddenKeys,
  onChangeViewMode = () => {},
  onChangeGroupMode = () => {},
  groupColumns,
  typeGroupColumns,
  scroll,
  saveAll = () => {},
  editAll = () => {},
  handleStatViewer = () => {},
  rowSelection,
  recordStatus,
  scratchPadList = [],
  setScratchPadList,
  form,
  handleRemoveCallRecord = () => {},
  handleNavigateContractState = () => {},
  placement,
  placementChange = () => {}
}) => {
  const [dataSource, setDataSource] = useState(data) // 콜 기록 데이터
  const [columns, setColumns] = useState(defaultColumns)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [showDetail, setShowDetail] = useState(false)
  const { data: users } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  // useEffect(() => {
  //   placementChange(placement)
  // }, [data])
  const showDetailColumns = ['SN'] // 상세보기 항목
  const currentUser = _.get(users, ['CurrentUser'])
  const handleCopyRecord = () => {
    const data = selectedRowData
    const resetData = _.map(data, (v) => { // name 복제
      const item = _.cloneDeep(v)
      _.set(item, 'type', null)
      _.set(item, 'created', dayjs(new Date()))
      _.set(item, 'inputDate', null)
      _.set(item, 'inputTime', null)
      _.set(item, 'callType', null)
      _.set(item, 'department', null)
      _.set(item, 'primaryAuthor', null)
      _.set(item, 'secondaryAuthor', null)
      _.set(item, 'projectType', null)
      _.set(item, 'projectSubType', null)
      _.set(item, 'address', null)
      _.set(item, 'via', null)
      _.set(item, 'expectedCaseName', null)
      _.set(item, 'jurisdiction', null)
      _.set(item, 'caseNumber', null)
      _.set(item, 'manager', null)
      _.set(item, 'amount', 0)
      _.set(item, 'content', null)
      _.set(item, 'visitDepartment', null)
      _.set(item, 'visitReservedDate', null)
      _.set(item, 'visitReservedTime', null)
      _.set(item, 'reservationStatus', null)
      _.set(item, 'reservationCancelReason', null)
      _.set(item, 'callCount', null)
      _.set(item, 'visitedDate', null)
      _.set(item, 'visitedTime', null)
      _.set(item, 'visitor', null)
      _.set(item, 'counselEmployees', null)
      _.set(item, 'createdBy', currentUser.name)
      _.set(item, 'nVisit', null)
      _.set(item, 'nRevisit', null)
      _.set(item, 'contractor', null)
      _.set(item, 'assigned', null)
      _.set(item, 'nAssign', null)
      _.set(item, 'cancelled', null)
      _.set(item, 'cancelReason', null)
      _.set(item, 'contractState', null)
      _.set(item, 'key', uuidv4())
      return item
    })
    const removeHeadResetData = _.head(resetData) // callRecord에서 복제된 데이터
    const storedData = JSON.parse(localStorage.getItem('scratchPad') || '[]') // 현재 scartchpad에 저장 되어있는 데이터
    const newData = [...storedData, removeHeadResetData]
    form.setFieldsValue({ scratchPad: newData })
    localStorage.setItem('scratchPad', JSON.stringify(newData))
    setScratchPadList(form.getFieldValue('scratchPad'))
    // setScratchPadList(newData)

    setSelectedRowData([])
    setSelectedRowKeys([])
    onChangeSelectedRowKeys([])
  }
  const filterColumns = (placement, grouped, groupedPlacement) => {
    if (grouped === true) { // 그룹화 목록
      if (placement === 'NOTYPE') { // 온오프구분이 없는 경우
        return !showDetail ? _.reject(defaultColumns, column => _.includes(showDetailColumns, column.title)) : defaultColumns // 상세보기에 따른 항목 필터 (그룹 뷰)
      } else { // 온오프구분이 있는 경우
        const filteredColumns = defaultColumns.filter(column => column.category?.includes(placement) && column.dataIndex !== 'edit')
        return !showDetail ? _.reject(filteredColumns, column => _.includes(showDetailColumns, column.title)) : filteredColumns
      }
    } else if (placement === 'ALL') {
      return defaultColumns
    }
    return defaultColumns.filter(column => column.category?.includes(placement)) // placement === 온라인, 오프라인 column
  }

  // // 전체/온라인/오프라인/방문&선임에 따라 data 필터
  // const placementChange = (value) => {
  //   const newPlacement = value
  //   setPlacement(newPlacement)
  //   const filteredColumns = filterColumns(newPlacement)
  //   setColumns(filteredColumns)
  //   if (newPlacement === 'ALL') {
  //     setDataSource(data)
  //   } else {
  //     const filteredData = _.filter(data, ['type', newPlacement])
  //     setDataSource(filteredData)
  //   }
  // }

  useEffect(() => {
    if (viewMode === 'list' || pageType === 'contractRecord') {
      const filteredColumns = filterColumns(placement)
      setColumns(filteredColumns)
    } else if (groupMode === 'NAME') {
      setColumns(groupColumns)
    } else {
      setColumns(typeGroupColumns)
    }
  }, [viewMode, placement, defaultColumns, groupMode])

  const EditableCell = ({ editing, dataIndex, title, inputType, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />
    return (
      <td {...restProps}>
        {editing
          ? (
            <Form.Item
              name={dataIndex}
              style={{ margin: 0 }}
              defaultValue={dataIndex}
              initialValue={dataIndex}
              rules={[{ required: true, message: `Please Input ${title}!` }]}
            >
              {inputNode}
            </Form.Item>)
          : (
              children)}
      </td>
    )
  }
  const textRef = useRef()
  const isEditing = (record) => null
  const mergedColumns = columns?.map((col) => {
    return {
      ...col,
      hidden: !showDetail && showDetailColumns.includes(col.title), // 상세보기에 따른 항목 필터 (목록 뷰)
      onCell: (record) => ({
        record,
        inputtype: col.dataIndex === 'id' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ref: isEditing(record) ? textRef : null
      })
    }
  })

  const handleRowSelectionChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
    const selectedRowsData = dataSource.filter(row => selectedRowKeys.includes(row.key))
    if (pageType === 'call') {
      setSelectedRowData(selectedRowsData)
    }
    onChangeSelectedRowKeys(selectedRowKeys)
  }

  const onExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : [])
  }

  const starredList = _.filter(dataSource, ['starred', true]) // 즐겨찾기 항목 필터

  const expandedRowRender = (value) => {
    const onlineData = _.filter(value.records, { type: 'ONLINE' })
    const offlineData = _.filter(value.records, { type: 'OFFLINE' })
    const visitData = _.filter(value.records, { type: 'VISIT' })
    const noType = _.filter(value.records, { type: null })
    return (
      <div>
        {onlineData.length > 0 && <Table columns={filterColumns('ONLINE', true, '온라인')} dataSource={onlineData} pagination={false} style={{ marginLeft: 40 }} />}
        {offlineData.length > 0 && <Table columns={filterColumns('OFFLINE', true, '오프라인')} dataSource={offlineData} pagination={false} style={{ marginLeft: 40 }} />}
        {visitData.length > 0 && <Table columns={filterColumns('VISIT', true, '방문&선임')} dataSource={visitData} pagination={false} style={{ marginLeft: 40 }} />}
        {noType.length > 0 && <Table columns={filterColumns('NOTYPE', true, '')} dataSource={noType} pagination={false} style={{ marginLeft: 40 }} />}
      </div>
    )
  }
  const expandableConfig = viewMode === 'group'
    ? {
        expandedRowRender,
        defaultExpandedRowKeys: ['0']
      }
    : undefined

  const handleFormChange = (changedValues, allValues) => {
  }
  return (
    <Form
      form={form}
      onValuesChange={(changedValues, allValues) => {
        handleFormChange(changedValues, allValues)
      }}
      // initialValues={{ call: dataSource }}
    >
      <Container>
        {/* <Button onClick={() => { console.log('call', form.getFieldValue()) }}>DEBUG!</Button> */}
        <HeaderGroup style={{ marginBottom: '10px' }}>
          <RowGapWrapper $gap={15}>
            {(pageType === 'call' && viewMode === 'list') && pageType !== 'contractRecordDeleted' // 콜 기록 페이지와 목록 뷰에서만 테이블 항목 선택지 제공
              ? (
                <Radio.Group value={placement} onChange={(e) => placementChange(e.target.value)}>
                  <Radio.Button value='ALL'>전체</Radio.Button>
                  <Radio.Button value='ONLINE'>온라인</Radio.Button>
                  <Radio.Button value='OFFLINE'>오프라인</Radio.Button>
                  <Radio.Button value='VISIT'>방문&선임</Radio.Button>
                </Radio.Group>)
              : null}
            {pageType !== 'contractRecordDeleted' &&
              <TableActions
                disabledTableActionByKey={disabledTableActionByKey}
                hiddenTableActionByKey={hiddenTableActionByKey}
                handleStatViewer={handleStatViewer}
                handleCopyRecord={handleCopyRecord}
                handleRemoveCallRecord={handleRemoveCallRecord}
              />}
            {viewMode === 'list' && pageType !== 'contractRecordDeleted' && pageType !== 'overdue'
              ? (
                  editingKeys.length > 0
                    ? (
                      <Button
                        type='text'
                        style={{ padding: '4px' }}
                        onClick={() => saveAll()}
                      >
                        <RowGapWrapper>
                          <SaveOutlined style={{ color: `${COLORS.purple}` }} />
                          <div>전체 저장</div>
                        </RowGapWrapper>
                      </Button>)
                    : (
                      <Button
                        type='text'
                        style={{ padding: '4px' }}
                        onClick={() => editAll(dataSource)}
                        disabled={selectedRowKeys.length > 0}
                      >
                        <RowGapWrapper>
                          <EditOutlined style={{ color: selectedRowKeys.length > 0 ? `${COLORS.purple_2}` : `${COLORS.purple}` }} />
                          <div>전체 수정</div>
                        </RowGapWrapper>
                      </Button>))
              : null}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Switch
                checked={showDetail}
                onChange={() => setShowDetail(!showDetail)}
                checkedChildren='상세보기'
                unCheckedChildren='상세보기'
              />
            </div>
          </RowGapWrapper>
          <Spacer />
          {viewMode !== 'list'
            ? (
              <GroupMode
                groupMode={groupMode}
                hiddenKeys={hiddenKeys}
                onChangeGroupMode={onChangeGroupMode}
              />)
            : null}
          {pageType !== 'contractRecord' && pageType !== 'contractRecordDeleted'
            ? (
              <ViewMode
                viewMode={viewMode}
                hiddenKeys={hiddenKeys}
                onChangeViewMode={onChangeViewMode}
              />)
            : null}
          <TotalIndicator total={total} unit={unit} loading={loading} />
        </HeaderGroup>
        <Form.List name='call'>
          {(fields) => {
            return (
              <TableComponent
                rowKey='key'
                columns={mergedColumns}
                expandable={expandableConfig}
                onExpand={onExpand}
                expandedRowKeys={expandedRowKeys}
                dataSource={fields.map(field => ({ ...field, field, ...form.getFieldValue('call')[field.name] }))}
                pagination={false}
                pageType={pageType}
                size='middle'
                rowSelection={rowSelection !== null && viewMode === 'list' // group에서는 selection이 보이지 않게 설정
                  ? ({
                      type: 'checkbox',
                      selectedRowKeys,
                      getCheckboxProps: (record) => {
                        const disable = (editingKeys?.findIndex((item) => record.key === item)) !== -1
                        return {
                          disabled: disable
                        }
                      },
                      onChange: handleRowSelectionChange
                    })
                  : undefined}
                scroll={{
                  y: scroll
                }}
                components={{
                  body: {
                    cell: EditableCell
                  }
                }}
                onRow={(record) => ({
                  onClick: () => onClickRow(record, pageType)
                })}
              />
            )
          }}
        </Form.List>
        {/* <TableComponent
          rowKey='key'
          columns={mergedColumns}
          expandable={expandableConfig}
          onExpand={onExpand}
          expandedRowKeys={expandedRowKeys}
          dataSource={recordStatus === 'starred' ? starredList : dataSource}
          pagination={false}
          pageType={pageType}
          size='middle'
          rowSelection={rowSelection !== null && viewMode === 'list' // group에서는 selection이 보이지 않게 설정
            ? ({
                type: 'checkbox',
                selectedRowKeys,
                getCheckboxProps: (record) => {
                  const disable = (editingKeys?.findIndex((item) => record.key === item)) !== -1
                  return {
                    disabled: disable
                  }
                },
                onChange: handleRowSelectionChange
              })
            : undefined}
          scroll={{
            y: scroll
          }}
          components={{
            body: {
              cell: EditableCell
            }
          }}
          onRow={(record) => ({
            onClick: () => onClickRow(record, pageType)
          })}
        /> */}
      </Container>
    </Form>
  )
}
