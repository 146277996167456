import { navigateToOverdueList } from '../../../cacheAndNavigation/overdueRecord/navigation'
import { navigateToContractRecordDetail } from '../../../cacheAndNavigation/contractRecordDetail/navigation'
import { useNavigate } from 'react-router-dom'

export const useOverdueTable = () => {
  const navigate = useNavigate()

  const onClickRow = (contractRecordMeta, viewMode) => {
    if (viewMode === 'group') {
      // navigateToOverdueList(navigate, contractRecordMeta)
    } else {
      navigateToContractRecordDetail(navigate, contractRecordMeta)
    }
  }

  return {
    onClickRow
  }
}
