import { useState } from 'react'
import { Form } from 'antd'
import _ from 'lodash'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { MoneyContainer } from '../../../../common/Theme/style'

dayjs.locale('ko')

export const useOverdueViewerColumns = () => {
  const [dataSource, setDataSource] = useState([])
  const [editingKeys, setEditingKeys] = useState([])

  const getOverdueViewerTableGroupColumns = [
    {
      title: '부서',
      dataIndex: 'department',
      key: 'department',
      sort: true,
      width: 100
    },
    {
      title: '총 계약 금액',
      dataIndex: 'contractTotal',
      key: 'contractTotal',
      sort: true,
      width: 100,
      render: (contractTotal) => {
        return (
          <MoneyContainer value={contractTotal === null ? 0 : contractTotal} />
        )
      }
    },
    {
      title: '개수',
      dataIndex: 'nRecords',
      key: 'nRecords',
      sort: true,
      width: 110
    }
  ]

  const getOverdueViewerTableGroupTypeColumns = [
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 100,
      render: (projectType) => {
        return (
          <div>{projectType.name}</div>
        )
      }
    },
    {
      title: '총 계약 금액',
      dataIndex: 'contractTotal',
      key: 'contractTotal',
      sort: true,
      width: 100,
      render: (contractTotal) => {
        return (
          <MoneyContainer value={contractTotal === null ? 0 : contractTotal} />
        )
      }
    },
    {
      title: '개수',
      dataIndex: 'nRecords',
      key: 'nRecords',
      sort: true,
      width: 110
    }
  ]

  const [form] = Form.useForm()
  const save = async (key) => {
    try {
      const row = await form.validateFields()
      const newData = [...dataSource]
      const index = newData.findIndex((item) => key === item.key)

      _.remove(editingKeys, (item) => item === key)

      if (index > -1) {
        const item = newData[index]
        newData.splice(index, 1, { ...item, ...row })
        setDataSource(newData)
      } else {
        newData.push(row)
        setDataSource(newData)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  const saveAll = () => {
    try {
      _.remove(editingKeys)
      setDataSource(editingKeys)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  const edit = (key) => {
    setEditingKeys([...editingKeys, key])
  }

  const editAll = (data) => {
    try {
      setEditingKeys(_.map(data, 'key'))
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }
  return {
    getOverdueViewerTableGroupColumns,
    getOverdueViewerTableGroupTypeColumns,
    editingKeys,
    saveAll,
    editAll
  }
}
