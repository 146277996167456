import { useMutation } from '@apollo/client'
import { addProjectType, editProjectType } from '../../apollo/mutations'
import _ from 'lodash'
import { useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../common/config'

export const useProjectTypeAddEdit = (onFinish = () => {}, onError = () => {}, refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [handleAddProjectType] = useMutation(addProjectType)
  const [handleEditProjectType] = useMutation(editProjectType)

  const onAddProjectType = async (color, name, order) => {
    return handleAddProjectType({
      variables: {
        type: {
          color,
          name,
          order
        }
      }
    }).then(v => {
      if (_.get(v, ['data', 'AddProjectType', 'ok'])) onFinish()
      else {
        messageApi.open(MESSAGES.projectTypeAddError)
        onError()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.projectTypeAddError)
      onError()
    })
  }

  const onEditProjectType = async (projectTypeId, color, name, order) => {
    return handleEditProjectType({
      variables: {
        editProjectTypeId: projectTypeId,
        input: {
          color,
          name,
          order
        }
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditProjectType', 'ok'])) onFinish()
      else {
        messageApi.open(MESSAGES.projectTypeEditError)
        onError()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.projectTypeEditError)
      onError()
    })
  }

  return {
    onAddProjectType,
    onEditProjectType
  }
}
