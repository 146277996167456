import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { BlockModal } from '../../../../timeCharge/components/common/BlockModal'
import { useBlockModal } from '../../../../timeCharge/hooks/common/useBlockModal'
import ProjectTypeForm from '../../../../timeCharge/components/form/ProjectTypeForm'
import { HeaderContentFooter } from '../../../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderTitle } from '../../../../common/components/PageHeaderStyles'
import { getProjectTypeFormStatus } from '../../../../timeCharge/cacheAndNavigation/setting/cache'
import { useSaveDeleteProjectTypeForm } from '../../../../timeCharge/hooks/settings/projectType/useSaveDeleteProjectTypeForm'

const AddProjectTypeContainer = () => {
  const navigate = useNavigate()
  const departmentFormStatus = getProjectTypeFormStatus()
  const prevDepartmentFormStatus = useRef(departmentFormStatus)
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()
  useEffect(() => {
    if (!blockModalOpen) prevDepartmentFormStatus.current = departmentFormStatus
  }, [blockModalOpen])

  const { onSave } = useSaveDeleteProjectTypeForm(onFetchAndNav)
  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='사건분류 등록' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <ProjectTypeForm
              onSave={onSave}
              onCancel={() => navigate(-1)}
            />
          </div>
        )}
      />
      <BlockModal
        title='사건분류 등록을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default AddProjectTypeContainer
