import TableTitle from './TableTitle'
import TableTitleWithSort from './TableTitleWithSort'

import { PageLimit } from './PageLimit'
import { TableActions } from './TableActions'
import { TotalIndicator } from './TotalIndicator'
import { ContentHeaderContainer, HeaderGroup, Spacer } from '../../../../common/components/PageHeaderStyles'

import './customTable.css'
import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors'

import { parseDateObj } from '../../../../common/util'
import { Pagination, Table, Tooltip, Typography } from 'antd'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table-thead .ant-table-cell {
    background: white;
    border-bottom: 0.5px solid ${COLORS.primaryColor50};
  }
`
const TableContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
`
const PaginationContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: center;
`

export const CustomTable = ({
  total = 0,
  loading,
  rowHeight = 5,
  extraHeaderItem = null,
  data,
  sortKey,
  sortOrder,
  page,
  tableActionHidden,
  limit,
  columns = [],
  disabledTableActionByKey = {
    stat: false,
    excelUpload: false,
    excelDownload: false,
    templateDownload: false
  },
  hiddenTableActionByKey,
  pageLimitHidden,
  onSortUp,
  onSortDown,
  expandable = {},
  onClickRow = () => {},
  onChangePage,
  onChangePageLimit,
  onClickTableActions,
  onChangeHoveredKey = () => {},
  onChangeSelectedRowKeys = () => {},
  preChartColumns = [],
  preEmployeeColumns = [],
  preDepartmentColumns = [],
  chartState,
  pagination = true,
  // excel related props
  excelProps = {},
  selectedRowKeys,
  unit,
  TableComponent = Table,
  refetch,
  fileDownloadLoading,
  spinning,
  percent,
  ...props
}) => {
  return (
    <Container>
      <ContentHeaderContainer>
        {tableActionHidden
          ? <HeaderGroup>{extraHeaderItem}</HeaderGroup>
          : (
            <HeaderGroup>
              <TableActions
                disabledTableActionByKey={disabledTableActionByKey}
                hiddenTableActionByKey={hiddenTableActionByKey}
                excelProps={excelProps}
                onClick={onClickTableActions}
                dataCategory='case'
                refetch={refetch}
                fileDownloadLoading={fileDownloadLoading}
                spinning={spinning}
                percent={percent}
                selectedRowKeys={selectedRowKeys}
              />
            </HeaderGroup>)}
        <Spacer />
        <HeaderGroup>
          {tableActionHidden ? null : extraHeaderItem}
          <TotalIndicator total={total} unit={unit} loading={loading} />
          {pageLimitHidden ? null : <PageLimit value={limit} onChange={v => onChangePageLimit(v)} />}
        </HeaderGroup>
      </ContentHeaderContainer>
      <TableContainer>
        <TableComponent
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            getCheckboxProps: (record) => ({
              disabled: record.disabled
            }),
            onChange: (selectedRowKeys, selectedRows) => {
              onChangeSelectedRowKeys(selectedRowKeys)
            }
          }}
          onRow={(record) => {
            return {
              style: { height: rowHeight },
              onClick: () => onClickRow(record),
              onMouseEnter: () => onChangeHoveredKey(record.key),
              onMouseLeave: () => onChangeHoveredKey(null)
            }
          }}
          columns={(chartState === '직원' ? preEmployeeColumns : chartState === '부서' ? preDepartmentColumns : columns).map(
            (v) => {
              if (v.sort) {
                return {
                  ...v,
                  title: (
                    <TableTitleWithSort
                      title={v.title}
                      sortOrder={v.key === sortKey ? sortOrder : null}
                      onUp={() => onSortUp(v.key)}
                      onDown={() => onSortDown(v.key)}
                    />
                  )
                }
              } else if (v.isDate) {
                return {
                  ...v,
                  render: (date) => (
                    <Tooltip title={parseDateObj(date, 'YYYY년 M월 D일 HH:MM')}>
                      <Typography.Text>{parseDateObj(date)}</Typography.Text>
                    </Tooltip>
                  )
                }
              } else {
                return {
                  ...v,
                  title: (
                    <TableTitle
                      title={v.title}
                    />
                  )
                }
              }
            })}
          loading={loading}
          expandable={expandable}
          dataSource={data}
          {...props}
          pagination={false}
          size='middle'
          rowClassName={record => record.disabled && 'disabled-row'}
        />
      </TableContainer>
      {pagination
        ? (
          <PaginationContainer>
            <Pagination
              showSizeChanger={false}
              current={page}
              total={total}
              pageSize={limit}
              onChange={(v) => onChangePage(v)}
            />
          </PaginationContainer>
          )
        : null}
    </Container>
  )
}
