import { useEffect, useRef, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { convertToDayjs, parseDateObj, uuidv4 } from '../../../common/util'
import { ContractContainer } from './ContractContainer'
import { InfoCard } from '../../components/contractRecordDetail/InfoCard'
import { getCurrentUser } from '../../../timeCharge/apollo/queries'
import { useConfirmModal } from '../../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { CardHeader } from '../../components/contractRecordDetail/CardHeader'
import { contractStateTag } from '../../components/common/CommonFunction'
import ContractRecordDetailFilter from '../../components/filter/ContractRecordDetailFilter'
import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { getRecordFormStatus } from '../../cacheAndNavigation/contractRecordDetail/cache'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { useContractFilter } from '../../hooks/filter/contractRecordDetail/useContractFilter'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'
import { ContractTotalRecordCard } from '../../components/contractRecordDetail/ContractTotalRecordCard'
import { useContractorInfoColumns } from '../../hooks/contractRecord/contractRecordDetail/useContractorInfoColumns'
import { useEditContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useEditContractRecordDetailForm'
import { useSavingContractRecordDetailForm } from '../../hooks/contractRecord/contractRecordDetail/useSavingContractRecordDetailForm'
import { ColumnGapWrapper, GrayCustomCard, NarrowCustomCard, RowGapWrapper } from '../../../common/Theme/style'
import { HeaderContainer, HeaderGroup, PaymentHeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'
import { customerInfo, customerInfoTable, extraPaymentCategoryOptions, paymentCategoryOptions, tmpDuplicationData } from '../../config'

import { useQuery } from '@apollo/client'
import COLORS from '../../../common/Theme/colors'
import styled from 'styled-components'
import _ from 'lodash'
import { Button, Modal, Table, Tabs, Form } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { useContractRecord } from '../../hooks/apollo/useContractRecord'
import { useContractRecordRemove } from '../../hooks/contractRecord/useContractRecordRemove'
import { useContractRecordAddEditRemove } from '../../hooks/contractRecord/useContractRecordAddEditRemove'
dayjs.locale('ko')

const ContentContainer = styled.div`
`
const StyledTable = styled(Table)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table-thead .ant-table-cell {
    background: white;
    border-bottom: 0.5px solid ${COLORS.primaryColor50};
  }
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`
const CustomTabs = styled(Tabs)`
  .ant-tabs-tab {
    font-size: 14px;
    font-weight: 600;
    padding: 0px 0 12px;
  }
`
const TableCategory = styled.div`
  width: 100px;
  color: ${COLORS.black_65};
  display: flex;
  align-items: center;
`
const StyledModal = styled(Modal)`
  .ant-modal-content {
    /* width: 800px; */
    overflow: scroll;
  }
`
const HeaderSpacer = styled.div`
  box-shadow: 0px 2px 3px #DFDFDF86;
`
const StyledEditButton = styled(Button)`
  position: absolute;
  top: 11px;
  right: 10px;
  z-index: 999;
`
export const ContractRecordDetailContainer = () => {
  const { id } = useParams()
  const { permissions } = useOutletContext()
  const [pageDelete, setPageDelete] = useState(false)
  /*
  계약 기록 데이터
*/
  const {
    loading,
    contractRecordData,
    refetch
  } = useContractRecord(
    id
  )
  const parseAddKey = (data) => {
    return (
      _.map(data, v => {
        return {
          ...v,
          key: uuidv4()
        }
      })
    )
  }
  const formattedData = (value) => {
    return (
      _.map(_.groupBy(value, (record) => _.trim(record.periodicId)), (records, periodicId) => ({
        key: periodicId,
        id: records[0].id,
        periodicId,
        totalDueAmount: _.sumBy(records, 'dueAmount'),
        category: records[0].category,
        periodic: records[0].periodic,
        dueDate: records[0].dueDate,
        dueAmount: records[0].dueAmount,
        payments: _.map(records, (record) => ({
          key: record.id,
          dueDate: record.dueDate,
          dueAmount: record.dueAmount,
          // deposits: record.deposits,
          category: record.category
        }))
      })))
  }
  useEffect(() => {
    if (!loading) {
      setDataSource(contractRecordData)
      const paymentRecords = _.get(contractRecordData, 'paymentRecords', [])
      const paymentComments = _.get(contractRecordData, 'paymentComments', [])

      /* 총 계약 내역 계약 금액과 추가 계약 조건으로 분할 */
      const contracts = _.filter(paymentRecords, record =>
        ['DEPOSIT', 'INTERIM', 'BALANCE', 'CONSULT'].includes(record.category)
      )
      setContractDataSource(parseAddKey(contracts))

      const extraContracts = _.filter(paymentRecords, record =>
        ['CONTINGENT', 'EXTRA', 'ETC'].includes(record.category)
      )
      setExtraContractDataSource(parseAddKey(extraContracts))

      /* 추심 목록 */
      setReasonDataSource(paymentComments)
      /* 입금 내역 */
      const depositsArray = _.flatMap(_.filter(paymentRecords, record => record.deposits.length > 0), 'deposits')
      setDepositDataSource(depositsArray)

      /* form 초기 값 */
      const initialValues = {
        contract: formattedData(contracts),
        extraContract: formattedData(extraContracts),
        reasonList: paymentComments,
        depositRecordList: depositsArray
      }
      form.setFieldsValue(initialValues)
    }
  }, [contractRecordData])

  const [dataSource, setDataSource] = useState() // 계약 정보
  const [contractDataSource, setContractDataSource] = useState([]) // 계약 목록
  const [extraContractDataSource, setExtraContractDataSource] = useState([]) // 추가 계약 목록
  const [depositDataSource, setDepositDataSource] = useState([]) // 입금 내역
  const [reasonDataSource, setReasonDataSource] = useState([])
  const [currentTab, setCurrentTab] = useState('callerInfo')
  const [closeContract, setCloseContract] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenContractorInfo, setIsModalOpenContractorInfo] = useState(false)
  const [receivedAmount, setReceivedAmount] = useState(0) // 회수 금액
  const contractState = dataSource
  const [formEmpty, setFormEmpty] = useState(false)
  const [newContractIds, setNewContractIds] = useState([])
  const [deletedContractIds, setDeletedContractIds] = useState([])
  const [newReasonIds, setNewReasonIds] = useState([])
  const [removedReasonIds, setRemovedReasonIds] = useState([])
  const [newDepositIds, setNewDepositIds] = useState([])
  const [removedDepositIds, setRemovedDepositIds] = useState([])
  const [form] = Form.useForm() // 수정중인데 나갈 경우, 경고창
  const formStatus = getRecordFormStatus()
  const prevStatus = useRef(formStatus)
  const { data } = useQuery(getCurrentUser, {
    fetchPolicy: 'network-only'
  })
  const currentUser = _.get(data, ['CurrentUser'])

  useEffect(() => {
    sumReceivedAmount() // 회수금액
  }, [depositDataSource])

  const {
    cancelModalOpen,
    onCloseCancelModal,
    onOkCancelModal,
    onEdit,
    onCancel,
    onSave,
    readOnly
  } = useSavingContractRecordDetailForm(
    dataSource,
    formStatus,
    newContractIds,
    deletedContractIds,
    refetch,
    setNewContractIds,
    setDeletedContractIds,
    newReasonIds,
    setNewReasonIds,
    removedReasonIds,
    setRemovedReasonIds,
    newDepositIds,
    setNewDepositIds,
    removedDepositIds,
    setRemovedDepositIds,
    pageDelete
  )
  // 추심목록 및 입금 내역 삭제
  const handleDelete = (key, type) => {
    if (type === 'reasonList') {
      const newData = _.reject(reasonDataSource, { id: key })

      setReasonDataSource(newData)
      form.setFieldsValue({ reasonList: newData })

      /* 추가 계약 조건 */
      if (_.includes(newReasonIds, key)) {
        setNewReasonIds((current) => _.without(current, key))
      } else {
        setRemovedReasonIds((current) => [...current, key])
      }
    } else { // depositRecordList
      const newData = _.reject(depositDataSource, { id: key })

      setDepositDataSource(newData)
      form.setFieldsValue({ depositRecordList: newData })

      if (_.includes(newDepositIds, key)) {
        setNewDepositIds((current) => _.without(current, key))
      } else {
        setRemovedDepositIds((current) => [...current, key])
      }
    }
  }

  const {
    formItems
  } = useEditContractRecordDetailForm(
    readOnly,
    contractDataSource,
    setContractDataSource,
    reasonDataSource,
    extraContractDataSource,
    setExtraContractDataSource,
    depositDataSource,
    setDepositDataSource,
    handleDelete,
    currentUser
  )

  const {
    onRemoveContractRecord
  } = useContractRecordAddEditRemove(
    refetch
  )

  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal
  } = useConfirmModal(
    onRemoveContractRecord
    // onDeleteCallRecord,
    // onDeleteContract,
    // onDeleteContractItem,
    // onDeleteComment,
    // onDeletePaymentRecord
  )

  const {
    meta,
    filters,
    onChangeMeta,
    onChangeFilter,
    onResetFilterAndGroup
  } = useContractFilter()

  const {
    callDataSource,
    isDuplicateCaseNumber,
    isDuplicateOpponent,
    duplicationTableColumns
  } = useContractorInfoColumns() // 콜 고객 정보, 계약자 정보

  // 종결 버튼 상태 확인
  const handleClose = () => {
    setCloseContract(!closeContract)
  }
  const onOkDeleteConfirmModal = () => {
    setPageDelete(true)
    onRemoveContractRecord(id)
  }
  const handleOk = () => {
    setIsModalOpen(false)
    setIsModalOpenContractorInfo(false)
    setPageDelete(true)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setPageDelete(false)
    setIsModalOpenContractorInfo(false)
  }
  const handleModal = (value) => {
    if (value === 'duplication') {
      setIsModalOpen(true)
    } else if (value === 'contractor') {
      setIsModalOpenContractorInfo(true)
    }
  }

  const handleAdd = (dataIndex, depositType, category, receivedAmount = 0, type = 'CARD', expenseProofMethod = 'CASHRECEIPT') => {
    if (dataIndex === 'reasonList') { // 추심 목록 추가
      const newId = uuidv4()
      const newData = {
        key: newId,
        id: newId,
        createdBy: currentUser.name,
        content: ''
      }
      setReasonDataSource([...reasonDataSource, newData])
      form.setFieldsValue({ reasonList: [...reasonDataSource, newData] })
      setNewReasonIds([...newReasonIds, newId])
    } else { // 입금 내역 목록 추가
      const newId = uuidv4()
      const newData = {
        key: newId,
        id: newId,
        category,
        expenseProofMethod,
        received: parseDateObj(dayjs(new Date())),
        receivedAmount,
        type,
        createdBy: currentUser?.name
      }
      setDepositDataSource([...depositDataSource, newData])
      form.setFieldsValue({ depositRecordList: [...depositDataSource, newData] })
      setNewDepositIds([...newDepositIds, newId])
      sumReceivedAmount()
    }
  }
  const checkFormEmpty = () => {
    const values = form.getFieldsValue()
    if (Array.isArray(values)) {
      const hasEmptyFields = Object.values(values).some(value => !value)
      if (hasEmptyFields) {
        setFormEmpty(true)
        return false
      }
    }
    return true
  }

  const handleSave = () => {
    onSave(form, contractRecordData.id)
    navigateToContractRecordDetail(navigate, contractRecordData, searchString, 'view')
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    }
  }
  // 계약 총 금액
  const contractTotal = _.sumBy(contractDataSource, 'dueAmount') + _.sumBy(extraContractDataSource, 'dueAmount')
  // 회수금액
  const sumReceivedAmount = () => {
    const sum = _.sumBy(depositDataSource, 'receivedAmount')
    setReceivedAmount(sum)
  }
  const InfoContainer = () => {
    let currentData = _.get(dataSource, ['callRecord'])
    if (currentTab !== 'callerInfo') {
      currentData = dataSource
    }
    return (
      <>
        <ColumnGapWrapper $gap={15}>
          {customerInfo.map(({ key, label, value }) => {
            // 수정: 중복 기능 구현 필요
            let content = _.get(currentData, [value])
            if (_.isObject(content)) {
              content = content.name
            }
            return (
              <RowGapWrapper key={key}>
                <TableCategory>{label}</TableCategory>
                {/* {currentTab === 'contractorInfo'
                  ? (
                    <>
                      {key === 'opponent' && ( // 상대방
                        <StyledTag color='red' onClick={() => handleModal('duplication')}>
                          중복
                        </StyledTag>)}
                      <Input
                        defaultValue={data}
                        placeholder='내용을 입력해주세요.'
                        min={0}
                        style={{
                          width: 160
                        }}
                        // onChange={(value) => handleFieldChange(key, 'receivedAmount', value)}
                      />
                    </>)
                  : (
                    <div>{data}</div>)} */}
                <div>{content}</div>
              </RowGapWrapper>
            )
          })}
        </ColumnGapWrapper>
        {/* 계약자 정보 수정 모달 */}
        <StyledModal
          width={1700}
          height={800}
          open={isModalOpenContractorInfo}
          onOk={handleOk}
          onCancel={handleCancel}
          okText='저장'
          cancelText='취소'
        >
          <h4>계약자 정보</h4>
          <ColumnGapWrapper $gap={15}>
            <Table
              pagination={false}
              columns={callDataSource}
              dataSource={customerInfoTable}
            />
            {isDuplicateCaseNumber &&
              <>
                <h4>중복된 사건 정보</h4>
                <Table
                  rowSelection={{
                    type: 'radio',
                    ...rowSelection
                  }}
                  pagination={false}
                  columns={duplicationTableColumns}
                  dataSource={tmpDuplicationData}
                />
              </>}
            {isDuplicateOpponent &&
              <>
                <h4>중복된 상대방 정보</h4>
                <Table
                  rowSelection={{
                    type: 'radio',
                    ...rowSelection
                  }}
                  pagination={false}
                  columns={duplicationTableColumns}
                  dataSource={tmpDuplicationData}
                />
              </>}
          </ColumnGapWrapper>
        </StyledModal>
      </>
    )
  }

  const tabItems = [
    {
      key: 'callerInfo',
      label: '콜 고객 정보',
      children: <InfoContainer tab='call' />
    },
    {
      key: 'contractorInfo',
      label: '계약자 정보',
      children: <InfoContainer tab='contract' />
    }
  ]

  const onChange = (key) => {
    setCurrentTab(key)
  }
  const navigate = useNavigate()
  const { searchParams } = useContractFilter()
  const searchString = searchParams.toString()

  const contractFormStatus = getRecordFormStatus()
  const prevCaseFormStatus = useRef(contractFormStatus)

  useEffect(() => {
    if (!cancelModalOpen && !pageDelete) {
      prevStatus.current = formStatus
    }
  }, [cancelModalOpen, pageDelete])
  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onOkDeleteConfirmModal}
        onCancel={onCloseModal}
      />
      <BlockModal
        title='수정을 취소하시겠습니까?'
        open={cancelModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <>
            <HeaderSpacer>
              <HeaderContainer>
                <PaymentHeaderTitle title={dataSource?.name} readOnly={readOnly} onOpenModal={onOpenModal} contractId={id} />
                <Spacer />
                {readOnly
                  ? (
                    <HeaderGroup>
                      {_.includes(permissions, 'UPDATE_PROJECT_DATA') && ( // 수정: permissions
                        <>
                          {/* {contractStateTag(contractState)} 임시 수석 */}
                          <Button
                            type='primary'
                            value='small'
                            icon={<EditOutlined />}
                            style={{ display: 'flex', alignItems: 'center' }}
                            onClick={() => onEdit()}
                          >
                            수정하기
                          </Button>
                        </>
                      )}
                    </HeaderGroup>)
                  : (
                    <HeaderGroup>
                      {_.includes(permissions, 'UPDATE_PROJECT_DATA') && ( // 수정: permissions
                        <>
                          {/* 수정 필요: 종결 */}
                          {/* <Button onClick={() => handleClose()}>
                            {closeContract
                              ? '종결 취소'
                              : '종결 하기'}
                          </Button> */}
                          <Button onClick={onCancel}>
                            취소하기
                          </Button>
                          <Button type='primary' onClick={() => handleSave()}>
                            저장하기
                          </Button>
                        </>)}
                    </HeaderGroup>)}
              </HeaderContainer>
            </HeaderSpacer>
            {/* 수정 필요: 필터 임시 주석 */}
            {/* {
              readOnly && (
                <ContractRecordDetailFilter
                  meta={meta}
                  filters={filters}
                  onChangeFilter={onChangeFilter}
                  onResetFilterAndGroup={onResetFilterAndGroup}
                  border='true'
                />
              )
            } */}
          </>
        )}
        content={
          <Form form={form} initialValues={{ contract: contractDataSource, extraContract: extraContractDataSource, reasonList: reasonDataSource, depositRecordList: depositDataSource }}>
            <ContentContainer>
              <ColumnGapWrapper $gap={10}>
                {/* <CallRecordFilter meta={meta} filters={filters} /> */}
                <RowGapWrapper $gap={10}>
                  <ColumnGapWrapper $gap={10} style={{ minWidth: '300px' }}>
                    <ContractTotalRecordCard contractTotal={contractTotal} receivedAmount={receivedAmount} />
                    <InfoCard title='사건 정보' data={contractRecordData} />
                    <NarrowCustomCard stype={{ position: 'relative' }}>
                      {(readOnly &&
                        currentTab === 'contractorInfo') && (
                          <StyledEditButton
                            onClick={() => handleModal('contractor')}
                          >
                            <EditOutlined />
                          </StyledEditButton>)}
                      <CustomTabs defaultActiveKey='0' items={tabItems} onChange={onChange} />
                    </NarrowCustomCard>
                  </ColumnGapWrapper>
                  <ColumnGapWrapper $gap={10} style={{ minWidth: '1320px' }}>
                    {/* <Button onClick={() => { console.log('contract', form.getFieldValue()) }}>DEBUG!</Button> */}
                    <GrayCustomCard $readOnly={readOnly}>
                      <ContractContainer
                        contractType='contract'
                        title='계약 금액'
                        contractData={contractDataSource}
                        setContractData={setContractDataSource}
                        options={paymentCategoryOptions}
                        readOnly={readOnly}
                        depositDataSource={depositDataSource}
                        setDepositDataSource={setDepositDataSource}
                        currentUser={currentUser}
                        form={form}
                        // loading={loading}
                        newContractIds={newContractIds}
                        setNewContractIds={setNewContractIds}
                        deletedContractIds={deletedContractIds}
                        setDeletedContractIds={setDeletedContractIds}
                      />
                    </GrayCustomCard>
                    <GrayCustomCard $readOnly={readOnly}>
                      <ContractContainer
                        contractType='extraContract'
                        title='추가 계약 조건(성공보수금 포함)'
                        contractData={extraContractDataSource}
                        setContractData={setExtraContractDataSource}
                        options={extraPaymentCategoryOptions}
                        readOnly={readOnly}
                        depositDataSource={depositDataSource}
                        setDepositDataSource={setDepositDataSource}
                        currentUser={currentUser}
                        form={form}
                        newContractIds={newContractIds}
                        setNewContractIds={setNewContractIds}
                        deletedContractIds={deletedContractIds}
                        setDeletedContractIds={setDeletedContractIds}
                      />
                    </GrayCustomCard>
                    {/* 추심 목록, 입금내역 */}
                    {formItems.map(({ key, cardTitle, value, scrollHeight, columns, component, dataIndex }) => {
                      return (
                        <GrayCustomCard key={key} $readOnly={readOnly}>
                          <CardHeader type='button' dataIndex={dataIndex} title={cardTitle} value={value} readOnly={readOnly} handleAdd={handleAdd} />
                          <StyledTable
                            key={key}
                            columns={columns}
                            dataSource={key === 'reasonList' ? reasonDataSource : depositDataSource}
                            pagination={false}
                            scroll={{
                              y: scrollHeight
                            }}
                          />
                        </GrayCustomCard>
                      )
                    })}
                  </ColumnGapWrapper>
                </RowGapWrapper>
              </ColumnGapWrapper>
            </ContentContainer>
          </Form>
        }
      />
    </>
  )
}
