import { RowGapWrapper } from '../../../common/Theme/style'
import MultipleSelect from '../../../timeCharge/components/common/MultipleSelect'
import { DateRangeFilter } from '../../../timeCharge/components/filters/DateRangeFilter'

import { Button, InputNumber } from 'antd'
import _ from 'lodash'

// 계약 기록 > 회수/미수금액 필터
const ContractRecordFilter = ({ meta, filters, onChangeMeta = () => {}, onResetFilterAndGroup = () => {} }) => {
  return (
    <div>
      <RowGapWrapper style={{ alignItems: 'center' }}>
        <DateRangeFilter
          value={meta.filter.datetimeRange}
          onCalendarChange={v => onChangeMeta('filter', 'datetimeRange', v)}
        />
        {_.filter(filters, ({ key }) => key !== '본사가 아닐 경우').map(({ key, selectedItems, options, value, onChangeFilter, defaultValue }) => {
          return (
            (key !== 'price')
              ? (
                <MultipleSelect
                  key={key}
                  style={{ minWidth: 100 }}
                  filters={filters}
                  selectedItems={selectedItems}
                  options={options}
                  placeholder={value}
                  onSelectChange={v => onChangeFilter(v)}
                />)
              : (
                <InputNumber
                  key={key}
                  changeOnWheel
                  placeholder='0'
                  min={0}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  addonAfter='원'
                  parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                  style={{ minWidth: 120 }}
                />)
          )
        })}
        <Button onClick={onResetFilterAndGroup}>필터 초기화</Button>
      </RowGapWrapper>
    </div>
  )
}

export default ContractRecordFilter
