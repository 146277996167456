import _ from 'lodash'
import { getContractRecord } from '../../apollo/callRecordQueries'
import { useQuery } from '@apollo/client'

export const useContractRecord = (getContractRecordId) => {
  const { data, loading, refetch } = useQuery(getContractRecord, {
    variables: {
      getContractRecordId
    },
    fetchPolicy: 'network-only'
  })
  const gqlData = _.get(data, ['GetContractRecord'])
  return {
    loading,
    contractRecordData: gqlData,
    totalCount: _.get(data, ['GetContractRecordList', 'totalCount'], 0),
    refetch
  }
}
