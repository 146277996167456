import { useState } from 'react'
import { depositType } from '../../../config'
import { usePaymentDetailFilterSearch } from './usePaymentDetailFilterSearch'
import _ from 'lodash'

// 계약 디테일 페이지 필터
export const useContractFilter = () => {
  const [openStatusByKey, setOpenStatusByKey] = useState({}) // 필터가 열려 렌더링 발생할때만 쿼리 호출할 수 있도록 스테이트 관리
  const {
    meta,
    searchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup
  } = usePaymentDetailFilterSearch()

  const convertToOptions = ({ id, name, ...v }) => {
    return {
      label: name,
      value: id,
      key: id
    }
  }

  const filters = [
    {
      key: 'contractState',
      value: '입금상태',
      options: depositType
    }
  ].map(v => ({
    ...v,
    selectedItems: _.get(meta, ['filter', v.key], []),
    onChangeFilter: (value) => {
      onChangeMeta('filter', v.key, value)
    },
    onOpenChange: open => setOpenStatusByKey(openStatusByKey => ({ ...openStatusByKey, [v.key]: open }))
  }))

  return {
    meta,
    filters,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    openStatusByKey
  }
}
