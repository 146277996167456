import styled from 'styled-components'
import { Button, Statistic } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const CardHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const TotalMoneyContainer = styled(Statistic)`
  .ant-statistic-content-value-int {
    font-size: 16px;
    font-weight: 600;
  }
`

export const CardHeader = ({ type, title, value, readOnly, dataIndex, handleAdd }) => {
  return (
    <CardHeaderContainer>
      <CardTitle>{title}</CardTitle>
      {type !== 'button'
        ? (
          <TotalMoneyContainer value={value} />)
        : (
            readOnly
              ? (null)
              : (<Button type='primary' ghost onClick={() => handleAdd(dataIndex)}><PlusOutlined />{value}</Button>)
          )}
    </CardHeaderContainer>
  )
}
