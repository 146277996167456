import { useMutation } from '@apollo/client'
import { addTimesheetTitleTemplate, editTimesheetTitleTemplate, removeTimesheetTitleTemplate } from '../../apollo/mutations'
import _ from 'lodash'
import { useOutletContext } from 'react-router-dom'
import { MESSAGES } from '../../../common/config'

export const useTimesheetTitleTemplateAdder = () => {
  const { messageApi } = useOutletContext()
  const [handleAddTimesheetTitleTemplate] = useMutation(addTimesheetTitleTemplate)
  const [handleEditTimesheetTitleTemplate] = useMutation(editTimesheetTitleTemplate)
  const [handleRemoveTimesheetTitleTemplate] = useMutation(removeTimesheetTitleTemplate)
  const handleError = () => {
    messageApi.open(MESSAGES.timesheetTitleTemplateError)
  }
  const onAddTimesheetTitleTemplate = (name, order) => {
    handleAddTimesheetTitleTemplate({
      variables: {
        type: {
          name,
          order
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'AddTimesheetNameTemplate', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  const onEditTimesheetTitleTemplate = (editTimesheetNameTemplateId, name, order) => {
    handleEditTimesheetTitleTemplate({
      variables: {
        editTimesheetNameTemplateId,
        input: {
          name,
          order
        }
      }
    }).then(v => {
      if (!_.get(v, ['data', 'EditTimesheetNameTemplate', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  const onRemoveTimesheetTitleTemplate = (removeTimesheetNameTemplateId) => {
    handleRemoveTimesheetTitleTemplate({
      variables: {
        removeTimesheetNameTemplateId
      }
    }).then(v => {
      if (!_.get(v, ['data', 'RemoveTimesheetNameTemplate', 'ok'])) {
        handleError()
      }
    }).catch(e => {
      handleError()
    })
  }

  return {
    onAddTimesheetTitleTemplate,
    onEditTimesheetTitleTemplate,
    onRemoveTimesheetTitleTemplate
  }
}
