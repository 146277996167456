import _ from 'lodash'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BlockModal } from '../../../timeCharge/components/common/BlockModal'
import { useBlockModal } from '../../../timeCharge/hooks/common/useBlockModal'
import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { useDepartmentList } from '../../../timeCharge/hooks/apollo/useDepartmentList'
import { HeaderContainer, HeaderTitle } from '../../../common/components/PageHeaderStyles'
import EmployeeDepartmentForm from '../../../timeCharge/components/form/EmployeeDepartmentForm'
import { useEditingUserDepartmentForm } from '../../../timeCharge/hooks/settings/users/useEditingUserDepartmentForm'
import { getUserDepartmentFormStatus, getUserDepartmentMeta } from '../../../timeCharge/cacheAndNavigation/setting/cache'
import { parseMetaToSelectOptions } from '../../../call/components/common/CommonFunction'

const SettingEmployeeDepartmentContainer = () => {
  const {
    departments
  } = useDepartmentList()
  const navigate = useNavigate()
  const { userId } = useParams()
  const userDepartmentFormStatus = getUserDepartmentFormStatus()
  const prevUserDepartmentFormStatus = useRef(userDepartmentFormStatus)
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()
  useEffect(() => {
    if (!blockModalOpen) prevUserDepartmentFormStatus.current = userDepartmentFormStatus
  }, [blockModalOpen])
  const initMeta = getUserDepartmentMeta()
  // 유저 배당 변경
  const userDepartments = _.get(initMeta, ['departments2'])
  const initDepartmentIds = _.map(userDepartments, 'id')
  const { onEdit } = useEditingUserDepartmentForm(onFetchAndNav, userId, initDepartmentIds)

  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='부서 수정' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <EmployeeDepartmentForm
              initialValues={{
                departmentIds: _.get(initDepartmentIds, [0]) // 부서 여러개 보류
              }}
              departments={_.map(departments, parseMetaToSelectOptions)}
              onSave={onEdit}
              editing
              onCancel={() => navigate(-1)}
            />
          </div>
        )}
      />
      <BlockModal
        title='부서 수정을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default SettingEmployeeDepartmentContainer
