import { useMutation } from '@apollo/client'
import { removeContract } from '../../apollo/mutations' // 수정필요 restoreContract
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { MESSAGES } from '../../../common/config'
import {useOutletContext } from 'react-router-dom'

// 수정 필요: 복구 mustaion 완성시
export const useContractRecordRestore = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [restoreContractRecordMutFn] = useMutation(removeContract) // 수정필요 restoreContract

  const onRestoreContractRecord = (id) => {
    restoreContractRecordMutFn({
      variables: {
        restoreContractId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'RestoreContract', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      messageApi.open(MESSAGES.restoreContractError)
      handleError()
    })
  }
  return {
    onRestoreContractRecord
  }
}
