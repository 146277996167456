import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { parseDateObj } from '../../../../common/util'
import _ from 'lodash'

export const getUpdatedSearchParams = (type, key, value, sp) => {
  if (_.isArray(value)) {
    sp.delete(`${type}_${key}`)
    value.forEach(v => {
      if (type === 'filter' && key === 'datetimeRange') {
        sp.append(`${type}_${key}`, parseDateObj(v, 'YYYY-MM-DD HH:MM'))
      } else {
        sp.append(`${type}_${key}`, v)
      }
    })
  } else {
    sp.set(`${type}_${key}`, value)
  }
  return sp
}

const defSearchParams = {
  filter_recordType: [], // 전체, 온라인, 오프라인, 방문&선임
  filteR_caseNumber: [], // 사건번호
  filter_datetimeRange: [],
  filter_departments: [], // 본사 부서
  filter_branch: [], // 지사
  filter_userIds: [],
  filter_contractState: [], // 계약상태
  filter_contractTotal: [], // 계약 총 금액
  filter_employee: [] // 담당변호사
}

export const useRecordFilterSearchParams = (initParams = defSearchParams) => {
  const [searchParams, setSearchParams] = useSearchParams(initParams)
  const [time, setTime] = useState('daily')
  const onChangeMeta = (type, key, value) => {
    if (key === 'time') {
      setTime(value)
    }
    const newSearchParams = getUpdatedSearchParams(type, key, value, searchParams)
    setSearchParams(newSearchParams)
  }
  const onRemoveItemFromFilter = (key, value) => {
    onChangeMeta('filter', value, _.filter(meta.filter[key], v => v !== value))
  }
  const onResetFilterAndGroup = () => {
    setSearchParams(defSearchParams)
  }

  const meta = {
    group: {
      time
    },
    filter: {
      datetimeRange: [],
      recordType: [],
      caseNumber: [],
      departments: [],
      userIds: [],
      contractState: [],
      contractTotal: [],
      employee: []
    }
  }
  const a = ['group', 'filter']
  const b = ['time']
  const c = ['recordType', 'datetimeRange', 'departments', 'userIds', 'contractState', 'contractTotal']
  a.forEach(v1 => {
    if (v1 === 'group') {
      b.forEach(v2 => {
        meta[v1][v2] = searchParams.get(`${v1}_${v2}`)
      })
    } else {
      c.forEach(v2 => {
        meta[v1][v2] = [...searchParams.getAll(`${v1}_${v2}`)]
      })
    }
  })

  return {
    meta,
    searchParams,
    setSearchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    onRemoveItemFromFilter
  }
}
