import { useMutation } from '@apollo/client'
import { addContractRecord, editContractRecord, removeContractRecord } from '../../apollo/mutations'
import _ from 'lodash'
import { handleError } from '@apollo/client/link/http/parseAndCheckHttpResponse'
import { navigateToContractRecordDetail } from '../../cacheAndNavigation/contractRecordDetail/navigation'
import { MESSAGES } from '../../../common/config'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { checkDateTime } from '../../components/common/CommonFunction'
import { navigateToContractRecord } from '../../cacheAndNavigation/paymentRecord/navigation'

export const useContractRecordAddEditRemove = (refetch = () => {}) => {
  const { messageApi } = useOutletContext()
  const [addContractRecordMutFn] = useMutation(addContractRecord)
  const [editContractRecordMutFn] = useMutation(editContractRecord)
  const [removeContractRecordMutFn] = useMutation(removeContractRecord)
  const navigate = useNavigate()

  const parseContractRecord = (data) => {
    return {
      callRecordId: _.get(data, 'id'),
      projectId: _.get(data, 'projectType.id'),
      caseNumber: _.get(data, 'caseNumber'),
      caseName: _.get(data, 'expectedCaseName'),
      projectTypeId: _.get(data, 'projectType.id'),
      departmentId: _.get(data, 'department.id'),
      assignedLawyerIds: _.map(data.counselLawyers, 'id'),
      name: _.get(data, 'name'),
      representer: _.get(data, 'primaryAuthor.name'),
      phone: _.get(data, 'phone'),
      address: _.get(data, 'address'),
      // email: _.get(data, 'email'),
      opponent: _.get(data, 'opponent'),
      jurisdiction: _.get(data, 'jurisdiction')
    }
  }

  const returnIdOrNull = (value) => {
    if (_.isString(value)) { // 부서 입력시 key만 넘겨줘서 string
      return value
    } else if (value?.id !== null) { // 입력되어있는 부서는 객체라서 id만 가져온다
      return value?.id
    } else {
      return null
    }
  }

  const reformatToIdArray = (value) => {
    const isArrayOfObjects = _.every(value, item => _.isObject(item) && 'id' in item && 'name' in item && '__typename' in item)
    if (isArrayOfObjects) {
      return _.map(value, 'id')
    } else {
      return value
    }
  }
  const parseContractRecords = (name) => {
    return {
      type: name.type || null,
      callCount: name.callCount || null,
      name: name.name || null,
      gender: name.gender || null,
      age: name.age || null,
      phone: name.phone || null,
      email: name.email || null,
      address: name.address || null,
      primaryAuthorId: returnIdOrNull(name.primaryAuthor),
      secondaryAuthorId: returnIdOrNull(name.secondaryAuthor),
      inputDatetime: checkDateTime(name.inputDate, name.inputTime) || null,
      projectTypeId: returnIdOrNull(name.projectType),
      projectSubTypeId: returnIdOrNull(name.projectSubType),
      departmentId: returnIdOrNull(name.department) || null,
      via: name.via || null,
      expectedCaseName: name.expectedCaseName || null,
      managerId: returnIdOrNull(name.manager) || null,
      amount: name.amount || null,
      jurisdiction: name.jurisdiction || null,
      caseNumber: name.caseNumber || null,
      content: name.content || null,
      callType: name.callType || null,
      visitReserved: checkDateTime(name.visitReservedDate, name.visitReservedTime) || null,
      reservationCancelReason: name.reservationCancelReason || null,
      visited: checkDateTime(name.visitedDate, name.visitedTime) || null,
      visitor: name.visitor || null,
      visitDepartmentId: returnIdOrNull(name.visitDepartment) || null,
      counselLawyers: reformatToIdArray(name.counselLawyers) || null,
      counselEmployees: reformatToIdArray(name.counselEmployees) || null,
      nVisit: name.nVisit || null,
      nRevisit: name.nRevisit || null,
      contractor: name.contractor || null,
      assigned: name.assigned || null,
      nAssign: name.nAssign || null,
      cancelled: name.cancelled || null,
      cancelReason: name.cancelled || null
    }
  }

  const onCreateContractRecord = (data) => {
    const transformedData = parseContractRecord(data)
    addContractRecordMutFn({
      variables: {
        input: transformedData
      }
    }).then(v => {
      const newRecordId = _.get(v, ['data', 'AddContractRecord', 'data'])
      navigateToContractRecordDetail(navigate, newRecordId, null, 'edit')
    }).catch(e => {
      messageApi.open(MESSAGES.restoreProjectsError)
      handleError()
    })
  }

  const onEditContractRecord = (id, input) => {
    const transformedData = parseContractRecords(input)
    editContractRecordMutFn({
      variables: {
        editContractRecordId: id,
        input: transformedData
      }
    }).then(v => {
      if (_.get(v, ['data', 'EditContractRecord', 'ok'])) {
        refetch()
      }
    }).catch(e => {
      console.log(e)
      handleError()
    })
  }

  const onRemoveContractRecord = (id) => {
    removeContractRecordMutFn({
      variables: {
        removeContractRecordId: id
      }
    }).then(v => {
      if (_.get(v, ['data', 'RemoveContractRecord', 'ok'])) {
        navigateToContractRecord(navigate, null)
      }
    }).catch(e => {
      console.log(e)
      handleError()
    })
  }

  return {
    onCreateContractRecord,
    onEditContractRecord,
    onRemoveContractRecord
  }
}
