import { Form, Input } from 'antd'
import { useState } from 'react'

// 계약 기록 > 콜 고객 정보
export const useContractorInfoColumns = () => {
  const contractDuplicationColumns = [
    {
      title: 'key',
      datadataIndex: 'key'
    },
    {
      title: 'ID',
      datadataIndex: 'ID'
    },
    {
      title: 'SN',
      datadataIndex: 'SN'
    },
    {
      title: '카테고리',
      datadataIndex: 'category'
    },
    {
      title: '세부카테고리',
      datadataIndex: 'subcategory'
    }
  ]

  const [isDuplicateCaseNumber, setIsDuplicateCaseNumber] = useState(false) // 사건번호 중복
  const [isDuplicateOpponent, setIsDuplicateOpponent] = useState(false) // 상대방 중복
  const [caseNumberStatus, setCaseNumberStatus] = useState('')

  const handleChange = (key, value) => {
    // const isDuplicateCaseNumber = Object.values(formData).some(
    //   (fieldValue) => fieldValue !== value && key !== 'name'
    // )

    setCaseNumberStatus('error')
    setIsDuplicateOpponent(true)
    setIsDuplicateCaseNumber(true)
  }
  const duplicationTableColumns = [
    {
      header: '의뢰인명',
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name'
    },
    {
      header: '위임인명',
      title: '위임인명',
      dataIndex: 'delegator',
      key: 'delegator'
    },
    {
      header: '전화번호',
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      header: '주소',
      title: '주소',
      dataIndex: 'address',
      key: 'address'
    },
    {
      header: '이메일',
      title: '이메일',
      dataIndex: 'email',
      key: 'email'
    },
    {
      header: (
        <>
          주민번호/ <br />
          사업자등록번호
        </>
      ),
      title: '주민번호/사업자등록번호',
      dataIndex: 'ssn',
      key: 'ssn'
    },
    {
      header: '사건번호',
      title: '사건번호',
      dataIndex: 'caseNumber',
      key: 'caseNumber'
    },
    {
      header: '사건명',
      title: '사건명',
      dataIndex: 'caseName',
      key: 'caseName'
    },
    {
      header: '상대방',
      title: '상대방',
      dataIndex: 'opponent',
      key: 'opponent'
    },
    {
      header: '관할',
      title: '관할',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction'
    }
  ]
  const callDataSource = [
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return (
          <Input
            placeholder='의뢰인명'
            defaultValue={name || undefined}
            // onChange={(e) => handleChange('name', e.target.value)}
          />
        )
      }
    },
    {
      title: '위임인명',
      dataIndex: 'delegator',
      key: 'delegator',
      render: (delegator, record) => {
        return (
          <Input
            placeholder='위임인명'
            defaultValue={delegator || undefined}
            // onChange={(e) => handleChange('delegator', e.target.value)}
          />
        )
      }
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      render: (name, record) => {
        return (
          <Input
            placeholder='전화번호'
            defaultValue={name || undefined}
            // onChange={(e) => handleChange('phone', e.target.value)}
          />
        )
      }
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      render: (address, record) => {
        return (
          <Input
            placeholder='주소'
            defaultValue={address || undefined}
            // onChange={(e) => handleChange('address', e.target.value)}
          />
        )
      }
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      render: (email, record) => {
        return (
          <Input
            placeholder='이메일'
            defaultValue={email || undefined}
            // onChange={(e) => handleChange('email', e.target.value)}
          />
        )
      }
    },
    {
      title: '주민번호/사업자등록번호',
      dataIndex: 'ssn',
      key: 'ssn',
      render: (ssn, record) => {
        return (
          <Input
            placeholder='주민번호/사업자등록번호'
            defaultValue={ssn || undefined}
            // onChange={(e) => handleChange('ssn', e.target.value)}
          />
        )
      }
    },
    {
      title: '사건번호',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      render: (caseNumber, record, index) => {
        return (
          <Form.Item
            key={caseNumber}
            validateStatus={isDuplicateCaseNumber ? 'error' : ''}
            help={isDuplicateCaseNumber ? '중복된 사건번호가 있습니다.' : null}
          >
            <Input
              placeholder='사건번호'
              defaultValue={caseNumber || undefined}
              // onChange={(e) => handleChange('caseNumber', e.target.value)}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '사건명',
      dataIndex: 'caseName',
      key: 'caseName',
      render: (caseName, record) => {
        return (
          <Input
            placeholder='사건명'
            defaultValue={caseName || undefined}
            // onChange={(e) => handleChange('caseName', e.target.value)}
          />
        )
      }
    },
    {
      title: '상대방',
      dataIndex: 'opponent',
      key: 'opponent',
      render: (opponent, record) => {
        return (
          <Form.Item
            key={opponent}
            validateStatus={isDuplicateOpponent ? 'error' : ''}
            help={isDuplicateOpponent ? '중복된 상대방이 있습니다.' : null}
          >
            <Input
              placeholder='상대방'
              defaultValue={opponent || undefined}
              // onChange={(e) => handleChange('opponent', e.target.value)}
              status={caseNumberStatus}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '관할',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      render: (jurisdiction, record) => {
        return (
          <Input
            placeholder='관할'
            defaultValue={jurisdiction || undefined}
            // onChange={(e) => handleChange('jurisdiction', e.target.value)}
          />
        )
      }
    }
  ]

  return {
    callDataSource,
    contractDuplicationColumns,
    isDuplicateCaseNumber,
    isDuplicateOpponent,
    duplicationTableColumns
  }
}
