import _ from 'lodash'
import { MoneyContainer } from '../../../../common/Theme/style'
import { ContractStateColor } from '../../../components/customTable/ContractStateColor'

export const useContractRecordColumnsList = (contractRecordsData) => {
  const moment = require('moment')
  const contractRecordTableColumns = [
    {
      title: '계약상태',
      dataIndex: 'contractState',
      key: 'contractState',
      sort: true,
      width: 80,
      fixed: 'left',
      render: (contractState, record) => {
        const overdue = _.some(contractRecordsData, (data) => data.id === record.id)
        if (overdue === true) {
          contractState = 'OVERDUE'
        } else if (record.contractRecord !== null) {
          contractState = 'PROGRESS'
        } else if (record.nAssign > 0) {
          contractState = 'CONTRACT'
        } else {
          contractState = 'NOCONTRACT'
        }
        return (
          <ContractStateColor state={contractState} />
        )
      }
    },
    {
      title: '입금 기한',
      dataIndex: 'paymentDeadLine',
      key: 'paymentDeadLine',
      sort: true,
      width: 100,
      render: (_, record) => {
        const payements = record.paymentRecords
        let closestDueDateRecord = null
        if (payements.length > 0) {
          let minDifference = Infinity
          payements.forEach(record => {
            const difference = Math.abs(moment(record.dueDate).diff(moment(), 'days'))
            if (difference < minDifference) {
              minDifference = difference
              closestDueDateRecord = record.dueDate
            }
          })
        }
        return (
          <div>{closestDueDateRecord}</div>
        )
      }
    },
    {
      title: '총 계약 금액',
      dataIndex: 'contractTotal',
      key: 'contractTotal',
      sort: true,
      width: 110,
      render: (contractTotal, record) => {
        const payments = _.sumBy(record.paymentRecords, 'dueAmount')
        return (
          <MoneyContainer value={payments} />
        )
      }
    },
    {
      title: '추심상황 기재 수',
      dataIndex: 'commentCount',
      key: 'commentCount',
      sort: true,
      width: 110,
      render: (commentCount) => {
        if (!Number.isInteger(commentCount)) {
          commentCount = 0
        }
        return (
          commentCount
        )
      }
    },
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 100,
      render: (projectType) => {
        return (
          <div>{projectType.name}</div>
        )
      }
    },
    {
      title: '사건명',
      dataIndex: 'project',
      key: 'project',
      width: 200
    },
    {
      title: '담당변호사',
      dataIndex: 'assignedLawyers',
      key: 'assignedLawyers',
      width: 100
    },
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      width: 100
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      width: 125
    }
  ]

  return {
    contractRecordTableColumns
  }
}
