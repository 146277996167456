import _ from 'lodash'
import { getContractRecordList } from '../../apollo/callRecordQueries'
import { useQuery } from '@apollo/client'

export const useContractRecordList = (
  ascending = false,
  key = null,
  searchQuery,
  from,
  to,
  departmentId = null,
  contractState = null,
  deleted = null,
  caseNumber = null,
  projectTypeId = null,
  assignedLawyerId = null,
  groupModeContract,
  outstanding,
  outstandingFrom,
  outstandingTo
) => {
  const variables = {
    sortBy: {
      ascending,
      key
    },
    filter: {
      searchQuery,
      ...from && to
        ? {
            dateRange: {
              from,
              to
            }
          }
        : {},
      assignedLawyerId,
      departmentId,
      deleted,
      caseNumber,
      projectTypeId,
      outstanding,
      dueDateRange: {
        from: outstandingFrom,
        to: outstandingTo
      }
    },
    groupBy: groupModeContract,
    offset: 0,
    limit: 10
  }
  const { data, loading, refetch } = useQuery(getContractRecordList, {
    variables,
    fetchPolicy: 'no-cache'
  })
  const format = (groupModeContract === 'DEPARTMENT' || groupModeContract === 'TYPE') ? 'groups' : 'records'
  const gqlData = _.get(data, ['GetContractRecordList', format], [])

  return {
    loading,
    variables,
    contractRecordsData: gqlData,
    totalCount: _.get(data, ['GetContractRecordList', 'totalCount'], 0),
    totalDueAmount: _.get(data, ['GetContractRecordList', 'totalDueAmount'], 0),
    totalReceived: _.get(data, ['GetContractRecordList', 'totalReceived'], 0),
    refetch
  }
}
