import { useState } from 'react'

import { CustomSearch } from '../../common/components/CustomSearch.js'
import CallRecordFilter from '../components/filter/CallRecordFilter.js'
import { useContractRecordList } from '../hooks/apollo/useContractRecordList.js'
import { usePaymentList } from '../hooks/contractRecord/usePaymentList.js/index.js'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter.js'
import { ContractCustomTable } from '../components/customTable/ContractCustomTable.js'
import { useOverdueViewerColumns } from '../hooks/overdue/table/useOverdueViewerColumns.js'
import { CustomSearchContainer, ResponsiveGapContainer } from '../../common/Theme/style.js'
import { useCallRecordFilter } from '../hooks/filter/callRecord/useCallContractRecordFilter.js'
import { useContractRecordColumnsList } from '../hooks/contractRecord/contractRecordDetail/useContractRecordColumnsList.js'
import { usePaymentTable } from '../hooks/contractRecord/table/usePaymentTable.js'

const ContractRecordContainer = () => {
  const [searchQuery, setSearchQuery] = useState(null) // 검색창
  const {
    meta,
    filters,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    openStatusByKey,
    departments
  } = useCallRecordFilter()

  const {
    contractRecordTableColumns
  } = useContractRecordColumnsList()

  const {
    viewMode,
    groupModeAllContract,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    ...props
  } = usePaymentList()

  /*
  계약 기록 데이터
*/
  const {
    loading,
    variables,
    contractRecordsData,
    totalCount,
    totalDueAmount,
    totalReceived,
    refetch
  } = useContractRecordList(
    true, // ascending
    null, // key
    searchQuery,
    null, // from,
    null, // to,
    null, // departmentId,
    null, // contractState,
    false, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    viewMode === 'list' ? null : groupModeAllContract,
    false, // outstanding
    null, // outstandingFrom
    null // outstandingTo
  )
  const {
    onClickRow
  } = usePaymentTable()
  const {
    getOverdueViewerTableGroupColumns,
    getOverdueViewerTableGroupTypeColumns
  } = useOverdueViewerColumns()
  return (
    <>
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <ResponsiveGapContainer>
            <CustomSearchContainer>
              <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
            </CustomSearchContainer>
            <CallRecordFilter
              meta={meta}
              filters={filters}
              onChangeMeta={onChangeMeta}
              onResetFilterAndGroup={onResetFilterAndGroup}
            />
          </ResponsiveGapContainer>
        )}
        content={(
          <ContractCustomTable
            data={contractRecordsData}
            defaultColumns={contractRecordTableColumns}
            groupColumns={getOverdueViewerTableGroupColumns}
            typeGroupColumns={getOverdueViewerTableGroupTypeColumns}
            total={totalCount}
            pageType='contractRecord'
            unit='개'
            viewMode={viewMode}
            groupModeContract={groupModeAllContract}
            onClickRow={onClickRow}
            scroll={700}
            loading={loading}
            {...props}
          />
        )}
      />
    </>
  )
}

export default ContractRecordContainer
