import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StatTable } from '../components/statTable/StatTable'
import { useConfirmModal } from '../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../common/components/ConfirmModal'
import { CustomSearch } from '../../common/components/CustomSearch'
import CallRecordFilter from '../components/filter/CallRecordFilter'
import { useCallRecordList } from '../hooks/apollo/useCallRecordList'
import { useRecordTable } from '../hooks/callRecord/useCallRecordTable'
import { BlockModal } from '../../timeCharge/components/common/BlockModal'
import { useScratchPadTable } from '../hooks/callRecord/useScratchPadTable'
import { useBlockModal } from '../../timeCharge/hooks/common/useBlockModal'
import { CallCustomTable } from '../components/customTable/CallCustomTable'
import { useCallRecordRemove } from '../hooks/callRecord/useCallRecordRemove'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter'
import { navigateToCallRecord } from '../cacheAndNavigation/callRecord/navigation'
import { useContractRecordAddEditRemove } from '../hooks/contractRecord/useContractRecordAddEditRemove'
import { useRecordViewerColumns } from '../hooks/contractRecord/table/useRecordViewerColumns'
import { useCallRecordFilter } from '../hooks/filter/callRecord/useCallContractRecordFilter'
import { ScratchPadCustomTable } from '../components/scratchPadCustomTable/ScratchPadCustomTable'
import { useDefaultDateRangeFilter } from '../../timeCharge/hooks/filter/useDefaultDateRangeFilter'
import { navigateToContractRecordDetail } from '../cacheAndNavigation/contractRecordDetail/navigation'
import { ColumnGapWrapper, CustomSearchContainer, ResponsiveGapContainer } from '../../common/Theme/style'

import { Form } from 'antd'
import styled from 'styled-components'
import _ from 'lodash'
import { yesterdayDate } from '../components/common/CommonFunction'
import { useContractRecordList } from '../hooks/apollo/useContractRecordList'
const Container = styled.div`
  width: 100%;
`
const CallRecordContainer = () => {
  const [searchQuery, setSearchQuery] = useState(null) // 의뢰인/전화번호 검색어
  const [isEditing, setIsEditing] = useState(false)
  const [dataSource, setDataSource] = useState([]) // 콜 기록 데이터
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const localStorageScratchPadData = localStorage.getItem('scratchPad')
  const initialData = localStorageScratchPadData ? JSON.parse(localStorageScratchPadData) : []
  const [scratchPadData, setScratchPadData] = useState(initialData)
  const [statViewer, setStatViewer] = useState(false)
  const [form] = Form.useForm()
  const [scratchPadList, setScratchPadList] = useState(form.getFieldValue('scratchPad'))
  const [currentForm, setCurrentForm] = useState(form.getFieldValue('call'))
  const [placement, setPlacement] = useState('ALL') // 목록 선택 사항: 전체, 온라인, 오프라인, 방문&선임

  const navigate = useNavigate()

  const {
    loading,
    meta,
    filters,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    onSearch,
    openStatusByKey,
    departments
  } = useCallRecordFilter()

  const {
    viewMode,
    groupMode,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    recordStatus,
    ...props
  } = useRecordTable()
  /*
    콜 기록 데이터
  */
  const {
    loading: callRecordDataLoading,
    variables,
    callRecordsData,
    totalCount,
    refetch
  } = useCallRecordList(
    false, // ascending
    null, // key
    searchQuery,
    null, // recordType
    meta.filter.datetimeRange[0],
    meta.filter.datetimeRange[1],
    meta.filter.userIds,
    meta.filter.departments,
    meta.filter.contractState,
    null, // deleted,
    viewMode === 'list' ? null : groupMode // groupBy
  )

  // 전체/온라인/오프라인/방문&선임에 따라 data 필터
  const placementChange = (value) => {
    const newPlacement = value
    setPlacement(newPlacement)
  }

  useEffect(() => {
    let filter = []
    if (placement === 'ALL') {
      filter = callRecordsData
    } else {
      filter = _.filter(callRecordsData, ['type', placement])
    }
    const currentFormData = form.getFieldValue('call')
    const updatedCallRecordsData = _.map(filter, (record) => {
      const editingRecord = _.find(editingKeys, (key) => key === record.id)
      return editingRecord ? currentFormData.find(data => data?.id === record?.id) : record
    })
    // Form initial value
    const initialValues = {
      call: updatedCallRecordsData,
      scratchPad: scratchPadData
    }
    // Form에 initial value set
    form.setFieldsValue(initialValues)
  }, [callRecordsData])

  // 콜 기록 DataSource에 저장
  useEffect(() => {
    if (!callRecordDataLoading) {
      if (callRecordsData == null) {
        setDataSource([])
      } else {
        setDataSource(callRecordsData)
        setCurrentForm(callRecordsData)
      }
    }
  }, [callRecordDataLoading])

  // 필터: '이번달' 기본 세팅
  useDefaultDateRangeFilter(meta, 'month', (defDateRange) => {
    const newSearchString = getUpdatedSearchParams('filter', 'datetimeRange', defDateRange, searchParams)
    navigateToCallRecord(navigate, recordStatus, newSearchString)
  })

  // 계약 기록 페이지 여부에 따라 계약 페이지 생성 또는 이동
  const handleNavigateContractState = (record) => {
    const contractId = _.get(record, ['contractRecord', 'id'])
    if (contractId === null || contractId === undefined) {
      onOpenModal(record, 'createContractRecord')
    } else {
      navigateToContractRecordDetail(navigate, _.get(record, ['contractRecord']), null, 'view')
    }
  }
  /*
    계약 기록 데이터
  */

  const {
    loading: overdueLoading,
    contractRecordsData
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    null, // to,
    null, // departmentId,
    'OVERDUE', // contractState,
    null, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    null, // groupby,
    true, // outstanding
    null, // outstandingFrom
    yesterdayDate // outstandingTo
  )

  const {
    getRecordViewerTableColumns, // 콜 기록
    getRecordViewerTableGroupColumns, // 콜 기록 그룹 항목
    getRecordViewerTableTypeGroupColumns, // 콜 기록 Type 그룹 항목
    editingKeys,
    saveAll,
    editAll
  } = useRecordViewerColumns(
    selectedRowKeys,
    setDataSource,
    form,
    refetch,
    callRecordsData,
    handleNavigateContractState,
    currentForm,
    setCurrentForm,
    contractRecordsData
  )


  const {
    fetching,
    onFetchAndNav,
    blockModalOpen,
    onOkCancelModal,
    onCloseCancelModal
  } = useBlockModal(null, editingKeys.length > 0)

  const {
    onRemoveCallRecord,
  } = useCallRecordRemove(refetch)

  const {
    onCreateContractRecord
  } = useContractRecordAddEditRemove()
  const {
    title: confirmModalTitle,
    confirmModalMeta,
    onOpenModal,
    onCloseModal,
    onConfirmModal
  } = useConfirmModal(
    onRemoveCallRecord,
    null,
    null,
    null,
    null,
    null,
    onCreateContractRecord
  )

  const handleRemoveCallRecord = () => {
    onOpenModal(selectedRowKeys, 'deleteCallRecord')
  }
  const {
    disabledScratchPadTableActionByKey,
    ...scratchPadProps
  } = useScratchPadTable()

  const handleStatViewer = () => {
    setStatViewer(!statViewer)
  }

  if (callRecordDataLoading) {
    return <Container />
  }


  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
        // status={'warning'}
      />
      <BlockModal
        open={blockModalOpen}
        title='수정을 취소하시겠습니까?'
        subTitle='변경사항이 저장되지 않습니다.'
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <ResponsiveGapContainer>
            <CustomSearchContainer>
              <CustomSearch onSearch={(v) => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
            </CustomSearchContainer>
            <CallRecordFilter
              loading={loading}
              meta={meta}
              filters={filters}
              onChangeMeta={onChangeMeta}
              onResetFilterAndGroup={onResetFilterAndGroup}
            />
          </ResponsiveGapContainer>
        )}
        content={(
          <ColumnGapWrapper $gap={10}>
            <CallCustomTable
              data={callRecordsData}
              defaultColumns={getRecordViewerTableColumns}
              groupColumns={getRecordViewerTableGroupColumns}
              typeGroupColumns={getRecordViewerTableTypeGroupColumns}
              total={totalCount}
              pageType='call'
              unit='개'
              setIsEditing={setIsEditing}
              disabledTableActionByKey={disabledTableActionByKey}
              hiddenTableActionByKey={hiddenTableActionByKey}
              viewMode={viewMode}
              groupMode={groupMode}
              recordStatus={recordStatus} // 페이지가 즐겨찾기 or 콜 기록
              editingKeys={editingKeys}
              {...props}
              scroll={400}
              saveAll={saveAll}
              editAll={editAll}
              selectedRowKeys={selectedRowKeys}
              setSelectedRowKeys={setSelectedRowKeys}
              handleStatViewer={handleStatViewer}
              setScratchPadData={setScratchPadData}
              scratchPadList={scratchPadList}
              setScratchPadList={setScratchPadList}
              form={form}
              handleRemoveCallRecord={handleRemoveCallRecord}
              handleNavigateContractState={handleNavigateContractState}
              placementChange={placementChange}
              placement={placement}
            />
            {statViewer && <StatTable meta={meta} onChangeMeta={onChangeMeta} handleStatViewer={handleStatViewer} />}
            <ScratchPadCustomTable
              scratchPadData={scratchPadData}
              setScratchPadData={setScratchPadData}
              disabledTableActionByKey={disabledScratchPadTableActionByKey}
              scratchPadList={scratchPadList}
              setScratchPadList={setScratchPadList}
              selectedRowKeys={selectedRowKeys}
              refetch={refetch}
              form={form}
              {...scratchPadProps}
            />
          </ColumnGapWrapper>
        )}
      />
    </>
  )
}

export default CallRecordContainer
