import _ from 'lodash'
import { graphTag } from '../../../common/config'

export const useGraphMeta = (meta, timeGroups) => {
  const getUniqBarChartLabel = (v) => {
    // const label = _.get(graphTag, _.get(v, ['secondaryKey', 'category'])) || _.get(v, ['secondaryKey', 'name'])
    // // if (_.get(meta.group.item === 'creator' ? usersByName : casesByCaseNumber, [label], []).length > 1) {
    //   // return label + `(${_.get(v, ['secondaryKey', meta.group.item === 'creator' ? 'email' : 'id'])})`
    // // }
    // return label
  }

  const barGraphProps = {
    keys: [
      '사건1',
      '사건2'
    ],
    indexBy: 'country',
    margin: { top: 50, right: 130, bottom: 50, left: 60 },
    padding: 0.15,
    innerPadding: 2,
    groupMode: 'grouped',
    valueScale: { type: 'linear' },
    indexScale: { type: 'band', round: false },
    colors: { scheme: 'category10' },
    // defs: [
    //   {
    //     id: 'dots',
    //     type: 'patternDots',
    //     background: 'inherit',
    //     color: '#38bcb2',
    //     size: 4,
    //     padding: 1,
    //     stagger: true
    //   },
    //   {
    //     id: 'lines',
    //     type: 'patternLines',
    //     background: 'inherit',
    //     color: '#eed312',
    //     rotation: -45,
    //     lineWidth: 6,
    //     spacing: 10
    //   }],
    fill: [
      {
        match: {
          id: 'fries'
        },
        id: 'dots'
      },
      {
        match: {
          id: 'sandwich'
        },
        id: 'lines'
      }],
    borderColor: {
      from: 'color',
      modifiers: [
        [
          'opacity',
          '1.2'
        ]
      ]
    },
    axisTop: null,
    axisRight: null,
    // axisBottom: {
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'country',
    //   legendPosition: 'middle',
    //   legendOffset: 32,
    //   truncateTickAt: 0
    // },
    // axisLeft: {
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   legend: 'food',
    //   legendPosition: 'middle',
    //   legendOffset: -40,
    //   truncateTickAt: 0
    // },
    labelSkipWidth: 16,
    labelSkipHeight: 12,
    labelTextColor: {
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6]]
    },
    labelPosition: 'end',
    labelOffset: 9,
    legends: [
      {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }],
    role: 'application',
    ariaLabel: 'Nivo bar chart demo',
    barAriaLabel: e => e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue
  }
  return {
    barGraphProps,
    graphType: 'bar',
    graphProps: barGraphProps,
    graphTitle: '작성자 별 타임시트 기록 총 시간',
    dataType: 'ratioOfTimesheetsPerCreators'
  }
}
