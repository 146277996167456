import { AppstoreOutlined, TeamOutlined } from '@ant-design/icons'
import { Segmented } from 'antd'
import _ from 'lodash'

const groupModeOptions = [
  {
    value: 'NAME',
    label: <TeamOutlined />
  },
  {
    value: 'TYPE',
    label: <AppstoreOutlined />
  }
]

export const GroupMode = ({ groupMode, onChangeGroupMode, hiddenKeys = [] }) => {
  console.log('🚀 ~ GroupMode ~ groupMode:', groupMode)
  return (
    <Segmented
      value={groupMode}
      options={_.filter(groupModeOptions, ({ value }) => !_.includes(hiddenKeys, value))}
      onChange={e => onChangeGroupMode(e)}
    />
  )
}
