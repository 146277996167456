import React, { useRef, useState } from 'react'

import styled from 'styled-components'
import { MenuOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import { Table } from 'antd'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import COLORS from '../../../common/Theme/colors'
import _ from 'lodash'
import { useDepartmentColumnsOfSetting } from '../../hooks/settings/useDepartmentColumnsOfSetting'

const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  border-top: 1px solid ${COLORS.primaryColor50};
`
const ItemCountWrapper = styled.div`
  display: flex;
  gap: 5px;
`
const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  background-color: aliceblue;
`
const BlueText = styled.div`
  color: ${COLORS.primaryColor};
  font-weight: bold;
`
const TableComponent = styled(Table)`
  & colpsan > col:nth-child(3),
  & thead > tr > th:nth-child(3),
  & tbody > tr > td:nth-child(3) {
    min-width: 100px;
    max-width: 500px;
    width: 100%;
  }
`

const DepartmentTypeAdder = ({ initDataSource = [], onUpdateDepartment }) => {
  const [dataSource, setDataSource] = useState(initDataSource)
  const isEditing = (record) => record.key === ''
  const textRef = useRef()
  const {
    departmentTableColumns
  } = useDepartmentColumnsOfSetting((removeId) => {
    setDataSource(dataSource => _.filter(dataSource, v => v.id !== removeId))
  })
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id)
        const overIndex = previous.findIndex((i) => i.key === over?.id)
        return _.map(arrayMove(previous, activeIndex, overIndex), (record, index) => {
          const order = _.get(record, ['order'])
          const name = _.get(record, ['name'])
          const key = _.get(record, ['key'])
          const leaders = _.map(_.get(record, ['leaders'], []), 'id')
          if (index !== order) onUpdateDepartment(key, leaders, name, index)
          return {
            ...record,
            order: index
          }
        })
      })
    }
  }

  const Row = ({
    row,
    editing,
    dataIndex,
    title,
    inputtype,
    record,
    index,
    currentRowValues,
    handleInputChange,
    children,
    ...props
  }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging
    } = useSortable({
      id: props['data-row-key']
    })
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1
        }
      ),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999
          }
        : {})
    }
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === 'sort') {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move'
                  }}
                  {...listeners}
                />
              )
            })
          }
          return child
        })}
      </tr>
    )
  }
  const mergedColumns = departmentTableColumns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ref: isEditing(record) ? textRef : null
      })
    }
  })

  return (
    <Container>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <RowContainer>
            <ItemCountWrapper>
              총
              <BlueText>{dataSource.length}</BlueText>
              개
            </ItemCountWrapper>
          </RowContainer>
          <TableComponent
            rowKey='key'
            columns={mergedColumns}
            dataSource={dataSource}
            components={{
              body: {
                row: Row
              }
            }}
            pagination={false}
          />
        </SortableContext>
      </DndContext>
    </Container>
  )
}

export default DepartmentTypeAdder
