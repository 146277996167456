import { ColumnGapWrapper, NarrowCustomCard, RowGapWrapper } from '../../../common/Theme/style'
import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import _ from 'lodash'
import { caseDetailCategory } from '../../config'

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
`
const TableCategory = styled.div`
  width: 90px;
  color: ${COLORS.black_65};
`

export const InfoCard = ({ title, data }) => {
  return (
    <NarrowCustomCard>
      <CardTitle>{title}</CardTitle>
      <ColumnGapWrapper $gap={15}>
        {caseDetailCategory.map(({ key, value, label }) => {
          let content = _.get(data, [value])
          if (_.isObject(content)) {
            content = content.name
          }
          return (
            <RowGapWrapper key={key} style={{ }}>
              <TableCategory>{label}</TableCategory>
              <div>{content}</div>
            </RowGapWrapper>
          )
        })}
      </ColumnGapWrapper>
    </NarrowCustomCard>
  )
}
