import { gql } from '@apollo/client'

export const addCallRecord = gql`
  mutation AddCallRecord($input: CallRecordMutationInput!) {
    AddCallRecord(input: $input) {
      _debug
      error
      ok
    }
  }
`

export const editCallRecord = gql`
  mutation EditCallRecord($editCallRecordId: ID!, $input: CallRecordMutationInput!) {
    EditCallRecord(id: $editCallRecordId, input: $input) {
      ok
      error
      _debug
    }
  }
`

export const removeCallRecords = gql`
  mutation RemoveCallRecords($ids: [ID]!) {
    RemoveCallRecords(ids: $ids) {
      ok
      error
      _debug
    }
  }
`

export const addContractRecord = gql`
mutation AddContractRecord($input: ContractRecordMutationInput!) {
  AddContractRecord(input: $input) {
    ok
    error
    _debug
    data {
      id
    }
  }
}
`

export const removeContract = gql`
  mutation RemoveContract($removeContractId: ID!) {
    RemoveContract(id: $removeContractId) {
      ok
      error
      _debug
    }
  }
`

// 미리 만듬: 계약기록 수정
export const editContractRecord = gql`
  mutation EditContractRecord($editContractRecordId: ID!, $input: ContractRecordMutationInput!) {
    EditContractRecord(id: $editContractRecordId, input: $input) {
      ok
      error
      _debug
    }
  }
`

// 계약 삭제
export const removeContractRecord = gql`
  mutation RemoveContractRecord($removeContractRecordId: ID!) {
    RemoveContractRecord(id: $removeContractRecordId) {
      ok
      error
      _debug
    }
  }
`

// 계약 금액 추가
export const addContractPayments = gql`
  mutation AddContractPayments($input: PaymentRecordsMutationInput!) {
    AddContractPayments(input: $input) {
      ok
      error
      _debug
    }
  }
`

// 계약 금액 수정
export const editContractPayments = gql`
  mutation EditContractPayments($periodicId: ID!, $input: PaymentRecordMutationInput!) {
    EditContractPayments(periodicId: $periodicId, input: $input) {
      _debug
      error
      ok
    }
  }
`

// 계약 금액 삭제
export const removeContractPayments = gql`
  mutation RemoveContractPayments($periodicId: ID!) {
    RemoveContractPayments(periodicId: $periodicId) {
      _debug
      error
      ok
    }
  }
`

// 계약 기록 > 입금 내역
export const addDepositRecord = gql`
  mutation AddDepositRecord($paymentRecordId: ID!, $input: AddDepositMutationInput!) {
    AddDepositRecord(paymentRecordId: $paymentRecordId, input: $input) {
      _debug
      error
      ok
    }
  }
`

// 계약 기록 > 입금 내역
export const editDepositRecord = gql`
  mutation EditDepositRecord($editDepositRecordId: ID!, $input: AddDepositMutationInput!) {
    EditDepositRecord(id: $editDepositRecordId, input: $input) {
      ok
      error
      _debug
    }
  }
`

// 계약 기록 > 입금 내역
export const removeDepositRecord = gql`
  mutation RemoveDepositRecord($removeDepositRecordId: ID!) {
    RemoveDepositRecord(id: $removeDepositRecordId) {
      ok
      error
      _debug
    }
  }
`

export const addComment = gql`
  mutation AddComment($contractId: ID!, $content: String) {
    AddComment(contractId: $contractId, content: $content) {
      ok
      error
      _debug
    }
  }
`

export const editComment = gql`
  mutation EditComment($editCommentId: ID!, $content: String) {
    EditComment(id: $editCommentId, content: $content) {
      ok
      error
      _debug
    }
  }
`

export const removeComment = gql`
  mutation RemoveComment($removeCommentId: ID!) {
    RemoveComment(id: $removeCommentId) {
      ok
      error
      _debug
    }
  }
`
