import { useSearchParams } from 'react-router-dom'
import _ from 'lodash'

export const getUpdatedSearchParams = (type, key, value, sp) => {
  if (_.isArray(value)) {
    sp.delete(`${type}_${key}`)
    value.forEach(v => {
      sp.append(`${type}_${key}`, v)
    })
  } else {
    sp.set(`${type}_${key}`, value)
  }
  return sp
}

// 계약 기록 > 디테일 페이지 필터
export const usePaymentDetailFilterSearch = (initParams) => {
  const defSearchParams = {
    filter_contractState: [] // 계약상태
  }

  const [searchParams, setSearchParams] = useSearchParams(initParams)
  const onChangeMeta = (type, key, value) => {
    const newSearchParams = getUpdatedSearchParams(type, key, value, searchParams)
    setSearchParams(newSearchParams)
  }
  const onRemoveItemFromFilter = (key, value) => {
    onChangeMeta('filter', value, _.filter(meta.filter[key], v => v !== value))
  }
  const onResetFilterAndGroup = () => {
    console.log('reset filter')
    setSearchParams(defSearchParams)
  }

  const meta = {
    filter: {
      contractState: [] // 계약 상태
    }
  }
  const a = ['filter']
  const c = ['contractState']
  a.forEach(v1 => {
    c.forEach(v2 => {
      meta[v1][v2] = [...searchParams.getAll(`${v1}_${v2}`)]
    })
  })
  return {
    meta,
    searchParams,
    setSearchParams,
    getUpdatedSearchParams,
    onChangeMeta,
    onResetFilterAndGroup,
    onRemoveItemFromFilter
  }
}
