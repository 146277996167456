import React, { useEffect, useState } from 'react'
import { convertToDayjs, parseDateObj, uuidv4 } from '../../../common/util'
import { cycleOptions } from '../../config'
import ProgressBar from '../../components/contractRecordDetail/ProgressBar'
import { calculateDateDuration, getLabelByValue, roundDecimal } from '../../components/common/CommonFunction'
import { CardHeader } from '../../components/contractRecordDetail/CardHeader'
import { CustomDatePicker } from '../../components/common/CustomDatePicker'
import CompletePaymentButton from '../../components/contractRecordDetail/CompletePaymentButton'
import { ColumnGapWrapper, MoneyContainer, RowGapWrapper, SpaceBetweenWrapper } from '../../../common/Theme/style'

import _ from 'lodash'
import { PlusOutlined } from '@ant-design/icons'
import { Button, DatePicker, Empty, Form, Input, InputNumber, Select, Table } from 'antd'
import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
dayjs.locale('ko')

const SelectLabel = styled.div`
  color: #747474;
`
const StyledSelect = styled(Select)`
  min-width: 100px;
`
const CountContainer = styled.div`
  min-width: 70px;
`
const StyledButton = styled(Button)`
  width: 100px;
  padding: 18px 100px;
  margin-top: 10px;
`
const StyledTable = styled(Table)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table-thead .ant-table-cell {
    background: white;
    border-bottom: 0.5px solid ${COLORS.primaryColor50};
  }
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`
const parseAddKey = (data) => {
  return (
    _.map(data, v => {
      return {
        ...v,
        key: uuidv4()
      }
    })
  )
}
// 계약 기록 > 계약 금액 목록
export const ContractContainer = ({ loading, contractData, setContractData, contractType, title, options, readOnly, depositDataSource, setDepositDataSource, sumReceivedAmount = () => {}, currentUser, form, newContractIds, setNewContractIds, deletedContractIds, setDeletedContractIds }) => {
  const TAGDATAGAP = 10
  const TAGGAP = readOnly ? 30 : 20
  const [buttonStates, setButtonStates] = useState({}) // 주기 생성 버튼 상태
  const [addContract, setAddContract] = useState(false)
  const depositRecords = _.cloneDeep(depositDataSource)
  const [contractDataSource, setContractDataSource] = useState([])
  useEffect(() => {
    reformatPayment(parseAddKey(contractData)) // 계약 항목 입금 항목 생성
  }, [contractData])

  useEffect(() => {
    checkCycleButtonState() // 주기 버튼 상태 체크
  }, [contractDataSource])

  const handleAdd = (dataIndex, depositType, category, receivedAmount = 0, type = 'CARD', expenseProofMethod = 'CASHRECEIPT') => {
    setAddContract(false)
    const newId = uuidv4()
    if (dataIndex === 'contractList') { // 계약 금액 목록 추가
      const newData = {
        key: newId,
        id: newId,
        category: null, // 금액 종류
        dueAmount: 0, // 총 금액
        dueDate: null,
        periodic: null,
        periodicId: newId,
        payments: []
        // date: parseDateObj(new Date())
      }
      const currentFormData = form.getFieldValue(contractType)
      form.setFieldsValue({ [contractType]: [...currentFormData, newData] })
      setContractDataSource([...currentFormData, newData])
      setNewContractIds([...newContractIds, newId])
    } else { // 입금 내역 목록 추가
      const newData = {
        key: newId,
        id: newId,
        received: parseDateObj(new Date()),
        receivedAmount,
        createdBy: currentUser?.name,
        expenseProofMethod,
        type,
        category
      }
      setDepositDataSource([...depositRecords, newData])
      form.setFieldsValue({ depositRecordList: [...depositRecords, newData] })
      sumReceivedAmount()
    }
  }
  const checkCycleButtonState = () => { // 주기 생성 버튼 상태 체크
    const cycleButtonStates = contractDataSource.reduce((acc, item) => {
      // 수정 필요: count 없어서 임시로 없앰
      acc[item.key] = !(item.category !== null && item.totalDueAmount > 0 && item.count > 0 && item.dueDate !== null)
      return acc
    }, {})
    setButtonStates(cycleButtonStates)
  }

  const reformatPayment = (data) => {
    const formattedData = _.map(_.groupBy(data, (record) => _.trim(record.periodicId)), (records, periodicId) => ({
      key: periodicId,
      id: records[0].id,
      periodicId,
      totalDueAmount: _.sumBy(records, 'dueAmount'),
      category: records[0].category,
      periodic: records[0].periodic,
      dueDate: records[0].dueDate,
      dueAmount: records[0].dueAmount,
      payments: _.map(records, (record) => ({
        key: record.id,
        dueDate: record.dueDate,
        dueAmount: record.dueAmount,
        deposits: record.deposits,
        category: record.category
      }))
    }))
    // const groupedData = _.groupBy(data, (record) => _.trim(record.periodicId))
    // 계약 금액 목록 항목 조정 (주기 계산)
    const transformedContracts = _.map(data, contract => {
      let result = []
      let eachPayment = contract.dueAmount
      const count = contract.count === undefined ? 2 : contract.count // 삭제 필요: 임시 값
      if (contract.periodic !== null) {
        eachPayment = contract.dueAmount / count
      }
      // count date
      const isValidFormat = dayjs(contract.dueDate, 'YYYY-MM-DD', true).isValid()
      const cycleDate = dayjs(contract.dueDate, 'YYYY-MM-DD')

      const dueDates = (calculateDateDuration(contract.periodic, count, cycleDate))
      result = _.map(dueDates, (deadline, index) => {
        return {
          key: uuidv4(),
          dueAmount: eachPayment,
          totalDeposit: 0,
          dueDate: [deadline],
          status: false,
          category: contract.category
          // group by 한 배열의 길이를 count로
        }
      })
      return {
        ...contract,
        payment: result
      }
    })

    setContractDataSource(formattedData)
    // 계약 금액 > 입금 내역에 따라 총 입금 금액 업데이트
    const updatedContracts = _.forEach(transformedContracts, contract => {
      _.forEach(contract.payment, payment => {
        while (payment.totalDeposit < payment.dueAmount) {
          const matchingRecordIndex = _.findIndex(depositRecords, { category: contract.category })
          if (matchingRecordIndex === -1) {
            break
          }
          const matchingRecord = depositRecords[matchingRecordIndex]
          payment.totalDeposit += matchingRecord.receivedAmount
          _.pullAt(depositRecords, matchingRecordIndex)
          if (payment.totalDeposit >= payment.dueAmount) {
            payment.status = true
            break
          }
        }
      })
    })
    // setContractDataSource(updatedContracts)
  }
  const handleDelete = (key, index) => {
    const currentFormData = form.getFieldValue(contractType)
    _.remove(currentFormData, (item, idx) => {
      return idx === index
    })
    form.setFieldsValue({ [contractType]: currentFormData })
    setContractDataSource(currentFormData)

    // 삭제된 키가 있다면 추가된 사항에서 삭제되기 때문에 그냥 지우면되지만
    // 삭제된 키는 있는데 matching하는 값이 newContractIds에 없다면 기존의 데이터에서 삭제하는거다
    if (_.includes(newContractIds, key)) {
      setNewContractIds((current) => _.without(current, key))
    } else {
      setDeletedContractIds((current) => [...current, key])
    }
  }

  const handleAddMultiplePayment = (dataIndex, depositType) => { // 새로운 주기 입금 항목 생성
    setAddContract(false)
    const newId = uuidv4()
    const newData = {
      key: newId,
      category: null,
      dueAmount: 0,
      dueDate: null,
      periodic: 'DAILY',
      periodicId: newId,
      payments: [],
      count: 0
    }
    const currentFormData = form.getFieldValue(contractType)
    form.setFieldsValue({ [contractType]: [...currentFormData, newData] })
    setContractDataSource([...currentFormData, newData])
    setNewContractIds([...newContractIds, newId])
  }
  const cycleUnitMap = {
    DAILY: 'day',
    WEEKLY: 'week',
    MONTHLY: 'month'
  }

  const handleMultiplePayment = (key, index) => { // 주기 생성 버튼
    const currentFormData = form.getFieldValue(contractType)
    const currentChange = currentFormData[index]
    // const dateList = calculateDateDuration(currentChange.periodic, currentChange.count, currentChange.dueDate, currentChange.totalDueAmount)
    const eachDueAmount = _.round((currentChange.totalDueAmount / currentChange.count), 2)
    const result = _.times(currentChange.count, (index) => ({
      dueDate: dayjs(currentChange.dueDate).add(index, cycleUnitMap[currentChange.periodic]).format('YYYY-MM-DD'),
      dueAmount: eachDueAmount
    }))

    const updatedDataSource = currentFormData.map((item, index) => {
      if (item.key === key) {
        return { ...item, dueAmount: eachDueAmount, payments: result }
      }
      return item
    })
    setContractDataSource(updatedDataSource)
    form.setFieldsValue({ [contractType]: updatedDataSource })
  }

  const handleFieldChange = (key, field, value, index) => {
    const currentFormData = form.getFieldValue(contractType)
    const currentChange = currentFormData[index]
    console.log('🚀 ~ handleFieldChange ~ currentChange:', currentChange)
    // const isButtonDisabled = !(currentChange !== null && currentChange.totalDueAmount > 0 && currentChange.count > 0 && currentChange.dueDate !== null)
    const isButtonDisabled = !(currentChange !== null && currentChange.totalDueAmount > 0 && currentChange.dueDate !== null)
    console.log('🚀 ~ handleFieldChange ~ isButtonDisabled:', isButtonDisabled)
    setButtonStates(prevStates => ({ ...prevStates, [key]: isButtonDisabled }))
  }

  const handleAddContract = () => {
    setAddContract(true)
  }
  const multiplePaymentColumns = [
    {
      title: '입금 기한',
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 40
    },
    {
      title: '총 금액',
      dataIndex: 'dueAmount',
      key: 'dueAmount',
      width: 40,
      render: (dueAmount, _, index) => {
        return (
          <MoneyContainer value={roundDecimal(dueAmount)} />
        )
      }
    },
    {
      title: '총 입금 금액',
      dataIndex: 'receivedAmount',
      key: 'receivedAmount',
      width: 30,
      render: (receivedAmount, record) => {
        return (
          <MoneyContainer value={roundDecimal(receivedAmount)} />
        )
      }
    },
    {
      title: '',
      dataIndex: 'prgressBar',
      key: 'prgressBar',
      width: 30,
      render: (_, record) => {
        const remain = record.dueAmount - record.receivedAmount
        const percent = (record.receivedAmount / record.dueAmount) * 100
        return (
          <ProgressBar
            percent={roundDecimal(percent)}
            remain={remain}
          />
        )
      }
    },
    // 수정 필요: 완납 임시 숨김
    // {
    //   title: '',
    //   dataIndex: 'buttons',
    //   key: 'buttons',
    //   width: 20,
    //   render: (_, record) => {
    //     if (!readOnly) {
    //       const remain = record.dueAmount - record.totalDeposit
    //       return (
    //         <RowGapWrapper>
    //           <CompletePaymentButton
    //             buttonKey={record.key}
    //             remain={remain}
    //             category={record.category}
    //             dueAmount={record.dueAmount}
    //             depositType='MULTIPLE'
    //             contractDataSource={contractDataSource}
    //             setContractData={setContractDataSource}
    //             handleAdd={handleAdd}
    //             sumReceivedAmount={sumReceivedAmount}
    //           />
    //         </RowGapWrapper>
    //       )
    //     }
    //   }
    // }
  ]

  return (
    <Form form={form}>
      <CardHeader type='button' dataIndex='contractList' title={title} value='추가' readOnly={readOnly} handleAdd={handleAddContract} />
      <ColumnGapWrapper $gap={10} style={{ marginTop: 10 }}>
        {contractDataSource.length === 0 && addContract === false &&
          <Empty
            description={
              <p>계약 사항이 없습니다.</p>
            }
          />}
        {_.map(contractDataSource, ({ key, category, periodic, payments, totalDueAmount, dueDate, dueAmount }, index) => {
          console.log('🚀 ~ {_.map ~ dueAmount:', dueAmount)
          const count = payments.length
          const totalReceivedAmount = _.sumBy(
            _.flatMap(payments, 'deposits'),
            'receivedAmount'
          )
          const percent = (totalReceivedAmount / dueAmount) * 100
          const remain = dueAmount - totalReceivedAmount
          
          if (periodic === null) {
            return (
              <SpaceBetweenWrapper key={key} style={{ alignItems: 'center', border: '1px solid #efefef', padding: '8px 15px', borderRadius: '5px', gap: 10 }}>
                <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                  {readOnly && <SelectLabel>금액 종류</SelectLabel>}
                  {readOnly
                    ? (
                      <div>{getLabelByValue(options, category)}</div>)
                    : (
                      <Form.Item
                        name={[contractType, index, 'category']}
                        // rules={[{ required: true, message: '' }]}
                        label='금액 종류'
                      >
                        <StyledSelect
                          placeholder='금액 종류'
                          defaultValue={category?.length > 0 ? category : null}
                          // onChange={(value) => handleFieldChange(key, 'category', value, index)}
                          options={options}
                        />
                      </Form.Item>)}
                </RowGapWrapper>
                {/* {contractType === 'extraContract' && ( // 추가 계약 조건 시에만 보여지는 항목
                  <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                    {readOnly && <SelectLabel>약정</SelectLabel>}
                    {readOnly
                      ? (
                        <div style={{ minWidth: 100 }}>{agreement}</div>)
                      : (
                        <Form.Item
                          name={[contractType, index, 'agreement']}
                          rules={[{ required: false }]}
                          label='약정'
                        >
                          <Input
                            defaultValue={agreement}
                            placeholder='내용을 입력해주세요.'
                            min={0}
                            style={{
                              width: 200
                            }}
                            onChange={(value) => handleFieldChange(key, 'agreement', value)}
                          />
                        </Form.Item>)}
                  </RowGapWrapper>
                )} */}
                <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                  {readOnly && <SelectLabel>총 금액</SelectLabel>}
                  {readOnly
                    ? (
                      <MoneyContainer value={totalDueAmount} />)
                    : (
                      <Form.Item
                        name={[contractType, index, 'totalDueAmount']}
                        // rules={[{ required: true, message: '' }]}
                        label='총 금액'
                      >
                        <InputNumber
                          changeOnWheel
                          defaultValue={totalDueAmount === 0 ? 0 : totalDueAmount}
                          placeholder='0'
                          // onChange={(value) => handleFieldChange(key, 'totalDueAmount', value)}
                          parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          addonAfter='원'
                          min={0}
                          style={{
                            width: 160
                          }}
                        />
                      </Form.Item>)}
                </RowGapWrapper>
                <RowGapWrapper $gap={TAGGAP}>
                  <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                    {readOnly && <SelectLabel>입금 기한</SelectLabel>}
                    {readOnly
                      ? (
                        <div>{dueDate}</div>)
                      : (
                        <Form.Item
                          name={[contractType, index, 'dueDate']}
                          // rules={[{ required: true, message: '' }]}
                          label='입금 기한'
                        >
                          <CustomDatePicker
                            date={dueDate?.length > 0 ? dayjs(dueDate) : null}
                            onChange={(date) => {
                              const newDateTime = date ? date.format('YYYY-MM-DD') : null
                              form.setFieldValue([contractType, index, 'dueDate'], newDateTime)
                            }}
                            style={{
                              width: 130
                            }}
                          />
                        </Form.Item>)}
                  </RowGapWrapper>
                  <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                    <SelectLabel>총 입금 금액</SelectLabel>
                    <MoneyContainer key={key} value={totalReceivedAmount} />
                  </RowGapWrapper>
                </RowGapWrapper>
                <ProgressBar
                  percent={roundDecimal(percent)}
                  remain={remain}
                />
                <div>
                  {
                    !readOnly
                      ? (
                        <RowGapWrapper>
                          <CompletePaymentButton
                            buttonKey={key}
                            remain={remain}
                            category={category}
                            dueAmount={dueAmount}
                            depositType='SINGLE'
                            contractDataSource={contractDataSource}
                            setContractData={setContractDataSource}
                            handleAdd={handleAdd}
                            sumReceivedAmount={sumReceivedAmount}
                          />
                          <Button danger onClick={() => handleDelete(key, index)}>삭제</Button>
                        </RowGapWrapper>)
                      : null
                  }
                </div>
              </SpaceBetweenWrapper>
            )
          } else { // 'MULTIPLE'
            return (
              <ColumnGapWrapper key={key}>
                <ColumnGapWrapper style={{ border: '1px solid #efefef', padding: '8px 15px', borderRadius: '5px' }}>
                  <SpaceBetweenWrapper style={{ alignItems: 'center', gap: 10 }}>
                    <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                      {readOnly && <SelectLabel>금액 종류</SelectLabel>}
                      {readOnly
                        ? (
                          <div>{getLabelByValue(options, category)}</div>)
                        : (
                          <Form.Item
                            name={[contractType, index, 'category']}
                            // rules={[{ required: true, message: '' }]}
                            label='금액 종류'
                          >
                            <StyledSelect
                              placeholder='금액 종류'
                              defaultValue={category?.length > 0 ? category : null}
                              onChange={(value) => handleFieldChange(key, 'category', value, index)}
                              options={options}
                            />
                          </Form.Item>)}
                    </RowGapWrapper>
                    {/* {contractType === 'extraContract' && ( // 추가 계약 조건 시에만 보여지는 항목
                      <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                        <SelectLabel>약정</SelectLabel>
                        {readOnly
                          ? (
                            <div style={{ minWidth: 100 }}>{agreement}</div>)
                          : (
                            <Input
                              defaultValue={agreement}
                              placeholder='내용을 입력해주세요.'
                              min={0}
                              style={{
                                width: 200
                              }}
                              onChange={(value) => handleFieldChange(key, 'agreement', value, index)}
                            />)}
                      </RowGapWrapper>
                    )} */}
                    <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                      {readOnly && <SelectLabel>총 금액</SelectLabel>}
                      {readOnly
                        ? (
                          <MoneyContainer value={totalDueAmount} />)
                        : (
                          <Form.Item
                            name={[contractType, index, 'totalDueAmount']}
                            // rules={[{ required: true, message: '' }]}
                            label='총 금액'
                          >
                            <InputNumber
                              changeOnWheel
                              placeholder='0'
                              defaultValue={totalDueAmount}
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              addonAfter='원'
                              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
                              min={0}
                              style={{
                                width: 160
                              }}
                              onChange={(value) => handleFieldChange(key, 'totalDueAmount', value, index)}
                            />
                          </Form.Item>)}
                    </RowGapWrapper>
                    <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                      {readOnly && <SelectLabel style={{ minWidth: '50px' }}>차수</SelectLabel>}
                      {readOnly
                        ? (
                          <CountContainer>{count}</CountContainer>)
                        : (
                          <Form.Item
                            name={[contractType, index, 'count']}
                            // rules={[{ required: true, message: '' }]}
                            label='차수'
                          >
                            <InputNumber
                              min={0}
                              defaultValue={count}
                              style={{ width: '130px' }}
                              onChange={(value) => handleFieldChange(key, 'count', value, index)}
                            />
                          </Form.Item>
                          )}
                    </RowGapWrapper>
                    <RowGapWrapper $gap={TAGDATAGAP} style={{ alignItems: 'center' }}>
                      {readOnly && <SelectLabel>주기</SelectLabel>}
                      <RowGapWrapper>
                        {readOnly
                          ? (
                            <div>{getLabelByValue(cycleOptions, periodic)}</div>)
                          : (
                            <Form.Item
                              name={[contractType, index, 'periodic']}
                              // rules={[{ required: true, message: '' }]}
                              label='주기'
                            >
                              <StyledSelect
                                placeholder='주기'
                                defaultValue={periodic || undefined}
                                onChange={(value) => handleFieldChange(key, 'periodic', parseDateObj(value), index)}
                                style={{ minWidth: '55px' }}
                                options={cycleOptions}
                              />
                            </Form.Item>)}
                        {readOnly
                          ? (
                            <div>{dueDate}</div>)
                          : (
                            <Form.Item
                              name={[contractType, index, 'dueDate']}
                              // rules={[{ required: true, message: '' }]}
                            >
                              <CustomDatePicker
                                date={dueDate?.length > 0 ? dayjs(dueDate) : null}
                                onChange={(date) => {
                                  handleFieldChange(key, 'dueDate', parseDateObj(date), index)
                                  const newDateTime = date ? date.format('YYYY-MM-DD') : null
                                  form.setFieldValue([contractType, index, 'dueDate'], newDateTime)
                                }}
                                placeholder='날짜 선택'
                                // defaultValue={date}
                                // style={style}
                              />
                            </Form.Item>)}
                      </RowGapWrapper>
                    </RowGapWrapper>
                    {readOnly
                      ? null
                      : (
                        <Button
                          disabled={buttonStates[key]}
                          style={{ minWidth: '120px' }}
                          onClick={() => handleMultiplePayment(key, index)}
                        >
                          <PlusOutlined /> 생성
                        </Button>
                        )}
                    <div>
                      {!readOnly
                        ? (<Button danger onClick={() => handleDelete(key, index)}>삭제</Button>)
                        : null}
                    </div>
                  </SpaceBetweenWrapper>
                  <StyledTable
                    columns={multiplePaymentColumns}
                    dataSource={payments}
                    pagination={false}
                    size='small'
                    scroll={{
                      y: 300
                    }}
                  />
                </ColumnGapWrapper>
              </ColumnGapWrapper>
            )
          }
        })}
      </ColumnGapWrapper>
      {addContract && (
        <RowGapWrapper $gap={10} style={{ justifyContent: 'center' }}>
          <StyledButton onClick={() => handleAdd('contractList', 'SINGLE')}>단일 입금</StyledButton>
          <StyledButton onClick={() => handleAddMultiplePayment('contractListMultiple', 'MULTIPLE')}>주기 입금</StyledButton>
        </RowGapWrapper>
      )}
    </Form>
  )
}
