import { navigateToContractRecordDetail } from '../../../cacheAndNavigation/contractRecordDetail/navigation'
import { useNavigate } from 'react-router-dom'
import { useContractFilter } from '../../filter/contractRecordDetail/useContractFilter'

export const usePaymentTable = () => {
  const navigate = useNavigate()
  const { searchParams } = useContractFilter()
  const searchString = searchParams.toString()
  const onClickRow = (contractRecordMeta, viewMode) => {
    if (viewMode !== 'group') {
      navigateToContractRecordDetail(navigate, contractRecordMeta, searchString)
    }
  }

  return {
    onClickRow
  }
}
