import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import _ from 'lodash'

const PaymentStatusButton = styled(Button)`
  border: ${props => props.complete ? '1px solid #64B93E' : '1px solid #d9d9d9'};
  color: ${props => props.complete ? '#64B93E' : '#000'};
`
const findContractByDepositId = (contractArray, depositId) => {
  return _.find(contractArray, contract =>
    _.some(contract.payments, payment =>
      _.some(payment.deposits, { id: depositId })
    )
  )
}

const getRemainingDueAmount = (contract) => {
  return _.map(contract.payments, payment => {
    const totalReceived = _.sumBy(payment.deposits, 'receivedAmount');
    return payment.dueAmount - totalReceived
  })
}
const handleFullPayment = (buttonKey, remain, category, depositType, contractDataSource, setContractData, handleAdd = () => {}) => {
  if (remain < 0 || remain === 0) { // 초과, 완납 상태
    return
  } else { // 완납이 아닌 상태
    handleAdd('depositRecordList', depositType, category, remain)
  }
  let filterSelectedData = null
  if (depositType === 'SINGLE') {
    filterSelectedData = _.find(contractDataSource, contract => (contract, { key: buttonKey })
    )
    if (filterSelectedData !== null) {
      const remaiin = getRemainingDueAmount(filterSelectedData)[0]
      // const payment = filterSelectedData[0].payments
      // payment[0].totalDeposit += remain
    }
    
    const updatedContractData = contractDataSource.map((item) => {
      if (item.key === buttonKey) {
        // return filterSelectedData[0]
      }
      return item
    })
    setContractData(updatedContractData)
  } else { // depositType === 'MULTIPLE'
    filterSelectedData = findContractByDepositId(contractDataSource, buttonKey)
    const updatedContractData = _.forEach(contractDataSource, contract => {
      const filterSelectedData = _.filter(contract.payment, { key: buttonKey })
      if (filterSelectedData.length > 0) {
        // filterSelectedData[0].totalDeposit += remain
        // return filterSelectedData[0]
      }
      return contract
    })
    setContractData(updatedContractData)
  }
}

const CompletePaymentButton = ({ buttonKey, remain, category, dueAmount, depositType, contractDataSource, setContractData, handleAdd = () => {} }) => {
  return (
    <PaymentStatusButton complete={remain === 0} onClick={() => handleFullPayment(buttonKey, remain, category, depositType, contractDataSource, setContractData, handleAdd)}>완납</PaymentStatusButton>
  )
}
export default CompletePaymentButton
