import { convertToDayjs, parseDateObj } from '../../../common/util'
import _ from 'lodash'
import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import moment from 'moment'
import koKR from 'antd/es/date-picker/locale/ko_KR'
dayjs.locale('ko')

// DatePicker에서 영문을 한국어로 전환
export const customLocale = koKR

export const yesterdayDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')

export const checkDatePickerFormat = (date) => {
  return (date !== null && date !== undefined) ? convertToDayjs(date) : null
}

export const formatMinutesToHHMM = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return moment(`${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`, 'HH:mm')
}

export const formatMinutesToMoment = (minutes) => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return moment(`${_.padStart(hours, 2, '0')}:${_.padStart(mins, 2, '0')}`, 'HH:mm')
}
export const parseMetaToSelectOptions = v => ({ value: _.get(v, ['id']), label: _.get(v, ['name']) })

export const parseDateTimeToTime = (dateTime) => {
  if (dateTime === null || dateTime === undefined) {
    return
  }
  return dateTime.split(' ')[0]
}

export const convertTimestamp = (timestamp) => {
  if (timestamp === null || timestamp === undefined) {
    return
  }
  const date = new Date(timestamp)
  const hours = _.padStart(date.getHours(), 2, '0')
  const minutes = _.padStart(date.getMinutes(), 2, '0')
  return `${hours}:${minutes}`
}

// 계약 기록 > 계약 기록 페이지 상태 태그
const ContractStateContainer = styled.div`
  color: ${props => (props.$state === 'CONTRACT') ? `${COLORS.primaryColor}` : props.$state === 'PROGRESS' ? `${COLORS.green}` : props.$state === 'OVERDUE' ? `${COLORS.red}` : props.$state === 'NOCONTRACT' ? `${COLORS.orange}` : props.$state === 'CLOSE' ? `${COLORS.black}` : ''};
  border: 1px solid ${props => (props.$state === 'CONTRACT') ? `${COLORS.primaryColor}` : props.$state === 'PROGRESS' ? `${COLORS.green}` : props.$state === 'OVERDUE' ? `${COLORS.red}` : props.$state === 'NOCONTRACT' ? `${COLORS.orange}` : props.$state === 'CLOSE' ? `${COLORS.black}` : ''};
  display: flex;
  justify-content: center;
  border-radius: 5px;
  padding: 7px 0;
  width: 50px;
  font-size: 12px;
`

export const contractStateTag = (state) => {
  const check = state === 'CONTRACT' ? '체결' : state === 'PROGRESS' ? '진행' : state === 'OVERDUE' ? '추심' : state === 'NOCONTRACT' ? '미체결' : state === 'CLOSE' ? '종결' : ''
  return <ContractStateContainer $state={state}>{check}</ContractStateContainer>
}

export const roundDecimal = (value) => {
  if (value > -1) {
    return parseFloat(value.toFixed(2))
  } else {
    return 0
  }
}

export const getLabelByValue = (options, value) => {
  const option = _.find(options, { value })
  return option ? option.label : null
}

// 계약 기록 > 날짜 주기 개산
export const calculateDateDuration = (periodic, count, cycleDate, totalDueAmount) => { // 주기 계산
  const eachDueAmount = (totalDueAmount / count).toFixed(2)
  if (periodic === 'DAILY') {
    return { dueDate: _.range(count).map(i => dayjs(cycleDate).add(i, 'day').format('YYYY-MM-DD')), dueAmount: eachDueAmount }
  } else if (periodic === 'WEEKLY') {
    return _.range(count).map(i => dayjs(cycleDate).add(i, 'week').format('YYYY-MM-DD'))
  } else if (periodic === 'MONTHLY') {
    return _.range(count).map(i => dayjs(cycleDate).add(i, 'month').format('YYYY-MM-DD'))
  } else { // yearly
    return _.range(count).map(i => dayjs(cycleDate).add(i, 'year').format('YYYY-MM-DD'))
  }
}

// 콜기록 > 사건구분 선택됐는지 여부 확인
export const checkProejectTypeInput = (form, record, projectSubTypes, value = '') => {
  const formData = form.getFieldValue('call')
  const currentData = _.filter(formData, { id: record.id })

  if (value.length > 0) {
    filterProjectSubtypes(form, record, projectSubTypes, value)
    return false
  } else {
    return !(currentData[0]?.projectType.id !== null)
  }
}

// 사건구분 선택이 바뀔 경우
export const onChageProejectType = (value) => {
  return false
}

// 콜기록 > 세부사건구분 (사건구분 선택에따라 세부사건 필터)
export const filterProjectSubtypes = (form, record, projectSubTypes, projectType) => {
  // const formData = form.getFieldValue('call')
  // const currentData = _.filter(formData, { id: record.id })
  // if (currentData[0]?.projectType.length === undefined) {
  //   value = currentData[0]?.projectType.id
  // }
  return _.filter(projectSubTypes, { type: projectType })
}

export const checkDateTime = (date, time) => {
  if (date === null && time !== null) {
    return parseDateObj(new Date()) + ' ' + time
  } else if (date !== null && time === null) {
    // const currentTime = dayjs().format('HH:mm')
    return date + ' ' + '00:00:00'
  } else if (date === null && time === null) {
    return null
  } else {
    return date + ' ' + time
  }
}
