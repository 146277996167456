// 계약 기록 > 고객사건 정보
export const caseDetailCategory = [
  {
    key: 'caseNumber',
    value: 'caseNumber',
    label: '사건번호'
  },
  {
    key: 'caseName',
    value: 'caseName',
    label: '사건명'
  },
  {
    key: 'projectType',
    value: 'projectType',
    label: '사건구분'
  },
  {
    key: 'department',
    value: 'department',
    label: '담당부서'
  },
  {
    key: 'assignedLawers',
    value: 'assignedLawers',
    label: '담당변호사'
  }
]

export const dayGroups = [
  {
    value: 'daily',
    label: '일간',
    key: '2'
  },
  {
    label: '주간',
    value: 'weekly',
    key: '3'

  },
  {
    label: '월간',
    value: 'monthly',
    key: '4'
  }
]

export const graphTag = {
  CALL: 'Call Counts',
  VISIT: 'Visit Counts'
}

export const paymentCategoryOptions = [
  {
    value: 'DEPOSIT',
    label: '착수금'
  },
  {
    value: 'INTERIM',
    label: '중도금'
  },
  {
    value: 'BALANCE',
    label: '잔금'
  },
  {
    value: 'CONSULT',
    label: '상담료'
  }
]

export const extraPaymentCategoryOptions = [
  {
    value: 'CONTINGENT',
    label: '성공수금'
  },
  {
    value: 'EXTRA',
    label: '추가약정'
  },
  {
    value: 'ETC',
    label: '기타'
  }
]

export const allPaymentCategoryOptions = [
  {
    value: 'DEPOSIT',
    label: '착수금'
  },
  {
    value: 'INTERIM',
    label: '중도금'
  },
  {
    value: 'BALANCE',
    label: '잔금'
  },
  {
    value: 'CONSULT',
    label: '상담료'
  },
  {
    value: 'CONTINGENT',
    label: '성공수금'
  },
  {
    value: 'EXTRA',
    label: '추가약정'
  },
  {
    value: 'ETC',
    label: '기타'
  }
]

export const paymentTypeOptions = [
  {
    value: 'CARD',
    label: '카드'
  },
  {
    value: 'CASH',
    label: '현금'
  },
  {
    value: 'WIRE',
    label: '계좌이체'
  }
]

export const cycleOptions = [
  {
    value: 'DAILY',
    label: '매일'
  },
  {
    value: 'WEEKLY',
    label: '매주'
  },
  {
    value: 'MONTHLY',
    label: '매달'
  },
  {
    value: 'YEARLY',
    label: '매년'
  }]

export const contractStateOptions = [
  {
    value: 'CONTRACT',
    label: '체결',
    key: '1'
  },
  {
    value: 'PROGRESS',
    label: '진행',
    key: '2'
  },
  {
    value: 'OVERDUE',
    label: '추심',
    key: '3'
  },
  {
    value: 'NOCONTRACT',
    label: '미체결',
    key: '4'
  },
  {
    value: 'CLOSE',
    label: '종결',
    key: '5'
  }
]

export const currentStateOptions = [
  {
    value: '방문',
    label: '방문',
    key: '1'
  },
  {
    value: '선임',
    label: '선임',
    key: '2'
  },
  {
    value: '예약중',
    label: '예약중',
    key: '3'
  },
  {
    value: '확인중',
    label: '확인중',
    key: '4'
  },
  {
    value: '예약취소',
    label: '예약취소',
    key: '5'
  }
]

export const depositType = [
  {
    value: 'PROGRESS',
    label: '미완납',
    key: '1'
  },
  {
    value: 'COMPLETE',
    label: '완납',
    key: '2'
  },
  {
    value: 'OVERDUE',
    label: '추심',
    key: '3'
  }
]

export const expenseProofMethodOptions = [
  {
    key: 'CASHRECEIPT',
    value: 'CASHRECEIPT',
    label: '현금영수증'
  },
  {
    key: 'TAXRECEIPT',
    value: 'TAXRECEIPT',
    label: '세금계산서'
  }
]

export const projectTypeOptions = [
  {
    value: '형사',
    label: '형사',
    key: '0',
    projectSubType: [
      {
        value: '형사',
        label: '형사',
        key: '0'
      },
      {
        value: '성범죄',
        label: '성범죄',
        key: '1'
      },
      {
        value: '교통범죄',
        label: '교통범죄',
        key: '2'
      },
      {
        value: '마약',
        label: '마약',
        key: '3'
      },
      {
        value: '군형사',
        label: '군형사',
        key: '4'
      },
      {
        value: '학교폭력',
        label: '학교폭력',
        key: '5'
      },
      {
        value: '기타',
        label: '기타',
        key: '6'
      }
    ]
  },
  {
    value: '민가사',
    label: '민가사',
    key: '1',
    projectSubType: [
      {
        value: '민사',
        label: '민사',
        key: '00'
      },
      {
        value: '행정',
        label: '행정',
        key: '11'
      },
      {
        value: '이혼',
        label: '이혼',
        key: '22'
      },
      {
        value: '상속',
        label: '상속',
        key: '33'
      },
      {
        value: '부동산건설',
        label: '부동산건설',
        key: '44'
      },
      {
        value: '노동(중대재해)',
        label: '노동(중대재해)',
        key: '55'
      },
      {
        value: '의료',
        label: '의료',
        key: '66'
      },
      {
        value: '기업',
        label: '기업',
        key: '77'
      },
      {
        value: '선거법',
        label: '선거법',
        key: '88'
      },
      {
        value: '기타',
        label: '기타',
        key: '99'
      }
    ]
  }
]

export const customerInfo = [
  {
    key: 'name',
    value: 'name',
    label: '의뢰인명'
  },
  {
    key: 'delegator',
    value: 'delegator',
    label: '위임인명'
  },
  {
    key: 'phone',
    value: 'phone',
    label: '전화번호'
  },
  {
    key: 'address',
    value: 'address',
    label: '주소'
  },
  {
    key: 'email',
    value: 'email',
    label: '이메일'
  },
  {
    key: 'caseNumber',
    value: 'caseNumber',
    label: '사건번호'
  },
  {
    key: 'ssn',
    value: 'ssn',
    label: (
      <>
        주민번호/ <br />
        사업자등록번호
      </>
    )
  },
  {
    key: 'expectedCaseName',
    value: 'expectedCaseName',
    label: '사건명'
  },
  {
    key: 'opponent',
    value: 'opponent',
    label: '상대방'
  },
  {
    key: 'jurisdiction',
    value: 'jurisdiction',
    label: '관할'
  }
]

export const reservationStatusOptions = [
  {
    key: 'CANCEL',
    value: 'CANCEL',
    label: '예약취소'
  },
  {
    key: 'PROGRESS',
    value: 'PROGRESS',
    label: '진행중'
  },
  {
    key: 'RESERVED',
    value: 'RESERVED',
    label: '예약'
  }
]

export const customerInfoTable = [
  {
    key: '0',
    name: '홍길동',
    delegator: '김영희',
    phone: '010 0000 0000',
    address: '서울특별시 00로',
    email: '0000@mail.mail',
    ssn: '0000000000000',
    caseNumber: '000가0000',
    caseName: '사건명1234',
    opponent: '홍영희',
    jurisdiction: '관할1'
  }
]

export const tmpDuplicationData = [
  {
    key: '0',
    name: '홍길동',
    delegator: '김영희',
    phone: '010 0000 0000',
    address: '서울특별시 00로',
    email: '0000@mail.mail',
    ssn: '0000000000000',
    caseNumber: '000가0000',
    caseName: '사건명1234',
    opponent: '홍영희',
    jurisdiction: '관할1'
  }
]

export const timeFormat = 'HH:mm'
