import { AppstoreOutlined, NodeIndexOutlined } from '@ant-design/icons'
import { Segmented } from 'antd'
import _ from 'lodash'

const groupModeOptions = [
  {
    value: 'DEPARTMENT',
    label: <NodeIndexOutlined />
  },
  {
    value: 'TYPE',
    label: <AppstoreOutlined />
  }
]

export const ContractGroupMode = ({ groupModeContract, onChangeGroupMode, hiddenKeys = [] }) => {
  return (
    <Segmented
      value={groupModeContract}
      options={_.filter(groupModeOptions, ({ value }) => !_.includes(hiddenKeys, value))}
      onChange={e => onChangeGroupMode(e)}
    />
  )
}
