import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BlockModal } from '../../../../timeCharge/components/common/BlockModal'
import { useBlockModal } from '../../../../timeCharge/hooks/common/useBlockModal'
import DepartmentForm from '../../../../timeCharge/components/form/DepartmentForm'
import { HeaderContentFooter } from '../../../../common/components/HeaderContentFooter'
import { HeaderContainer, HeaderTitle } from '../../../../common/components/PageHeaderStyles'
import { getDepartmentFormStatus, getDepartmentMeta } from '../../../../timeCharge/cacheAndNavigation/setting/cache'
import { useEditingDepartmentForm } from '../../../../timeCharge/hooks/settings/departments/useEditingDepartmentForm'
import _ from 'lodash'

const EditDepartmentContainer = () => {
  const navigate = useNavigate()
  const { departmentId } = useParams()
  const departmentFormStatus = getDepartmentFormStatus()
  const prevDepartmentFormStatus = useRef(departmentFormStatus)
  const {
    blockModalOpen,
    onFetchAndNav,
    onCloseCancelModal,
    onOkCancelModal
  } = useBlockModal()
  useEffect(() => {
    if (!blockModalOpen) prevDepartmentFormStatus.current = departmentFormStatus
  }, [blockModalOpen])
  const initMeta = getDepartmentMeta()
  const { onEdit } = useEditingDepartmentForm(onFetchAndNav, departmentId, _.get(initMeta, ['leaders']))
  return (
    <>
      <HeaderContentFooter
        totalHeight='100vh'
        header={(
          <HeaderContainer>
            <HeaderTitle title='부서정보 수정' />
          </HeaderContainer>
        )}
        content={(
          <div style={{ display: 'flex', padding: '100px', justifyContent: 'center' }}>
            <DepartmentForm
              initialValues={{
                name: _.get(initMeta, ['name']),
                leaders: _.map(_.get(initMeta, ['leaders']), 'id')
              }}
              users={_.get(initMeta, ['members'], []).concat(_.get(initMeta, ['leaders'], []))}
              onSave={onEdit}
              editing
              onCancel={() => navigate(-1)}
            />
          </div>
        )}
      />
      <BlockModal
        title='부서정보 수정을 취소하시겠습니까?'
        open={blockModalOpen}
        onOk={onOkCancelModal}
        onCancel={onCloseCancelModal}
      />
    </>
  )
}

export default EditDepartmentContainer
