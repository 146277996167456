import { useState } from 'react'

import { CustomSearch } from '../../common/components/CustomSearch.js'
import CallRecordFilter from '../components/filter/CallRecordFilter.js'
import { useContractRecordList } from '../hooks/apollo/useContractRecordList.js'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter.js'
import { ContractCustomTable } from '../components/customTable/ContractCustomTable.js'
import { useOverdueViewerColumns } from '../hooks/overdue/table/useOverdueViewerColumns.js'
import { CustomSearchContainer, ResponsiveGapContainer } from '../../common/Theme/style.js'
import { useCallRecordFilter } from '../hooks/filter/callRecord/useCallContractRecordFilter.js'
import { useContractRecordDeletedColumns } from '../hooks/deleted/useContractRecordDeletedColumns.js'
import { useConfirmModal } from '../hooks/common/useConfirmModal.js'
import { ConfirmModal } from '../../common/components/ConfirmModal.js'
import { useContractRecordRestore } from '../hooks/deleted/useContractRecordRestore.js'
import { usePaymentTable } from '../hooks/contractRecord/table/usePaymentTable.js'

const ContractRecordDeletedContainer = () => {
  const [searchQuery, setSearchQuery] = useState(null) // 검색창
  const {
    meta,
    filters,
    searchParams,
    onChangeMeta,
    convertToOptions,
    onResetFilterAndGroup,
    getUpdatedSearchParams,
    openStatusByKey,
    departments
  } = useCallRecordFilter()

  const onClickRecoveryRecord = (record, e) => {
    e.stopPropagation()
    const recordId = record.id
    onOpenModal(recordId, 'restore')
  }

  const {
    columns
  } = useContractRecordDeletedColumns(
    onClickRecoveryRecord
  )

  /*
  계약 기록 데이터
*/
  const {
    loading,
    variables,
    contractRecordsData,
    totalCount,
    refetch
  } = useContractRecordList(
    true, // ascending
    null, // key
    searchQuery,
    null, // from,
    null, // to,
    null, // departmentId,
    null, // contractState,
    true, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    null, // viewmode
    false, // outstanding
    null, // outstandingFrom
    null // outstandingTo
  )

  const {
    onRestoreContractRecord
  } = useContractRecordRestore(refetch)
  const {
    onCloseModal,
    onConfirmModal,
    confirmModalMeta,
    title: confirmModalTitle,
    onOpenModal
  } = useConfirmModal(
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    onRestoreContractRecord
  )

  const {
    getOverdueViewerTableGroupColumns
  } = useOverdueViewerColumns()

  const {
    onClickRow
  } = usePaymentTable()
  return (
    <>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
      />
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <ResponsiveGapContainer>
            <CustomSearchContainer>
              <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
            </CustomSearchContainer>
            <CallRecordFilter
              meta={meta}
              filters={filters}
              onChangeMeta={onChangeMeta}
              onResetFilterAndGroup={onResetFilterAndGroup}
            />
          </ResponsiveGapContainer>
        )}
        content={(
          <ContractCustomTable
            data={contractRecordsData}
            defaultColumns={columns}
            groupColumns={getOverdueViewerTableGroupColumns}
            total={totalCount}
            viewMode='list'
            pageType='contractRecordDeleted'
            unit='개'
            scroll={700}
            loading={loading}
            onClickRow={onClickRow}
          />
        )}
      />
    </>
  )
}

export default ContractRecordDeletedContainer
