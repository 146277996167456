import { useEffect, useRef, useState } from 'react'
import { HeaderGroup, Spacer } from '../../../common/components/PageHeaderStyles'
import { TotalIndicator } from '../../../timeCharge/components/common/CustomTable/TotalIndicator'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { Table } from 'antd'
import { PaymentViewMode } from '../paymentCustomTable/PaymentViewMode'
import CustomCalendar from '../common/CustomCalendar'
import { useCalendar } from '../../../timeCharge/hooks/timesheet/useCalendar'
import { ContractGroupMode } from '../recordCustomTable/ContractGroupMode'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-table-thead .ant-table-cell {
    background: white;
    border-bottom: 0.5px solid ${COLORS.primaryColor50};
  }
  .ant-table-wrapper .ant-table-cell-fix-left, :where(.css-dev-only-do-not-override-4oxdqr).ant-table-wrapper .ant-table-cell-fix-right {
    z-index: 1;
  }
  .ant-table-row {
    cursor: pointer;
  }
`

export const ContractCustomTable = ({
  data,
  defaultColumns = [],
  TableComponent = Table,
  total,
  unit,
  loading,
  pageType,
  viewMode,
  groupModeContract,
  hiddenKeys,
  onChangeViewMode = () => {},
  onChangeGroupMode = () => {},
  groupColumns,
  typeGroupColumns,
  scroll,
  handleStatViewer = () => {},
  setSelectedRowData,
  handleCopyRecord = () => {},
  rowSelection,
  onClickRow = () => {},
  disabledTableActionByKey = {
    stat: false,
    excelDownload: false,
    copyRecord: false,
    delete: false
  },
  hiddenTableActionByKey,
  selectedRowKeys,
  setSelectedRowKeys,
  onChangeSelectedRowKeys = () => {},
  editingKeys = [],
  saveAll = () => {},
  editAll = () => {},
  overdueMockData
}) => {
  const [columns, setColumns] = useState(defaultColumns)

  useEffect(() => {
    if (viewMode === 'list') {
      setColumns(defaultColumns)
    } else if (groupModeContract === 'DEPARTMENT') {
      setColumns(groupColumns)
    } else {
      setColumns(typeGroupColumns)
    }
  }, [viewMode, defaultColumns])

  const textRef = useRef()
  const isEditing = (record) => null

  const mergedColumns = columns?.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputtype: col.dataIndex === 'id' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        ref: isEditing(record) ? textRef : null
      })
    }
  })

  const calendarProps = useCalendar()
  return (
    <Container>
      <HeaderGroup>
        <Spacer />
        {viewMode === 'group' &&
          (
            <ContractGroupMode
              groupModeContract={groupModeContract}
              hiddenKeys={hiddenKeys}
              onChangeGroupMode={onChangeGroupMode}
            />)}
        {pageType !== 'contractRecordDeleted'
          ? (
            <PaymentViewMode
              viewMode={viewMode}
              hiddenKeys={hiddenKeys}
              onChangeViewMode={onChangeViewMode}
            />)
          : null}
        <TotalIndicator total={total} unit={unit} loading={loading} />
      </HeaderGroup>
      {viewMode === 'calendar'
        ? (<CustomCalendar {...calendarProps} />)
        : (
          <TableComponent
            rowKey='key'
            columns={mergedColumns}
            dataSource={data}
            pagination={false}
            pageType={pageType}
            size='middle'
            // rowSelection={rowSelection !== null
            //   ? ({
            //       type: 'checkbox',
            //       selectedRowKeys,
            //       getCheckboxProps: (record) => {
            //         const disable = (editingKeys?.findIndex((item) => record.key === item)) !== -1
            //         return {
            //           disabled: disable
            //         }
            //       },
            //       onChange: (selectedRowKeys) => {
            //         handleRowSelectionChange(selectedRowKeys)
            //       }
            //     })
            //   : undefined}
            scroll={{
              y: scroll
            }}
            onRow={(record) => ({
              onClick: () => onClickRow(record, viewMode)
            })}
          />
          )}
    </Container>
  )
}
