import _ from 'lodash'

export const navigateToOverdueRecord = (navigate, searchString, _viewMode, _groupModeContract) => {
  const viewMode = _viewMode
  const groupModeContract = _groupModeContract

  navigate(`/overdue/${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      ...viewMode ? { viewMode } : {},
      ...groupModeContract ? { groupModeContract } : {}
    }
  })
}

export const navigateToOverdueList = (navigate, contractRecordMeta = {}, departmentId, _viewMode, _groupModeContract) => {
  const viewMode = _viewMode
  const groupModeContract = _groupModeContract
  const params = new URLSearchParams()
  params.append('filter_branch', contractRecordMeta.key)
  const searchString = params.toString()
  navigate(`/overdue/${searchString ? `?${searchString}` : ''}`, {
    state: {
      ..._.get(window, ['history', 'state', 'usr'], {}),
      ...viewMode ? { viewMode } : {},
      ...groupModeContract ? { groupModeContract } : {}

    }
  })
}
