import { ContractStateColor } from '../../components/customTable/ContractStateColor'
import { Button } from 'antd'
import { MoneyContainer } from '../../../common/Theme/style'

export const useContractRecordDeletedColumns = (onClickRecoveryRecord) => {
  const contractRecordDeltedTableColumns = [
    {
      title: '계약상태',
      dataIndex: 'contractState',
      key: 'contractState',
      sort: true,
      width: 80,
      fixed: 'left',
      render: (contractState, record) => {
        return (
          <ContractStateColor state={contractState} />
        )
      }
    },
    {
      title: '입금 기한',
      dataIndex: 'paymentDeadLine',
      key: 'paymentDeadLine',
      sort: true,
      width: 100
    },
    {
      title: '금액',
      dataIndex: 'contractTotal',
      key: 'contractTotal',
      sort: true,
      width: 110,
      render: (contractTotal) => {
        return (
          <MoneyContainer value={contractTotal} />
        )
      }
    },
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 80
    },
    {
      title: '사건명',
      dataIndex: 'projectName',
      key: 'projectName',
      width: 200
    },
    {
      title: '담당변호사',
      dataIndex: 'delegates',
      key: 'delegates',
      width: 100
    },
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      width: 80
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      width: 125
    },
    {
      title: '추심상황 기재 수',
      dataIndex: 'commentCount',
      key: 'commentCount',
      sort: true,
      width: 110
    },
    {
      title: '',
      dataIndex: 'recover',
      key: 'recover',
      width: 80,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'right',
      render: (_, record) => {
        return (
          <Button
            onClick={(e) => { onClickRecoveryRecord(record, e) }}
            danger
          >
            복구
          </Button>
        )
      }
    }
  ]

  return {
    columns: contractRecordDeltedTableColumns
  }
}
