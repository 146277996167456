import { useEffect, useState } from 'react'
import ContractRecordFilter from '../components/filter/ContractRecordFilter'
import { useOverdueList } from '../hooks/overdue/useOverdueList'
import { useOverdueTable } from '../hooks/overdue/table/useOverdueTable'
import { useContractFilter } from '../hooks/filter/pastPayment/useContractFilter'
import { useContractRecordColumnsList } from '../hooks/contractRecord/contractRecordDetail/useContractRecordColumnsList'
import { ContractCustomTable } from '../components/customTable/ContractCustomTable'
import { HeaderContentFooter } from '../../common/components/HeaderContentFooter'
import { useOverdueViewerColumns } from '../hooks/overdue/table/useOverdueViewerColumns'
import { ColumnGapWrapper, MoneyContainer, ResponsiveGapContainer } from '../../common/Theme/style'
import styled from 'styled-components'
import COLORS from '../../common/Theme/colors'
import { Divider, Statistic } from 'antd'
import _ from 'lodash'
import { useContractRecordList } from '../hooks/apollo/useContractRecordList'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import { useProjectTypeDueAmount } from '../hooks/contractRecord/useProjectTypeDueAmount'
import { yesterdayDate } from '../components/common/CommonFunction'

const CollectionInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  white-space: nowrap;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
`

const CollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.red};
  padding: 15px;
  border-radius: 5px;
  width: 260px;
`

const TotalMoneyContainer = styled(Statistic)`
  .ant-statistic-content-value-int,
  .ant-statistic-content-value-decimal {
    font-size: 16px;
    font-weight: 600;
  }
`
const Tag = styled.div`
  color: #545454;
  font-size: 14px;
`
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 15px 16px;
`

const FilterResultContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
`

const CustomDivider = styled(Divider)`
  margin: 10px 0;
`

// 계약 기록 > 총 금액
const OverdueContainer = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [totalOverdue, setTotalOverdue] = useState(0) // 총 추심 금액
  const [detectiveTotal, setDetectiveTotal] = useState(0) // 형사 추심 금액
  const [civilTotal, setCivilTotal] = useState(0) // 민사 추심 금액

  const [filteredTotalOverdue, setFilteredTotalOverdue] = useState(0) // 필터한 총 추심 금액
  const [filteredDetectiveTotal, filteredSetDetectiveTotal] = useState(0) // 필터한 형사 추심 금액
  const [filteredCivilTotal, setFilteredCivilTotal] = useState(0) // 필터한 민사 추심 금액

  const {
    onClickRow
  } = useOverdueTable()
  const {
    viewMode,
    groupModeContract,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    ...props
  } = useOverdueList()
  const {
    getOverdueViewerTableGroupColumns,
    getOverdueViewerTableGroupTypeColumns
  } = useOverdueViewerColumns()

  const {
    meta,
    filters,
    onChangeMeta,
    onResetFilterAndGroup
  } = useContractFilter()
    console.log('🚀 ~ OverdueContainer ~ meta:', meta)
    console.log('🚀 ~ OverdueContainer ~ filters:', filters)

  /*
    계약 기록 데이터
  */
  const {
    loading,
    variables,
    contractRecordsData,
    totalCount,
    totalDueAmount,
    totalReceived,
    refetch
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    null, // to,
    null, // departmentId,
    'OVERDUE', // contractState,
    null, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    null, // assignedLawyerId,
    viewMode === 'list' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    yesterdayDate // outstandingTo
  )

  /*
    계약 기록 데이터 (필터용)
  */
  const {
    loading: filterLoading,
    variables: filterVariables,
    contractRecordsData: filterContractRecordsData,
    totalCount: filterTotalCount,
    totalDueAmount: currentFilterTotalDueAmount,
    totalReceived: filterTotalReceived,
    refetch: filterRefetch
  } = useContractRecordList(
    true, // ascending
    null, // key
    null, // searchQuery
    null, // from,
    null, // to,
    null, // departmentId,
    'OVERDUE', // contractState,
    null, // deleted,
    null, // caseNumber,
    null, // projectTypeId,
    meta.filter.userIds, // assignedLawyerId,
    viewMode === 'list' ? null : groupModeContract,
    true, // outstanding
    null, // outstandingFrom
    meta.filter.datetimeRange[1] // outstandingTo
  )


  // 형사
  const {
    filterTotalDueAmount,
  } = useProjectTypeDueAmount(
    viewMode,
    groupModeContract,
    '15ce1e5f-481b-4da6-a6d1-7dd7d8f2db11',
    true,
    null,
    yesterdayDate
  )

  // 민사
  const {
    filterTotalDueAmount: filterTotalDueAmount2
  } = useProjectTypeDueAmount(
    viewMode,
    groupModeContract,
    'b17d7dbb-848c-4f9f-8b37-090c39a3098b',
    true,
    null,
    yesterdayDate
  )


  const {
    contractRecordTableColumns
  } = useContractRecordColumnsList(contractRecordsData)

  // 추심 목록 필터
  const filteredContract = _.filter(contractRecordsData, v => v.contractState === 'OVERDUE')

  useEffect(() => {
    sumTotalOverdue()
    sumProjectType()
  }, [])

  const sumTotalOverdue = () => { // 전체 추심 금액
    const totalSum = _.sumBy(filteredContract, 'contractTotal')
    setTotalOverdue(totalSum)
  }
  const sumProjectType = () => {
    const groupedByProjectType = _.groupBy(filteredContract, 'projectType')
    const sumByProjectType = _.mapValues(groupedByProjectType, group => _.sumBy(group, 'contractTotal'))
    setDetectiveTotal(sumByProjectType['형사'])
    setCivilTotal(sumByProjectType['민가사'])
  }

  return (
    <>
      <HeaderContentFooter
        boxShadow='none'
        header={(
          <>
            <ResponsiveGapContainer>
              <CollectionWrapper>
                <CollectionInfoContainer>
                  <Tag>전체 추심금액</Tag>
                  <TotalMoneyContainer value={totalDueAmount !== null ? totalDueAmount : 0} />
                </CollectionInfoContainer>
                <CustomDivider />
                <ColumnGapWrapper $gap={1}>
                  <CollectionInfoContainer>
                    <Tag>형사 추심금액</Tag>
                    <MoneyContainer value={filterTotalDueAmount !== null ? filterTotalDueAmount : 0} />
                  </CollectionInfoContainer>
                  <CollectionInfoContainer>
                    <Tag>민가사 추심금액</Tag>
                    <MoneyContainer value={filterTotalDueAmount2 !== null ? filterTotalDueAmount2 : 0} />
                  </CollectionInfoContainer>
                </ColumnGapWrapper>
              </CollectionWrapper>
              {/* <div style={{ minWidth: '300px' }}>
                <CustomSearch onSearch={v => setSearchQuery(v)} placeholder='의뢰인명/전화번호를 입력해주세요.' />
              </div> */}
              <FilterContainer>
                <ContractRecordFilter
                  meta={meta}
                  filters={filters}
                  onChangeMeta={onChangeMeta}
                  onResetFilterAndGroup={onResetFilterAndGroup}
                />
                <CustomDivider />
                <FilterResultContainer>
                  <CollectionInfoContainer>
                    <Tag>총 추심금액</Tag>
                    <MoneyContainer value={filteredTotalOverdue} />
                  </CollectionInfoContainer>
                  <ColumnGapWrapper $gap={1}>
                    <CollectionInfoContainer>
                      <Tag>형사 추심금액</Tag>
                      <MoneyContainer value={filteredDetectiveTotal} />
                    </CollectionInfoContainer>
                    <CollectionInfoContainer>
                      <Tag>민가사 추심금액</Tag>
                      <MoneyContainer value={filteredCivilTotal} />
                    </CollectionInfoContainer>
                  </ColumnGapWrapper>
                </FilterResultContainer>
              </FilterContainer>
            </ResponsiveGapContainer>
          </>
        )}
        content={(
          <ContractCustomTable
            data={contractRecordsData}
            defaultColumns={contractRecordTableColumns}
            groupColumns={getOverdueViewerTableGroupColumns}
            typeGroupColumns={getOverdueViewerTableGroupTypeColumns}
            total={totalCount}
            pageType='overdue'
            unit='개'
            viewMode={viewMode}
            groupModeContract={groupModeContract}
            {...props}
            onClickRow={onClickRow}
            scroll={700}
            setSelectedRowKeys={setSelectedRowKeys}
            hiddenTableActionByKey={{
              stat: true,
              excelDownload: false,
              copyRecord: true,
              delete: true
            }}
            // loading={loading}
          />
        )}
      />
    </>
  )
}

export default OverdueContainer
