import { useEffect, useRef, useState } from 'react'
import TextArea from 'antd/es/input/TextArea'
import UserIcon from '../../../../common/components/UserIcon'
import { contractDuplication } from '../../../../mockdata'
import { useDepartmentList } from '../../common/filter/useDepartmentList'
import { OnOffTextColor } from '../../../components/common/OnOffTextColor'
import { useUserList } from '../../../../common/apollo/useUserList'
import { CustomDatePicker } from '../../../components/common/CustomDatePicker'
import { checkDatePickerFormat, checkProejectTypeInput, customLocale, filterProjectSubtypes, parseMetaToSelectOptions } from '../../../components/common/CommonFunction'
import { SaveNewDataButton } from '../../../components/customTable/SaveNewDataButton'
import { ContractStateColor } from '../../../components/customTable/ContractStateColor'
import { FilterSelect } from '../../../../timeCharge/components/settings/DepartmentFilter'
import { calculateColumnWidth, convertToDayjs, parseTimeObj } from '../../../../common/util'
import { SearchParamsFilter } from '../../../../timeCharge/components/filters/SearchParamsFilter'
import { contractStateOptions, projectTypeOptions, reservationStatusOptions, timeFormat } from '../../../config'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'
import { Alert, Button, Form, Input, InputNumber, Modal, Popover, Select, Table, Tag, TimePicker, Typography } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import _ from 'lodash'
import { useCallRecordSaveEdit } from '../useCallRecordSaveEdit'
import { CustomTimePicker } from '../../../components/common/CustomTimePicker'
import { useCategories } from '../useCategories'
import { useProjectSubTypes } from '../useProjectSubTypes'

dayjs.locale('ko')

const StyledTag = styled(Tag)`
  cursor: pointer;
`
const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 800px;
  }
`
const EllipsisContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`
const StyledButton = styled(Button)`
  :where(.css-dev-only-do-not-override-4oxdqr).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #000;
    border-color:#000;
  }
`

const DuplicationStyledModal = styled(Modal)`
  .ant-modal-content {
    width: 800px;
  }
`

export const useRecordAdderColumns = (form, scratchPadData, setScratchPadData, departmentOptions, setScratchPadList, refetch, saveScratchPadData) => {
  const [disabled, setDisabled] = useState(true)
  const [contentValue, setContentValue] = useState(null) // 내용
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [duplicationModalOpen, setDuplicationModalOpen] = useState(false)
  const [advancedSearch, setAdvancedSearch] = useState(false)
  const isEditing = true
  const { departments } = useDepartmentList()
  const [projectTypeSelected, setProjectTypeSelected] = useState(false) // 사건구분 선택에 따라 세부사건구분 disable
  const [contentFirstLine, setContentFirstLine] = useState('')
  // const {
  //   onSaveCallRecord
  // } = useCallRecordSaveEdit(form, setScratchPadList, refetch)
  const { categories } = useCategories()
  const { projectSubTypes } = useProjectSubTypes()
  const textAreaRef = useRef(null)
  const handleContentChange = (e) => {
    textAreaRef.current = e.target.value
    if (textAreaRef.current !== null && textAreaRef.current !== undefined) {
      const splitLine = textAreaRef.current.split('\n')
      setContentFirstLine(splitLine[0])
    // form.setFieldValue(['scratchPad', index, 'content'], textAreaRef.current)
    }
  }
  const showModal = (key) => {
    if (key === 'duplication') { // 중복 알림 모달
      setDuplicationModalOpen(true)
    } else { // 내용 입력 모달
      setIsModalOpen(true)
    }
  }
  const handleOk = (key, index) => {
    // setDuplicationModalOpen(false)
    form.setFieldValue(['scratchPad', index, 'content'], textAreaRef.current)
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setDuplicationModalOpen(false)
  }

  const contractDuplicationColumns = [
    {
      title: 'key',
      dataIndex: 'key'
    },
    {
      title: 'ID',
      dataIndex: 'ID'
    },
    {
      title: 'SN',
      dataIndex: 'SN'
    },
    {
      title: '카테고리',
      dataIndex: 'category'
    },
    {
      title: '세부카테고리',
      dataIndex: 'subcategory'
    }
  ]
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    }
  }

  const {
    loading,
    users
  } = useUserList()

  const handleAdvancedSearch = (e) => {
    e.preventDefault()
    console.log('handleAdvancedSearch')
    setAdvancedSearch(!advancedSearch)
  }
  const onChange = (key, value) => {
    if (key === 'content') { // 내용
      setContentValue(value.target.value)
    }
  }
  const checkProjectType = (v) => {
    if (v !== null) {
      setProjectTypeSelected(true)
    } else {
      setProjectTypeSelected(false)
    }
  }

  const [secondSelectOptions, setSecondSelectOptions] = useState(projectSubTypes)
  const handleProjectTypeChange = (value, index) => {
    const selectedSubTypes = filterProjectSubtypes(form, null, projectSubTypes, value)
    form.setFieldValue(['call', index, 'projectSubType'], null)

    setSecondSelectOptions(selectedSubTypes)
  }
  const getRecordAdderTableColumns = [
    {
      title: '온오프구분',
      dataIndex: 'type',
      key: 'type',
      width: 140,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (type, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'type']}
            style={{ margin: 0 }}
          >
            <Select
              style={{ width: 120 }}
              placeholder='온오프구분'
              options={[
                {
                  value: 'ONLINE',
                  label: '온라인'
                },
                {
                  value: 'OFFLINE',
                  label: '오프라인'
                },
                {
                  value: 'VISIT',
                  label: '방문&선임'
                }
              ]}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '통화날짜',
      dataIndex: 'inputDate',
      key: 'inputDate',
      width: 150,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'left',
      editable: true,
      sort: true,
      render: (inputDate, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'inputDate']}
            style={{ margin: 0, width: 130 }}
          >
            <CustomDatePicker
              placeholder='통화날짜'
              date={convertToDayjs(inputDate)}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '통화시간',
      dataIndex: 'inputTime',
      key: 'inputTime',
      width: 130,
      category: ['ONLINE'],
      editable: true,
      sort: true,
      render: (inputTime, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'inputTime']}
            style={{ margin: 0, width: 110 }}
            // rules={[{ required: true, message: 'Please Input inputTime!' }]}
          >
            <CustomTimePicker
              placeholder='통화시간'
              time={parseTimeObj(inputTime, 'HH:mm')}
              // defaultValue={checkDatePickerFormat(inputTime) || undefined}
              // needConfirm={false}
              // format={timeFormat}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '콜식별',
      dataIndex: 'callType',
      key: 'callType',
      width: 130,
      category: ['ONLINE'],
      editable: true,
      render: (callType, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'callType']}
            style={{ margin: 0, width: 110 }}
          >
            <Select
              placeholder='콜식별'
              options={[
                {
                  value: 'NEWCALL',
                  label: '신규통화',
                  key: '0'
                },
                {
                  value: 'MISSEDCALL',
                  label: '부재중',
                  key: '1'
                }
              ]}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '부서',
      dataIndex: 'department',
      key: 'department',
      width: 220,
      category: ['ONLINE'],
      editable: true,
      render: (department, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'department']}
            style={{ margin: 0 }}
          >
            <Select
              placeholder='부서'
              options={departments.map(v => ({ label: v.name, value: v.id }))}
              allowClear
            />
          </Form.Item>
        )
      }
    },
    {
      title: '입력자_정',
      dataIndex: 'primaryAuthor',
      key: 'primaryAuthor',
      width: 100,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (primaryAuthor, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'primaryAuthor']}
            style={{ margin: 0, width: 90 }}
          >
            <Select
              placeholder='NA'
              options={users.map(v => ({ label: v.name, value: v.id }))}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '입력자_부',
      dataIndex: 'secondaryAuthor',
      key: 'secondaryAuthor',
      width: 100,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (secondaryAuthor, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'secondaryAuthor']}
            style={{ margin: 0, width: 90 }}
          >
            <Select
              placeholder='NA'
              options={users.map(v => ({ label: v.name, value: v.id }))}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '사건구분',
      dataIndex: 'projectType',
      key: 'projectType',
      width: 110,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (projectType, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'projectType']}
            style={{ margin: 0 }}
            // initialValue={projectType}
          >
            <Select
              placeholder='사건구분'
              // defaultValue={projectType || undefined}
              onChange={(value) => handleProjectTypeChange(value, index)}
              options={categories}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '세부사건구분',
      dataIndex: 'projectSubType',
      key: 'projectSubType',
      width: 140,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (projectSubType, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'projectSubType']}
            style={{ margin: 0 }}
          >
            <Select
              disabled={checkProejectTypeInput(form, record)}
              placeholder='세부사건구분'
              options={secondSelectOptions}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '의뢰인명',
      dataIndex: 'name',
      key: 'name',
      width: 120,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'left',
      editable: true,
      render: (name, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'name']}
            style={{ margin: 0, width: 100 }}
            placeholder='이름'
          >
            <Input
              placeholder='의뢰인명'
            />
          </Form.Item>
        )
      }
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      width: 120,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (gender, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'gender']}
            style={{ margin: 0 }}
          >
            <Select
              placeholder='성별'
              defaultValue={gender || undefined}
              options={[
                {
                  value: 'MALE',
                  label: '남성'
                },
                {
                  value: 'FEMALE',
                  label: '여성'
                }
              ]}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '연령',
      dataIndex: 'age',
      key: 'age',
      width: 110,
      category: ['ONLINE', 'OFFLINE', 'age'],
      editable: true,
      render: (age, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'age']}
                style={{ margin: 0 }}
              >
                <InputNumber
                  placeholder='연령'
                  defaultValue={age || undefined}
                  changeOnWheel
                  min={0}
                />
              </Form.Item>
              )
            : (age))
      }
    },
    {
      title: '전화번호',
      dataIndex: 'phone',
      key: 'phone',
      width: 180,
      fixed: 'left',
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (phone, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'phone']}
            style={{ margin: 0, width: 160 }}
          >
            <Input
              placeholder='전화번호'
              defaultValue={phone || undefined}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '주소',
      dataIndex: 'address',
      key: 'address',
      width: calculateColumnWidth(scratchPadData, 'address', '주소', 15, 220),
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (address, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'address']}
            style={{ margin: 0 }}
          >
            <Input
              placeholder='주소'
              defaultValue={address || undefined}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '방문경로',
      dataIndex: 'via',
      key: 'via',
      width: calculateColumnWidth(scratchPadData, 'via', '방문경로', 13, 200),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (via, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', record.key, 'via']}
            style={{ margin: 0 }}
          >
            <Input
              placeholder='방문경로'
              defaultValue={via || undefined}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '예상사건명',
      dataIndex: 'expectedCaseName',
      key: 'expectedCaseName',
      width: calculateColumnWidth(scratchPadData, 'expectedCaseName', '사건명', 14, 200),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (expectedCaseName, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'expectedCaseName']}
            style={{ margin: 0 }}
          >
            <Input
              placeholder='예상사건명'
              defaultValue={expectedCaseName || undefined}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '관할',
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      width: calculateColumnWidth(scratchPadData, 'jurisdiction', '관할', 13, 200),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (jurisdiction, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'jurisdiction']}
                style={{ margin: 0 }}
              >
                <Input
                  placeholder='관할'
                  defaultValue={jurisdiction || undefined}
                />
              </Form.Item>)
            : (jurisdiction))
      }
    },
    {
      title: '사건번호등',
      dataIndex: 'caseNumber',
      key: 'caseNumber',
      width: 220,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (caseNumber, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'caseNumber']}
                style={{ margin: 0, width: 200 }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <Input
                    defaultValue={caseNumber || undefined}
                    placeholder='사건번호등'
                  />
                  {/* 중복 임시 주석 처리 */}
                  {/* <StyledTag
                    color='red'
                    onClick={() => showModal('duplication')}
                  >
                    중복
                  </StyledTag> */}
                  <DuplicationStyledModal open={duplicationModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Alert type='error' message='중복된 계약이 있습니다.' banner />
                    <h4>계약 기록</h4>
                    <Table
                      rowSelection={{
                        type: 'radio',
                        ...rowSelection
                      }}
                      pagination={false}
                      columns={contractDuplicationColumns}
                      dataSource={contractDuplication}
                    />
                  </DuplicationStyledModal>
                </div>
              </Form.Item>)
            : (caseNumber))
      }
    },
    {
      title: '담당자',
      dataIndex: 'manager',
      key: 'manager',
      width: 120,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (manager, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'manager']}
            style={{ margin: 0, width: 100 }}
            // initialValue={manager}
          >
            <Select
              placeholder='담당자'
              options={users.map(v => ({ label: v.name, value: v.id }))}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '소가/피해금',
      dataIndex: 'amount',
      key: 'amount',
      width: 180,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (amount, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'amount']}
            style={{ margin: 0, width: 160 }}
            // initialValue={amount}
          >
            <InputNumber
              changeOnWheel
              defaultValue={amount || undefined}
              placeholder='소가/피해금'
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              addonAfter='원'
              parser={(value) => value?.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '내용',
      dataIndex: 'content',
      key: 'content',
      width: 250,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (content, record, index) => {
        let firstLine = ''
        if (content !== null && content !== undefined) {
          const splitLine = content.split('\n')
          firstLine = splitLine[0]
          setContentFirstLine(splitLine[0])
        }
        return (
          isEditing
            ? (
              <>
                <Form.Item
                  name={['scratchPad', index, 'content']}
                  style={{ margin: 0 }}
                >
                  <StyledButton
                    placeholder='내용을 입력해주세요'
                    onClick={showModal}
                    style={{ width: '230px', justifyContent: 'left' }}
                  >
                    {contentFirstLine.length > 0
                      ? (
                        <EllipsisContainer>
                          {contentFirstLine}
                        </EllipsisContainer>)
                      : (
                        <div style={{ color: '#d3d3d3' }}>내용을 입력해주세요</div>)}
                  </StyledButton>
                </Form.Item>
                <StyledModal title='내용 수정' ref={textAreaRef} open={isModalOpen} onOk={() => handleOk(record.key, index)} onCancel={handleCancel} width={800} height={800} okText='확인' cancelText='취소'>
                  <TextArea defaultValue={content || undefined} style={{ height: '500px' }} onChange={handleContentChange} />
                </StyledModal>
              </>)
            : (
              <Popover
                placement='bottom'
                content={
                  <TextArea defaultValue={content || undefined} disabled={disabled} style={{ width: '500px', minHeight: '500px', background: '#fff', color: '#000' }} />
                }
              >
                <EllipsisContainer>
                  {firstLine}
                </EllipsisContainer>
              </Popover>))
      }
    },
    {
      title: '방문지사',
      dataIndex: 'visitDepartment',
      key: 'visitDepartment',
      width: 220,
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (visitDepartment, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'visitDepartment']}
            style={{ margin: 0 }}
          >
            <Select
              placeholder='방문지사'
              defaultValue={visitDepartment || undefined}
              options={_.map(departments, parseMetaToSelectOptions)}
              allowClear
            />
          </Form.Item>
        )
      }
    },
    {
      title: '방문예약일자',
      dataIndex: 'visitReservedDate',
      key: 'visitReservedDate',
      width: 160,
      category: ['VISIT'],
      editable: true,
      render: (visitReservedDate, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'visitReservedDate']}
                style={{ margin: 0 }}
              >
                <CustomDatePicker
                  placeholder='방문예약일자'
                  date={convertToDayjs(visitReservedDate) || undefined}
                />
              </Form.Item>)
            : (visitReservedDate))
      }
    },
    {
      title: '방문예약시간',
      dataIndex: 'visitReservedTime',
      key: 'visitReservedTime',
      width: 150,
      category: ['ONLINE'],
      editable: true,
      render: (visitReservedTime, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'visitReservedTime']}
                style={{ margin: 0 }}
              >
                <TimePicker
                  locale={customLocale}
                  placeholder='방문예약시간'
                  defaultValue={checkDatePickerFormat(visitReservedTime) || undefined}
                  needConfirm={false}
                  format={timeFormat}
                />
              </Form.Item>)
            : (visitReservedTime))
      }
    },
    {
      title: '예약상황',
      dataIndex: 'reservationStatus',
      key: 'reservationStatus',
      width: 120,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      editable: true,
      render: (reservationStatus, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'reservationStatus']}
                style={{ margin: 0, width: 100 }}
              >
                <Select
                  placeholder='예약상황'
                  defaultValue={reservationStatus || undefined}
                  options={reservationStatusOptions}
                />
              </Form.Item>)
            : (reservationStatus))
      }
    },
    {
      title: '예약취소사유',
      dataIndex: 'reservationCancelReason',
      key: 'reservationCancelReason',
      width: calculateColumnWidth(scratchPadData, 'reservationCancelReason', '예약취소사유', 15, 150),
      category: ['ONLINE', 'OFFLINE'],
      editable: true,
      render: (reservationCancelReason, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'reservationCancelReason']}
            style={{ margin: 0 }}
            // initialValue={reservationCancelReason}
          >
            <Input
              placeholder='예약취소사유'
              defaultValue={reservationCancelReason || undefined}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '콜 수',
      dataIndex: 'callCount',
      key: 'callCount',
      width: 110,
      category: ['ONLINE'],
      editable: true,
      render: (callCount, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'callCount']}
            style={{ margin: 0 }}
            // initialValue={callCount}
          >
            <InputNumber
              defaultValue={callCount || undefined}
              changeOnWheel
              min={0}
              placeholder='콜수'
              style={{ width: 90 }}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '방문일',
      dataIndex: 'visitedDate',
      key: 'visitedDate',
      width: 140,
      category: ['VISIT'],
      editable: true,
      render: (visitedDate, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'visitedDate']}
            style={{ margin: 0 }}
          >
            <CustomDatePicker
              placeholder='방문일'
              date={checkDatePickerFormat(visitedDate) || undefined}
            />
          </Form.Item>
        )
      }
    },
    // {
    //   title: '방문시간',
    //   dataIndex: 'visitedTime',
    //   key: 'visitedTime',
    //   width: 130,
    //   category: ['VISIT'],
    //   editable: true,
    //   render: (visitedTime, record, index) => {
    //     return (
    //       isEditing
    //         ? (
    //           <Form.Item
    //             name={['scratchPad', index, 'visitedTime']}
    //             style={{ margin: 0, width: 110 }}
    //           >
    //             <TimePicker
    //               placeholder='방문시간'
    //               defaultValue={checkDatePickerFormat(visitedTime) || undefined}
    //               onChange={(value) => handleChange(record.key, 'visitedTime', value)}
    //               needConfirm={false}
    //               format={timeFormat}
    //             />
    //           </Form.Item>)
    //         : (visitedTime))
    //   }
    // },
    {
      title: '방문자',
      dataIndex: 'visitor',
      key: 'visitor',
      width: 140,
      category: ['VISIT'],
      render: (visitor, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'visitor']}
            style={{ margin: 0, width: 120 }}
          >
            <Input
              placeholder='방문자'
              defaultValue={visitor}
            />
          </Form.Item>
        )
      }
    },
    // 임시 주석: 고급 검색
    // {
    //   title: '상담변호사',
    //   dataIndex: 'counselLawyers',
    //   key: 'counselLawyers',
    //   width: calculateColumnWidth(scratchPadData, 'counselLawyers', '상담변호사', 23, 150),
    //   category: ['VISIT'],
    //   editable: true,
    //   render: (counselLawyers, record, index) => {
    //     const joinArray = counselLawyers?.join()
    //     return (
    //       isEditing
    //         ? (
    //           <Form.Item
    //             name='counselLawyers'
    //             style={{ margin: 0, width: calculateColumnWidth(scratchPadData, 'counselLawyers', '상담변호사', 22, 140) }}
    //             initialValue={counselLawyers}
    //           >
    //             <Select
    //               mode='multiple'
    //               defaultValue={counselLawyers}
    //               popupMatchSelectWidth={advancedSearch ? 600 : false}
    //               options={users.map(v => ({
    //                 label: v.name,
    //                 value: v.name,
    //                 group: v.group.name,
    //                 departments: v.departments
    //               }))}
    //               optionRender={(option) => {
    //                 return (
    //                   <Space>
    //                     <UserIcon group={option.data.group} name={option.data.label} />
    //                     <DepartmentTag departments={option.data.departments} />
    //                   </Space>
    //                 )
    //               }}
    //               dropdownRender={menu => {
    //                 return (
    //                   <>
    //                     {/* <Divider style={{ margin: '3px 0' }} /> */}
    //                     {advancedSearch
    //                       ? (
    //                         <RowGapWrapper>
    //                           {/* <FilterSelect // 필터전체
    //                             scroll={{ y: 400 }}
    //                             departments={_.map(departments, department => ({ name: _.get(department, ['name']), key: _.get(department, ['id']) }))}
    //                             employees={_.map(users, userGroup => ({ key: userGroup.id, allocation: userGroup?.name }))}
    //                             // onChangeEmployeesSelection={rowKeys => setSelectedEmployees(rowKeys)}
    //                             // selectedDepartmentIds={selectedDepartmentOptions} // 선택된 부서 넘겨주기
    //                             // selectedEmployees={selectedEmployees}
    //                             // onChangeSelection={(rowKeys) => onCheckDepartemntSelection(rowKeys)} // 부서 필터 선택
    //                           /> */}
    //                           <SearchParamsFilter
    //                             // selectedItems={selectedItems}
    //                             options={users.map(v => ({ label: v.name, value: v.name }))}
    //                             persistElement={<BaseText text='작성자' />}
    //                             // onChange={e => onChangeFilter(e)}
    //                             // onChangeDepartmentFilter={e => onChangeDepartmentFilter(e)}
    //                             // selectedOptions={selectedOptions}
    //                             selectedDepartmentOptions={selectedDepartmentOptions}
    //                             // openStatusByKey={openStatusByKey}
    //                             departments={departments}
    //                             // {...props}
    //                           />
    //                           {menu}
    //                         </RowGapWrapper>)
    //                       : (
    //                         <>
    //                           <Space style={{ padding: '0 5px 4px' }}>
    //                             <Button
    //                               onClick={handleAdvancedSearch}
    //                               // style={{ width: '205px' }}
    //                             >
    //                               <SearchOutlined />
    //                               고급 검색
    //                             </Button>
    //                           </Space>
    //                           {menu}
    //                         </>)}
    //                   </>
    //                 )
    //               }}
    //               // open={(open) => {
    //               //   console.log('open', open)
    //               // }}
    //               onDropdownVisibleChange={(open) => {
    //                 if (!open) {
    //                   setAdvancedSearch(false)
    //                 }
    //               }}
    //             />
    //           </Form.Item>)
    //         : (joinArray)
    //     )
    //   }
    // },
    {
      title: '상담변호사',
      dataIndex: 'counselLawyers',
      key: 'counselLawyers',
      width: calculateColumnWidth(scratchPadData, 'counselLawyers', '상담변호사', 23, 150),
      category: ['VISIT'],
      editable: true,
      render: (counselLawyers, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'counselLawyers']}
                style={{ margin: 0, width: calculateColumnWidth(scratchPadData, 'counselLawyers', '상담변호사', 22, 170) }}
              >
                <Select
                  mode='multiple'
                  placeholder='상담변호사'
                  defaultValue={counselLawyers || undefined}
                  options={users.map(v => ({ label: v.name, value: v.id }))}
                />
              </Form.Item>)
            : (counselLawyers))
      }
    },
    {
      title: '상담직원',
      dataIndex: 'counselEmployees',
      key: 'counselEmployees',
      width: calculateColumnWidth(scratchPadData, 'counselEmployees', '상담직원', 24, 190),
      category: ['VISIT'],
      editable: true,
      render: (counselEmployees, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'counselEmployees']}
                style={{ margin: 0, width: calculateColumnWidth(scratchPadData, 'counselEmployees', '상담직원', 22, 170) }}
              >
                <Select
                  mode='multiple'
                  placeholder='상담직원'
                  defaultValue={counselEmployees || undefined}
                  options={users.map(v => ({ label: v.name, value: v.id }))}
                />
              </Form.Item>)
            : (counselEmployees))
      }
    },
    {
      title: '데이터 입력자',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: calculateColumnWidth(scratchPadData, 'createdBy', '데이터 입력자', 22, 170),
      category: ['VISIT'],
      editable: true,
      render: (createdBy, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'createdBy']}
            style={{ margin: 0 }}
            // initialValue={createdBy}
          >
            <Select
              mode='multiple'
              defaultValue={createdBy || undefined}
              placeholder='데이터 입력자'
              options={users.map(v => ({ label: v.name, value: v.id }))}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '신규방문수',
      dataIndex: 'nVisit',
      key: 'nVisit',
      width: 120,
      category: ['VISIT'],
      editable: true,
      render: (nVisit, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'nVisit']}
            style={{ margin: 0 }}
            // initialValue={nVisit}
          >
            <InputNumber
              defaultValue={nVisit || undefined}
              changeOnWheel
              min={0}
              placeholder='신규방문수'
              style={{ width: '100px' }}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '재방문',
      dataIndex: 'nRevisit',
      key: 'nRevisit',
      width: 120,
      category: ['VISIT'],
      editable: true,
      render: (nRevisit, record, index) => {
        return (
          <Form.Item
            name={['scratchPad', index, 'nRevisit']}
            style={{ margin: 0 }}
            // initialValue={nRevisit}
          >
            <InputNumber
              defaultValue={nRevisit || undefined}
              changeOnWheel
              min={0}
              placeholder='재방문'
              style={{ width: 100 }}
            />
          </Form.Item>
        )
      }
    },
    {
      title: '계약자',
      dataIndex: 'contractor',
      key: 'contractor',
      width: 115,
      category: ['VISIT'],
      render: (contractor, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'contractor']}
                style={{ margin: 0 }}
              >
                <Input
                  defaultValue={contractor || undefined}
                  placeholder='계약자'
                />
              </Form.Item>)
            : (contractor))
      }
    },
    {
      title: '선임일',
      dataIndex: 'assigned',
      key: 'assigned',
      width: 140,
      category: ['VISIT'],
      editable: true,
      render: (assigned, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'assigned']}
                style={{ margin: 0, width: 120 }}
              >
                <CustomDatePicker
                  placeholder='선임일'
                  date={checkDatePickerFormat(assigned) || undefined}
                />
              </Form.Item>)
            : (assigned))
      }
    },
    {
      title: '선임',
      dataIndex: 'nAssign',
      key: 'nAssign',
      width: 100,
      category: ['VISIT'],
      editable: true,
      render: (nAssign, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'nAssign']}
                style={{ margin: 0 }}
              >
                <InputNumber
                  defaultValue={nAssign || undefined}
                  changeOnWheel
                  min={0}
                  placeholder='선임'
                  style={{ width: 80 }}
                />
              </Form.Item>)
            : (nAssign))
      }
    },
    // {
    //   title: '진행상황',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 120,
    //   category: ['VISIT'],
    //   fixed: 'right',
    //   editable: true,
    //   render: (status, record, index) => {
    //     return (
    //       isEditing
    //         ? (
    //           <Form.Item
    //             name='status'
    //             style={{ margin: 0 }}
    //           >
    //             <Select
    //               style={{ width: '100px' }}
    //               placeholder='선택'
    //               defaultValue={status || undefined}
    //               onChange={(value) => handleChange(record.key, 'status', value)}
    //               options={currentStateOptions}
    //             />
    //           </Form.Item>)
    //         : (status))
    //   }
    // },
    {
      title: '사임취소일자',
      dataIndex: 'cancelled',
      key: 'cancelled',
      width: 140,
      category: ['VISIT'],
      editable: true,
      render: (cancelled, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'cancelled']}
                style={{ margin: 0, width: 120 }}
                defaultValue={cancelled || undefined}
              >
                <CustomDatePicker
                  placeholder='사윔취소일자'
                  date={checkDatePickerFormat(cancelled) || undefined}
                />
              </Form.Item>)
            : (cancelled))
      }
    },
    {
      title: '사유',
      dataIndex: 'cancelReason',
      key: 'cancelReason',
      width: calculateColumnWidth(scratchPadData, 'cancelReason', '사유', 13, 250),
      category: ['VISIT'],
      editable: true,
      render: (cancelReason, record, index) => {
        return (
          isEditing
            ? (
              <Form.Item
                name={['scratchPad', index, 'cancelReason']}
                style={{ margin: 0 }}
              >
                <Input
                  placeholder='사유'
                  defaultValue={cancelReason || undefined}
                />
              </Form.Item>)
            : (cancelReason))
      }
    },
    // {
    //   title: '계약상태',
    //   dataIndex: 'contractState',
    //   key: 'contractState',
    //   width: 100,
    //   category: ['VISIT'],
    //   fixed: 'right',
    //   editable: true,
    //   render: (contractState, record, index) => {
    //     return (
    //       isEditing
    //         ? (
    //           <Form.Item
    //             name={['scratchPad', index, 'contractState']}
    //             style={{ margin: 0 }}
    //             defaultValue={contractState}
    //           >
    //             <Select
    //               style={{ width: '80px' }}
    //               placeholder='선택'
    //               defaultValue={contractState || undefined}
    //               options={contractStateOptions}
    //             />
    //           </Form.Item>)
    //         : (
    //           <ContractStateColor state={contractState} />
    //           ))
    //   }
    // },
    {
      title: '저장',
      dataIndex: 'save',
      key: 'save',
      width: 50,
      category: ['ONLINE', 'OFFLINE', 'VISIT'],
      fixed: 'right',
      render: (_, record, index) => {
        return (
          <SaveNewDataButton
            record={record}
            onSave={() => saveScratchPadData(record.key)}
          />
        )
      }
    }
  ]

  return {
    getRecordAdderTableColumns
  }
}
