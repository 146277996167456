import _ from 'lodash'
import { useCustomTable } from '../../common/useCustomTable'
import { useNavigate } from 'react-router-dom'
import { navigateToOverdueRecord } from '../../../cacheAndNavigation/overdueRecord/navigation'
import { getRecordContentViewMode, getRecordContractGroupMode } from '../../../cacheAndNavigation/callRecord/cache'
import { useRecordFilterSearchParams } from '../../filter/callRecord/useRecordFilterSearchParams'

export const useOverdueList = () => {
  const { searchParams } = useRecordFilterSearchParams()
  const viewMode = getRecordContentViewMode() // for history
  const groupModeContract = getRecordContractGroupMode()
  const searchString = searchParams.toString()
  const navigate = useNavigate()
  const tableProps = useCustomTable()

  const {
    selectedRowKeys
  } = tableProps

  const disabledTableActionByKey = {
    copyRecord: _.isEmpty(selectedRowKeys),
    delete: _.isEmpty(selectedRowKeys),
    save: _.isEmpty(selectedRowKeys)
  }
  const hiddenTableActionByKey = {
    stat: false,
    excelDownload: false,
    copyRecord: false,
    delete: false
  }
  const onChangeViewMode = v => {
    navigateToOverdueRecord(navigate, searchString, v)
  }
  const onChangeGroupMode = v => {
    navigateToOverdueRecord(navigate, searchString, 'group', v)
  }

  return {
    viewMode,
    groupModeContract,
    disabledTableActionByKey,
    hiddenTableActionByKey,
    onChangeViewMode,
    onChangeGroupMode,
    ...tableProps
  }
}
