import { Button, Card, Statistic } from 'antd'
import styled from 'styled-components'
import COLORS from './colors'

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TextNoWrap = styled.div`
  white-space: nowrap;
`

export const RowGapWrapper = styled.div`
  display: flex;
  gap: ${props => props.$gap ? props.$gap : 5}px;
  white-space: nowrap;
`
export const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`

export const ColumnGapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.$gap ? props.$gap : 5}px;
`

export const ResponsiveGapContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  @media (max-width: 1270px ) {
    flex-direction: column;
  }
`

export const MoneyContainer = styled(Statistic)`
  width: 80px;
  text-align: right;
  .ant-statistic-content-value-int,
  .ant-statistic-content-value-decimal {
    font-size: 14px;
    color: ${props => props.$color};
  }
`

// 콜선임 > 계약 정보 페이지
export const GrayCustomCard = styled(Card)`
  /* width: ${props => (props.$readOnly ? '800px' : '1050px')}; */
  .ant-card-body{
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .ant-card-body::before {
    content: none;
  }
  .ant-card-body::after {
    content: none;
  }
`

// 테이블 글씨
export const caseTableCommonProps = { wordWrap: 'break-word', wordBreak: 'break-word', fontSize: 12 }

export const CaseInfoButton = styled(Button)`
  width: 1px;
  border-radius: 30px;
  background: ${COLORS.gray_1_50};
  padding: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

// 콜 선임 > 계약 기록
export const NarrowCustomCard = styled(Card)`
  .ant-card-body{
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .ant-card-body::before {
    content: none;
  }
  .ant-card-body::after {
    content: none;
  }
`

// 콜선임 > 검색창
export const CustomSearchContainer = styled.div`
  min-width: 240px;
  max-width: 260px;
  width: 100%;
  margin-left: 15px;
`
