import styled from 'styled-components'
import COLORS from '../../../../common/Theme/colors'
import { Skeleton } from 'antd'

const ItemCountWrapper = styled.div`
  display: flex;
  gap: 5px;
`
const BlueText = styled.div`
  color: ${COLORS.primaryColor};
  font-weight: bold;
`

export const TotalIndicator = ({ total = 0, unit, loading }) => {
  if (loading) return <Skeleton />
  return (
    <ItemCountWrapper>
      총
      <BlueText>{total}</BlueText>
      {unit}
    </ItemCountWrapper>
  )
}
