import { HeaderContentFooter } from '../../../common/components/HeaderContentFooter'
import { HeaderTitle, Spacer } from '../../../common/components/PageHeaderStyles'

import { useUserGroupList } from '../../../timeCharge/hooks/apollo/useUserGroupList'
import { useSettingGroupPermission } from '../../../timeCharge/hooks/settings/users/useSettingGroupPermission'

import styled from 'styled-components'
import { Button, Checkbox, Popover, Table } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import _ from 'lodash'

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:  20px;
  width: 100%;
  margin-bottom: 50px;
`

const _autorityCategory = [
  {
    authorityGroupName: '사건 관련',
    detailAuthority: '모든 사건 열람',
    key: 'READ_ALL_PROJECT'
  },
  {
    authorityGroupName: '',
    detailAuthority: '동일부서 사건 열람',
    key: 'READ_SAME_DEPARTMENT_PROJECT'
  },
  {
    authorityGroupName: '타임시트 관련',
    detailAuthority: '모든 타임시트 열람',
    key: 'READ_ALL_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '동일부서 타임시트 열람',
    key: 'READ_SAME_DEPARTMENT_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 작성',
    key: 'WRITE_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타인 타임시트 입력',
    key: 'WRITE_OTHER_TIMESHEET'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 연혁 열람',
    key: 'READ_TIMESHEET_HISTORY'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 시간 조정 입력',
    key: 'WRITE_TIMESHEET_TIMEDELTA'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 시간 조정 열람',
    key: 'READ_TIMESHEET_TIMEDELTA'
  },
  {
    authorityGroupName: '사건 종결 관련',
    detailAuthority: '사건 종결',
    key: 'CLOSE_PROJECT'

  },
  {
    authorityGroupName: '',
    detailAuthority: '사건 종결 취소',
    key: 'REOPEN_PROJECT'
  },
  {
    authorityGroupName: '사건 등록 관련',
    detailAuthority: '사건 등록',
    key: 'CREATE_PROJECT'

  },
  {
    authorityGroupName: '',
    detailAuthority: '자문 등록',
    key: 'CREATE_CONSULTATION'
  },
  {
    authorityGroupName: '',
    detailAuthority: '기타 등록',
    key: 'CREATE_ETC'
  },
  {
    authorityGroupName: '',
    detailAuthority: '사건 정보 관리',
    key: 'UPDATE_PROJECT_DATA'
  },
  {
    authorityGroupName: '사건 배당 관련',
    detailAuthority: '사건 배당 받을 수 있음',
    key: 'ALLOW_ALLOCATION'
  },
  {
    authorityGroupName: '',
    detailAuthority: '사건 배당 또는 위임할 수 있음',
    key: 'ALLOCATE_OR_DELEGATE'
  },
  {
    authorityGroupName: '',
    detailAuthority: '사건분류 관리',
    key: 'MANAGE_PROJECT_CATEGORY'
  },
  {
    authorityGroupName: '',
    detailAuthority: '타임시트 제목 관리',
    key: 'MANAGE_TIMESHEET_NAME'
  },
  {
    authorityGroupName: '',
    detailAuthority: '직원관리',
    key: 'MANAGE_USERS'
  },
  {
    authorityGroupName: '',
    detailAuthority: '부서관리',
    key: 'MANAGE_DEPARTMENTS'
  }
]

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: center;
  gap: 10px;
`

const ManageGroupAuthorityContainer = () => {
  const { userGroups, loading } = useUserGroupList() // 쿼리: 그룹변 모든 권한
  // userGroups의 각 권한 목록에서 사건 열람 권한이 없을 경우 타임시트 열람 비활성화
  const userGroupKeys = _.map(userGroups, 'id')
  const userGroupByKey = _.keyBy(userGroups, 'id') // 그룹멸 권한 목록
  const autorityCategory = _autorityCategory.map((v) => {
    const { key: permission } = v
    const checkedByUserGroup = {}
    _.forEach(userGroupKeys, key => {
      checkedByUserGroup[key] = _.includes(_.get(userGroupByKey, [key, 'permissions']), permission)
    })
    return {
      ...v,
      checkedByUserGroup
    }
  })
  const {
    handleCheckboxChange,
    onSaveChanges,
    saveButtonDisabled,
    meta,
    checkboxState
  } = useSettingGroupPermission()
  return (
    <HeaderContentFooter
      header={(
        <Container>
          <HeaderTitle title='권한그룹 관리' />
          <Spacer $flex={5} />
          <Button
            style={{ display: 'flex', alignItems: 'center' }}
            icon={<EditOutlined />} type='primary' onClick={onSaveChanges}
            disabled={saveButtonDisabled}
          >
            변경사항 저장하기
          </Button>
        </Container>
      )}
      content={(
        <ColumnContainer>
          <Table
            loading={loading}
            pagination={false}
            columns={[
              {
                title: '권한종류',
                dataIndex: '권한종류',
                rowScope: 'row',
                children: [
                  {
                    title: '권한 그룹',
                    dataIndex: 'authorityGroupName',
                    key: 'authorityGroupName',
                    width: 100
                  },
                  {
                    title: '권한 세부 카테고리',
                    dataIndex: 'detailAuthority',
                    key: 'detailAuthority',
                    width: 100
                  }
                ]
              },
              {
                title: '권한그룹',
                dataIndex: 'authorityCategory',
                colSpan: 7,
                children: _.map(checkboxState, v => {
                  return {
                    title: _.get(v, ['name']),
                    key: _.get(v, ['id']),
                    render: (__, record) => {
                      const isDisabled = _.includes(meta.disabledUserType, v.name) && record.key === 'READ_SAME_DEPARTMENT_TIMESHEET'
                      const checkbox = (
                        <Checkbox
                          onChange={e => handleCheckboxChange(e.target.checked, v, record)}
                          checked={_.includes(_.get(v, ['permissions'], []), record.key)}
                          disabled={isDisabled}
                        />
                      )
                      return isDisabled
                        ? (
                          <Popover content='동일부서 사건 열람 활성화 필요'>
                            {checkbox}
                          </Popover>)
                        : (checkbox)
                    }
                  }
                })
              }
            ]}
            dataSource={
              loading
                ? null
                : autorityCategory.map(
                  ({ authorityGroupName, key: permission, detailAuthority }) => {
                    return ({
                      key: permission,
                      detailAuthority,
                      authorityGroupName
                    })
                  }
                )
            }
          />
        </ColumnContainer>
      )}
    />
  )
}
export default ManageGroupAuthorityContainer
