import { scratchPadTableActionItems } from './ScratchPadTableActionItems'
import styled from 'styled-components'
import { Button, message } from 'antd'
import _ from 'lodash'
import { useConfirmModal } from '../../hooks/common/useConfirmModal'
import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { useDeleteScratchPad } from '../../hooks/mutations/useDeleteScratchPad'

const Container = styled.div`
  display: flex;
  gap: 0px;
`

export const TableActions = ({
  dataSource,
  handleDelete,
  disabledTableActionByKey = {},
  hiddenTableActionByKey = {},
  onClick,
  excelProps = {},
  dataCategory,
  refetch,
  fileDownloadLoading,
  selectedRowKeys,
  setScratchPadData,
  form,
  setScratchPadList
}) => {
  const {
    onDeleteScratchPad
  } = useDeleteScratchPad(
    form,
    selectedRowKeys,
    setScratchPadList
  )

  const {
    onCloseModal,
    onConfirmModal,
    confirmModalMeta,
    title: confirmModalTitle,
    onOpenModal
  } = useConfirmModal(
    null,
    null,
    null,
    null,
    null,
    onDeleteScratchPad)

  return (
    <Container>
      <ConfirmModal
        open={confirmModalMeta.showConfirmModal}
        title={confirmModalTitle}
        onOk={onConfirmModal}
        onCancel={onCloseModal}
      />
      {scratchPadTableActionItems.map(({ label, key, icon }, index) => {
        return (
          <Button
            icon={icon}
            disabled={_.get(disabledTableActionByKey, [key], false) || fileDownloadLoading || (key === 'tossCase' ? !(selectedRowKeys?.length > 0) : '')}
            key={key}
            type='text'
            style={{ padding: '4px' }}
            onClick={() => {
              if (key === 'save') {
                const selectedRows = dataSource.filter((item) => selectedRowKeys.includes(item.key))
                selectedRows.forEach((row) => {
                  const missingFields = []
                  Object.keys(row).forEach((field) => {
                    if (!row[field]) {
                      missingFields.push(field)
                    }
                  })

                  if (missingFields.length > 0) {
                    message.warning(`열 ${index + 1} 번째 행에 누락된 항목이 있습니다: ${missingFields.join(', ')}`)
                  }
                })
              } else if (key === 'delete') {
                onOpenModal(selectedRowKeys, 'delete')
              }
            }}
          >
            {label}
          </Button>
        )
      })}
    </Container>
  )
}
