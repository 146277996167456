import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ConfirmModal } from '../../../common/components/ConfirmModal'
import { navigateToSettingDetail } from '../../cacheAndNavigation//setting/navigation'
import { useSaveDeleteProjectTypeForm } from './projectType/useSaveDeleteProjectTypeForm'

import styled from 'styled-components'
import COLORS from '../../../common/Theme/colors'
import { Button, ColorPicker, Popover } from 'antd'
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons'

const StyledPopover = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
`
const FlatButton = styled(Button)`
  &.ant-btn {
    font-size: 18px;
    border: none;
    display: flex;
    align-items: center;
    width: 10px;
    justify-content: center;
    box-shadow: none;
    background: none;
  }
`
const PopoverMenuContainer = styled.div`
  display: flex;
  gap: 10px;
`

const colorColumnWidth = 50

const ProjectTypePopOverContent = ({ onEditProjectType, onRemoveProjectType }) => {
  const [open, setOpen] = useState(null)
  return (
    <StyledPopover>
      <PopoverMenuContainer style={{ gap: 10 }} onClick={onEditProjectType}>
        <EditOutlined style={{ color: `${COLORS.orange}` }} />
        <div>사건분류 정보 수정</div>
      </PopoverMenuContainer>
      <PopoverMenuContainer style={{ gap: 10 }} onClick={(e) => setOpen(true)}>
        <DeleteOutlined style={{ color: `${COLORS.red}` }} />
        <div>사건분류 삭제</div>
      </PopoverMenuContainer>
      <ConfirmModal
        title='사건분류를 삭제하시겠습니까?'
        open={open}
        onOk={() => {
          onRemoveProjectType()
          setOpen(false)
        }}
        onCancel={() => {
          setOpen(false)
        }}
      />
    </StyledPopover>
  )
}
export const useProjectTypeColumnsOfSetting = (refetch = () => {}) => {
  const navigate = useNavigate()
  const {
    onRemoveProjectType
  } = useSaveDeleteProjectTypeForm(
    null,
    null,
    refetch
  )

  const MenuColumnWidth = 80
  const projectTypeTableColumns = [
    {
      key: 'sort',
      width: MenuColumnWidth,
      editable: false
    },
    {
      title: '',
      dataIndex: 'color',
      key: 'color',
      width: colorColumnWidth,
      editable: true,
      render: (_color, record) => {
        return (
          <ColorPicker value={_color} disabled />
        )
      }
    },
    {
      title: '사건분류',
      dataIndex: 'name',
      editable: true
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      width: 20,
      render: (_v, record) => {
        return (
          <>
            <Popover
              onClick={(e) => {
                e.stopPropagation()
              }}
              trigger='click'
              placement='right'
              content={
                <ProjectTypePopOverContent
                  onEditProjectType={() => {
                    navigateToSettingDetail(
                      navigate,
                      'editing',
                      'editProjectType',
                      'editProjectType',
                      record.id,
                      record
                    )
                  }}
                  onRemoveProjectType={() => onRemoveProjectType(record.id)}
                />
              }
            >
              <FlatButton>
                <MoreOutlined />
              </FlatButton>
            </Popover>
          </>
        )
      }
    }
  ]
  return {
    projectTypeTableColumns
  }
}
